import TransactionService from "../services/TransactionService";
import { AsyncStorage } from "react-native";
import { toCents } from '../utils/Utility';

export class Payment {

    public id: any
    public title: string
    public message: string
    public amount: number;
    public fee: number;
    public currency: string
    public direction: string;
    public timestamp: string
    public transactionId: string
    public eventType: string
    public refundable: string;
    public merchantDeviceId: string;
    public merchantDeviceName: string;
    public shopId: number;
    public shopName: string;
    public objectUserId: number;
    public objectAlias: string;
    public transactionType: string;
    public externalEventType: any;
    public eventTypeList: any

    readonly $name;

    constructor(paymentObj){
        this.id = paymentObj.id
        this.title = paymentObj.title
        this.message = paymentObj.message
        this.currency = paymentObj.currency
        this.direction = paymentObj.direction
        this.timestamp = paymentObj.lastModifiedDate
        this.transactionId = paymentObj.transactionId
        this.eventType = paymentObj.eventType
        this.refundable = paymentObj.refundable;
        this.amount = paymentObj.amount/100;
        this.fee = paymentObj.fee || 0
        this.merchantDeviceId = paymentObj.merchantDeviceId;
        this.merchantDeviceName = paymentObj.merchantDeviceId;
        this.shopId = paymentObj.shopId;
        this.shopName = paymentObj.shopName;
        this.objectUserId = paymentObj.objectUserId;
        this.objectAlias = paymentObj.objectAlias;
        this.transactionType = paymentObj.transactionType;
        this.externalEventType = paymentObj.externalEventType
        this.eventTypeList = [
            {
              id: "PAYPAL_PAYMENT",
              name: "PayPal wallet"
            },
            {
              id: "PAYPAL_CARD_PAYMENT",
              name: "PayPal carte"
            },
            {
                id: "PAYPAL_REFUND",
                name: "Rimborso PayPal"
            },
            {
                id: "PAYPAL_CARD_REFUND",
                name: "Rimborso carta PayPal"
            },
            {
              id: "ALIPAY_PAYMENT",
              name: "Alipay+"
            },
            {
                id: "ALIPAY_REFUND",
                name: "Rimborso Alipay+"
            },
            {
              id: "CONNECT_MERCHANT_PAYMENT",
              name: "Alipay+ online"
            },
            {
                id: "CONNECT_MERCHANT_REFUND",
                name: "Rimborso Alipay+ online"
            },
            {
              id: "TINABA",
              name: "TINABA"
            }
        ]
    }

    getEventType(){
        var founded = this.eventTypeList.find(el => el.id == this.externalEventType)
        if(founded){
            return founded.name
        }else{
            return "Tinaba"
        }
    }

    async refund(){
        if(this.transactionType == "PAYMENT_ORDER"){
            var m = JSON.parse((await AsyncStorage.getItem("wp_merchantDeviceData"))!)
            return TransactionService.refundTransaction(m.id, this.transactionId)
        }else{
            const req = {
                transactionId: this.transactionId,
                paymentType: "B2P",
                amount: this.fee ? toCents(this.amount) + Number(this.fee) : toCents(this.amount),
                currentAmount: this.fee ? toCents(this.amount) + Number(this.fee) : toCents(this.amount),
                currency: "EUR"
            }
            return TransactionService.businessRefund(req)
        }
    }
}
