import React from 'react';

import {
    Text,
    View,
    Image,
    SafeAreaView,
    StyleSheet,
    Platform,
    ScrollView,
    AsyncStorage,
    TouchableOpacity,
    TouchableHighlight,
} from 'react-native';

import { t, extra } from "../../assets"
import { mq } from '../utils/Utility';
import { TopBar } from '../components/topBar'
import { CBottomSheet } from '../components/bottomSheet';
import loginController from '../controller/login.controller';
import { CAlert } from '../components/alert';
import { QrCode } from '../components/qrcode';
import { MenuList } from './Lists';
import { ArrowTopBar } from '../components/arrowTopBar';
import listController from '../controller/list.controller';
import { NavigationEvents } from 'react-navigation';



export class Settings extends React.Component<{ navigation: any }, { WW, W, M, S: boolean, user: any, qrcode: string, visible: boolean, lists: boolean, renderContent: any}> {
    modalEvent = "settings"

    constructor(navigation: any){
        super(navigation)
        this.state = { WW: false, W: false, M: false, S: false, user: {shop: {}, deviceInfo: {}}, qrcode: "", visible: false, lists: false, renderContent: () => {}};
    }

    static navigationOptions = {
        headerStyle: {
         visibility: "hidden",
         height: 0
        },
    };

    async componentDidMount() {
        let userCtx = JSON.parse((await AsyncStorage.getItem("wp_merchantDeviceData"))!)
        let paymentCode = (await AsyncStorage.getItem("wp_paymentCode"))!
        this.setState({user: userCtx, qrcode: paymentCode})
    }

    _toggleBottomNavigationView = (lists, contentProduct?) => {
        lists ? this.setState({renderContent: this.renderContentListsError}): this.setState({renderContent: this.renderContentBS})
        if(contentProduct && typeof contentProduct == "function"){
            this.setState({ renderContent: contentProduct })
        }
        this.setState({ visible: !this.state.visible });
    };

    async logout(props){
       loginController.logout(props)
    }

    renderContentBS(){
        return(
            <View style={[ t.flexWrap, t.flexRow, t.bgWhite, t.roundedTLg, t.p4, t.pB5 ]}>

                <View style={[ t.w11_12, t.h6, t.justifyCenter, t.mB2 ]}>
                    <Text style={[t.text4, t.textFirst, t.fontBd]}>Hai bisogno di assistenza?</Text>
                </View>

                <TouchableOpacity style={[ t.w1_12, t.h6, t.justifyCenter, t.itemsEnd ]} onPress={() => this._toggleBottomNavigationView(false)}>
                    <Image source={require('../../assets/icons/iconCross.png')} style={[{width: 14, height: 14}, t.mR1]}/>
                </TouchableOpacity>
                <View style={[ t.flexWrap, t.flexRow, t.wFull ]}>
                    <Text style={[t.text5, t.fontRg, t.textSecond]}>Scrivi a:  </Text>
                    <Text style={[t.text5, t.fontBd, t.textMainBlue]}>business@tinaba.it</Text>
                </View>
                <View style={[ t.flexWrap, t.flexRow, t.wFull, t.mT1 ]}>
                    <Text style={[t.text5, t.fontRg, t.textSecond]}>Oppure chiama:  </Text>
                    <Text style={[t.text5, t.fontBd, t.textMainBlue]}>800694950 dall'estero: +390298670060</Text>
                </View>

            </View>
        )
    }

    renderContentListsError(){
        return(
            <View style={[ t.flexWrap, t.flexRow, t.bgWhite, t.roundedTLg, t.p4, t.pB8]}>

                 <View style={[ t.flexWrap, t.flexRow, t.wFull ]}>
                    <View style={[ t.w10_12, t.h3 ]}>
                    </View>
                    <TouchableOpacity style={[ t.w2_12, t.h3, t.justifyCenter, t.itemsEnd, t.pX2, t.mB1 ]} onPress={() => this._toggleBottomNavigationView(false)}>
                        <Image source={require('../../assets/icons/iconCross.png')} style={{width: 14, height: 14}}/>
                    </TouchableOpacity>
                </View>
                <View style={[ t.wFull, t.h6, t.mB2, t.p2 ]}>
                    <Text style={[t.text3, t.textGrey, t.fontBold, t.justifyCenter]}>PUOI GESTIRE I TUOI LISTINI SOLO DALLA CASSA PRINCIPALE</Text>
                </View>
                <View style={[ t.flexWrap, t.flexRow, t.wFull, t.p2 ]}>
                    <Text style={[t.text5, t.textSecond, t.mT3]}>Per impostare questa cassa come cassa principale:</Text>
                </View>
                <View style={[ t.flexRow, t.pL2 ]}>
                    <Text>{'\u2022'}</Text>
                    <Text style={[t.pL1, t.fontRg, t.text5, t.mB1 ]}>
                        Accedi con le tue credenziali alla App Tinaba (profilo business) o al sito tinaba.com
                    </Text>
                </View>
                <View style={[ t.flexRow, t.pL2 ]}>
                    <Text>{'\u2022'}</Text>
                    <Text style={[t.pL1, t.fontRg, t.text5, t.mB1 ]}>
                        Vai alla sezione "Gestione Casse e aggiungi (tasto +) una nuova cassa
                    </Text>
                </View>
            </View>
        )
    }

    async openLists(){
        let userCtx = JSON.parse((await listController.getCashInfo())!)
        if(userCtx.deviceInfo.standalone){
            this.setState({lists: true})
        }else{
            this._toggleBottomNavigationView(true)
        }
    }

    render(){

        const { WW, W, M, S } = this.state;

        return (
            <SafeAreaView style={[ e.heightCustom, e.bgCustomLight ]} onLayout={ mq(this) }>
                <View style={[ e.heightCustom, t.bgLightBlue, WW && [e.fixedContainer] ]}>

                    <NavigationEvents
                    onDidFocus={payload => {
                        this.setState({lists: false})
                    }}/>

                    <CAlert event={this.modalEvent}></CAlert>

                    <ScrollView style={[ t.wFull ]} bounces={false}>
                        {!this.state.lists && <View style={[ t.itemsCenter ]}>

                            <View style={[ t.wFull, M && { paddingHorizontal: "1.05%" }, W && { paddingHorizontal: "3.05%" } ]}>
                                <TopBar></TopBar>
                            </View>

                            <View style={[ t.wFull, t.pB12, M && t.w9_12, W && t.w8_12, WW && t.w7_12 ]}>

                                <View style={[ t.wFull, t.itemsCenter, t.bgWhite, t.rounded ]}>
                                    <TouchableOpacity style={[ t.borderB1, t.borderDisGrey, t.p3, t.w11_12 ]} onPress={() => this.openLists()}>
                                        <Text style={[ t.fontRg, t.textSecond, t.textCenter, {fontSize: 18} ]}>Gestione listini</Text>
                                    </TouchableOpacity>
                                    <TouchableOpacity style={[ t.borderB1, t.borderDisGrey, t.p3, t.w11_12 ]} onPress={() => {CAlert.showModal({data: {actionFunc: this.logout, props: this.props}, type:"LOGOUT"}, this.modalEvent)}}>
                                        <Text style={[ t.fontRg, t.textSecond, t.textCenter, {fontSize: 18} ]}>Effettua il logout dalla cassa</Text>
                                    </TouchableOpacity>
                                    <TouchableOpacity style={[ t.borderDisGrey, t.p3, t.w11_12 ]} onPress={() => {this._toggleBottomNavigationView(false)}}>
                                        <Text style={[ t.fontRg, t.textSecond, t.textCenter, {fontSize: 18} ]}>Assistenza</Text>
                                    </TouchableOpacity>
                                </View>

                                <View style={[ t.flexWrap, t.rounded, t.mY4, t.flexRow, { backgroundColor: "#199DBF" } ]}>
                                    <View style={[ t.wFull, t.rounded, t.bgWhite, t.itemsCenter, t.justifyCenter, { height: 100 }, M && [t.w3_12, {height: 125}], W && [t.w3_12, {height: 125}], WW && [t.w3_12, {height: 125}] ]}>
                                        <Image source={require('../../assets/img/tinabaAlipayVertical.png')} resizeMode="contain" style={[ t.hidden, t.w12, t.h10, M && [t.flex], W && [t.flex], WW && [t.flex] ]}/>
                                        <Image source={require('../../assets/img/tinabaAlipayHorizontal.png')} resizeMode="contain" style={[ {width: 260, height: 40}, M && t.hidden, W && t.hidden, WW && t.hidden ]}/>
                                    </View>

                                    <View style={[ t.wFull, t.itemsCenter, t.justifyCenter, {height: 125}, M && t.w9_12, W && t.w9_12, WW && t.w9_12 ]}>
                                        <Text style={[ t.text5, t.textWhite, t.fontRg, t.w11_12, M && {width: 280}, W && {width: 460}, WW && {width: 460} ]}>
                                            Abilita i pagamenti Alipay e scopri come creare la tua vetrina in cinese
                                        </Text>
                                        <Text style={[ t.text5, t.textWhite, t.fontRg, t.w11_12, M && {width: 280}, W && {width: 460}, WW && {width: 460} ]}>
                                            {"在支付宝App内可以创建你的商铺了!"}
                                        </Text>
                                        <Text style={[ t.text6, t.textWhite, t.fontBd, t.w11_12, t.mT1, M && {width: 280}, W && {width: 460}, WW && {width: 460}]}>
                                            Per info:  business@tinaba.it
                                        </Text>
                                    </View>
                                </View>

                                <View style={[ t.bgWhite, t.rounded, t.p2, t.itemsCenter, t.pY4 ]}>
                                    <QrCode value={this.state.qrcode? this.state.qrcode : "NA"} size={170}/>

                                    <Text style={[ t.text3, t.textFirst, t.fontBd, t.mT4 ]}>{this.state.user.shop.name}</Text>
                                    <Text style={[ t.text5, t.textSecond, t.fontRg, t.mT2 ]}>{this.state.user.deviceInfo.deviceName}</Text>
                                    <Text style={[ t.text5, t.textSecond, t.fontRg, t.textCenter ]}>{`${this.state.user.shop.street} ${this.state.user.shop.streetNumber}, ${this.state.user.shop.postalCode} ${this.state.user.shop.city}`}</Text>
                                    <Text style={[ t.text5, t.textThird, t.fontRg, t.mT4, t.textCenter, t.w11_12 ]}>Mostra questo QR code ai tuoi clienti per {"\n"}l'accesso diretto alla tua vetrina Tinaba</Text>
                                </View>

                            </View>

                        </View>
                        }

                        {
                            this.state.lists &&
                            <View>
                                <View style={[ t.bgWhite ]}>
                                    <ArrowTopBar toogle={() => {this.setState({lists: false})}}></ArrowTopBar>
                                </View>
                                <MenuList {...{W, M, S}} _toggleBottomNavigationView={this._toggleBottomNavigationView.bind(this)}></MenuList>
                            </View>
                        }
                    </ScrollView>
                    <CBottomSheet { ...{visible: this.state.visible, _toggleBottomNavigationView: this._toggleBottomNavigationView, renderContent: this.state.renderContent} }></CBottomSheet>
                </View>
            </SafeAreaView>
        )
    }
}

const e = StyleSheet.create({

    // Custom background external area
    bgCustomLight: {
        backgroundColor: (Platform.OS === 'web') ? "#c9d1d7" : "#e8eef2",
    },

    // Fix height 100% on mobile + web
    heightCustom: {
        height: (Platform.OS === 'web') ? "100vh" : "100%",
    },

    // Custom fixed container
    fixedContainer: {
        height: (Platform.OS === 'web') ? "90vh" : "100%",
        width: (Platform.OS === 'web') ? "1400px" : "100%",
        margin: (Platform.OS === 'web') ? "auto" : 0,
        marginTop: (Platform.OS === 'web') ? "4.5vh" : 0,
    }

});
