import React, { Component } from 'react'
import { 
    View, 
    StyleSheet, 
    Text, 
    TouchableHighlight, 
    Image, 
    Platform, 
    DeviceEventEmitter
} from 'react-native'

import { t, extra } from "../../assets"
import { vas, orderIcon } from "../utils/Utility"
import Moment from 'moment';
import { OrderDetails } from './orderDetail'
import { CAlert } from './alert'
import { CBottomSheet } from './bottomSheet'
import Popup from 'reactjs-popup';
import { Avatar } from './avatar';



export class NewOrderPayModal extends React.Component<{ W, M, S: boolean, onRef: any, item: any, refresh: any}, {visible: boolean, modalType: any, visibleBS: boolean}> {
    
    refundModal
    modalEvent= "newOrderPay";


    constructor(props){
      super(props)
      this.state = {visible: true, modalType: "REFUND", visibleBS: false}
    }

    componentDidMount() {
      this.props.onRef(this)
    }

    componentWillUnmount() {
      this.props.onRef(undefined)
    }

    _toggleBottomNavigationView = () => {
        this.setState({ visibleBS: !this.state.visibleBS, visible: false });

    };

    openRefundModal(){
        this.setState({modalType: "REFUND"})
        this.refundModal.show()
        this._toggleBottomNavigationView()
    }

    async refund(){
        this.props.item.refund().then(
            () => {
                this.refundModal.dismiss()
                this._toggleBottomNavigationView()
                this.setState({modalType: "SUCCESS_REFUND"})
                CAlert.showModal({data: {props: this.props}, type:this.state.modalType}, this.modalEvent)
                this.props.refresh()
                this.setState({modalType: "REFUND"})
            },
            () => {
                this._toggleBottomNavigationView()
                this.refundModal.dismiss()
                this.setState({modalType: "ERROR_REFUND"})
                this.refundModal.show()
                this.setState({modalType: "REFUND"})
            }
        )
        
    }

    infoFirst = {
        pTitle: "Ok",
        page: "Home",
        navigation: "",
        onClick: "",
        value: ""
    }

    infoSecond = {
        pTitle: "Ok",
        page: "",
        navigation: "",
        onClick: "",
        value: ""
    }

    dismiss(){
      this.setState({visible: false})
    }

    renderContentBS({ W, M, S }){        
        return(          
            <OrderDetails ack={true} refresh={this.props.refresh} refund={this.openRefundModal.bind(this)} dismiss={this._toggleBottomNavigationView.bind(this)} event={"pendingHome"} {...{ W, M, S, item: this.props.item }}></OrderDetails>
        )    
    }

    openBSAction(){
        if(this.props.item.$name == "Order"){
            this._toggleBottomNavigationView()
        }else{
            DeviceEventEmitter.emit("open:keypad")
            this.dismiss()
        }
    }

    
    render(){
        const {W, M, S} = this.props
        return(
            <>
            <Popup open={this.state.visible} position="right center" contentStyle={{ width: 600, borderRadius: 8 }}>
                <View>   
                    <View>    
                        <View style={[t.flexWrap, t.flexRow, t.p2 ]}>

                                <View style={[ t.w2_12, t.h6, t.flex, t.justifyCenter ]}>
                                    <Avatar imageLink={this.props.item.avatar}/>
                                </View>
                                <View style={[ t.w7_12, t.h6, t.flex, t.justifyCenter ]}>
                                    <Text style={[ t.fontBd, t.textFirst, t.text5 ]} >{this.props.item.user ? this.props.item.user.contactAlias : this.props.item.contactAlias}</Text>
                                    <Text style={[ t.fontRg, t.textSecond ]}>{Moment(this.props.item.timestamp).isSame(Moment(), 'day') ? "OGGI "+Moment(this.props.item.timestamp).format('HH:mm') : Moment(this.props.item.timestamp).format('DD/MM/YYYY HH:mm')}</Text>
                                </View>
                                <View style={[ t.w3_12, t.h6, t.flex, t.itemsEnd, t.justifyCenter ]}>
                                    <Text style={[ t.fontBd, t.textFirst, t.text4  ]}>{this.props.item.amount ? this.props.item.amount.toFixed(2).replace(".", ",")+'€': "Tavolo "+this.props.item.table}</Text>
                                </View>

                                <View style={[ t.wFull, t.borderB1, t.borderDisGrey, {marginTop: 10}, t.mB1 ]}></View>

                                <View style={[ t.w2_12, t.h5, t.flex, t.justifyCenter ]}>
                                    <Image source={orderIcon[this.props.item.vasType]} style={[ t.w4, t.h4, t.mL1 ]}/>
                                </View>
                                <View style={[ t.w8_12, t.h5, t.flex, t.justifyCenter ]}>
                                    <Text style={[ t.fontBd, t.textSecond, t.text5 ]} >{this.props.item.vasType ? vas[this.props.item.vasType] : "Conto al tavolo"}</Text>
                                </View>
                                <View style={[ t.wFull, t.flexRow, t.justifyCenter, t.mT3 ]}>
                                    <View style={[ t.bgMainBlue, t.rounded, t.w7_12, t.mR2 ]}>
                                        <TouchableHighlight onPress={() => {this.openBSAction()}}>
                                            <Text style={[ t.textCenter, t.textWhite, t.text5, t.fontBd, {padding: 9} ]}>Guarda subito</Text>
                                        </TouchableHighlight>
                                    </View>  
                                    <View style={[ t.bgWhite, t.rounded, t.border1, t.borderMainBlue, t.w5_12 ]}>
                                        <TouchableHighlight onPress={() => {this.dismiss()}}>
                                            <Text style={[ t.textCenter, t.textMainBlue, t.text5, t.fontBd, {padding: 9} ]}>Dopo</Text>
                                        </TouchableHighlight>
                                    </View>         
                                </View>
                        </View>
                    </View>
                    {/*<CAlert onRef={ref => {this.refundModal = ref}} {...{data: {actionFunc: this.refund.bind(this), props: this.props}, type:this.state.modalType}}></CAlert>*/}
                    <CAlert event={this.modalEvent}></CAlert>
                </View>
            </Popup>      
             <CBottomSheet { ...{visible: this.state.visibleBS, _toggleBottomNavigationView: this._toggleBottomNavigationView, renderContent: this.renderContentBS.bind(this, { W, M, S })} }></CBottomSheet>
             </>
        ) 
    }
} 