import React from 'react';

import {
    View,
    Text,
    Image,
    StyleSheet,
    TouchableOpacity,
    Platform,
    DeviceEventEmitter
} from "react-native";
import { toCents } from '../utils/Utility';

import { t, extra } from "../../assets"
import Moment from 'moment';
import { CAlert } from './alert';
import { LinkToPayDetail } from './linkToPayDetail';



export class LinkToPayCard extends React.Component<{ W, M, S: boolean, item: any, refresh: any, _toggleBottomNavigationView: any, style?: any },  {visible: boolean, modalType: string}> {

    modalEvent = Platform.OS == "web" ? "historicLink" : "historicLinkDetail";

    constructor(props){
        super(props)
        this.state = {visible: false, modalType: "REFUND"}
    }

    infoStandardCard = {
        pTitle: "Ok",
        page: "Home",
        navigation: "",
        onClick: "",
        value: ""
    }

    openRefundModal(){
        console.log("OpenRefundModal")
        this.setState({modalType: "REFUND"})
        CAlert.showModal({data: {actionFunc: this.refund.bind(this), props: this.props}, type:this.state.modalType}, this.modalEvent)
        Platform.OS == "web" && this.props._toggleBottomNavigationView()
    }

    async refund(){
        this.props.item.refund(false).then(
            () => {
                this.showSuccessResponse(false)
            },
            (error) => {
                console.log(error)
               this.showErrorResponse(false)
            }
        )
    }

    partiallyRefund(refundAmount, reset) {

        this.props.item.refund(true, toCents(refundAmount)).then(
            () => {
                this.showSuccessResponse(true)
                reset()
            },
            (error) => {
                console.log(error)
                this.showErrorResponse(true)
            }
        )
    }

    showSuccessResponse(pr){
        !pr && CAlert.dismissModal(this.modalEvent)
        pr && Platform.OS == "web" && this.props._toggleBottomNavigationView()
        this.setState({modalType: "SUCCESS_REFUND"})
        CAlert.showModal({data: {props: this.props}, type:this.state.modalType}, this.modalEvent)
        this.props.refresh()
        this.setState({modalType: "REFUND"})
    }

    showErrorResponse(pr){
        pr && Platform.OS == "web" && this.props._toggleBottomNavigationView()
        !pr && CAlert.dismissModal(this.modalEvent)
        this.setState({modalType: "ERROR_REFUND"})
        CAlert.showModal({data: {props: this.props}, type:this.state.modalType}, this.modalEvent)
        this.setState({modalType: "REFUND"})
    }

    renderContentBS({ W, M, S }){
        return(
            <LinkToPayDetail refresh={this.props.refresh} dismiss={this.props._toggleBottomNavigationView} refund={this.openRefundModal.bind(this)} partiallyRefund={this.partiallyRefund.bind(this)} {...{ W, M, S, item: this.props.item }}></LinkToPayDetail>
        )
    }

    renderStatusTag(item){
        var style
        var label
        var partialRefunded = false

        switch (item.status) {
            case "EXPIRED":
                style = [t.bgDarkGrey]
                label = "SCADUTO"
                break;
            case "PAYMENT":
                style = [{backgroundColor: "#EFAA07"}]
                label = "PENDING"
                break;
            case "REFUND":
                style = [t.bgRed]
                label = "RIMBORSATO"
            break;
            case "PAYMENT_SUCCESS":
                if(item.refundedAmount && item.refundedAmount > 0 && item.refundedAmount < item.amount ){
                    style = [t.border2, t.borderRed]
                    label = "RIMBORSATO PARZIALMENTE"
                    partialRefunded = true
                }else{
                    style = [t.bgGreen]
                    label = "PAGATO"
                }
            break;
        }

        return (
            <View style={[t.p1, t.rounded, style]}>
                <Text style={[t.fontBold, {fontSize: 11}, !partialRefunded ? t.textWhite : t.textRed]}>{label}</Text>
            </View>
        )
    }

    render(){
        const { W, M, S, item } = this.props
        var refund = (item.status == "REFUND" || (item.refundedAmount > 0 && item.refundedAmount < item.amount))
        return(

            <View style={[ this.state.visible && {zIndex: 100}, t.flex, t.flexWrap, t.flexRow, t.wFull, t.bgWhite, t.rounded, {marginBottom: 9},
            e.elevation0, extra.elevationShadow(2), M && [{width: "48%", marginHorizontal: "1%"}, t.mB2], W && [{width: "48%", marginHorizontal: "1%"}, t.mB2] ]}>


                <View style={[ t.flexWrap, t.flexRow, t.wFull, t.p2 ]}>

                    {
                    /*<View style={[ t.w2_12, t.h6, t.justifyCenter ]}>
                        <Image source={require('../../assets/img/profileSmall.png')} style={[ t.w5, t.h5, t.roundedFull ]}/>
                    </View>*/
                    }

                    <View style={[ t.w9_12, t.h6, t.justifyCenter, t.pL1 ]}>
                        {
                            (item.userAlias || item.userFullName) &&
                            <Text style={[ t.fontBd, t.textFirst, t.text5 ]}>
                                {
                                    item.userAlias ? item.userAlias : item.userFullName
                                }
                            </Text>
                        }
                        <Text style={[ t.fontRg, t.textSecond ]}>
                            {
                                Moment(item.createdDate).isSame(Moment(), 'day') ? "OGGI "+Moment(item.createdDate).format('HH:mm') : Moment(item.createdDate).format('DD/MM/YYYY HH:mm')
                            }
                        </Text>
                    </View>

                    <View style={[ t.w3_12, t.h6, t.itemsEnd, t.justifyCenter ]}>
                        <Text style={[ t.fontBd, refund ? t.textRed : t.textFirst, t.text4 ]}>
                            {
                                (refund ? item.refundedAmount/100*-1 : item.amount/100).toFixed(2).replace(".", ",")
                            }

                            </Text>
                    </View>

                    <View style={[ t.wFull, t.borderB1, t.borderDisGrey, {marginTop: 10}, t.mB1 ]}></View>

                    <View style={[ t.w10_12, t.h5, t.flexWrap, t.contentStart, t.justifyCenter, t.block ]}>

                        <View style={[t.w4, t.h4, t.mR3, t.mL1, t.itemsCenter]}>
                            <Image source={require('../../assets/icons/ltp-icon.png')} style={[ { width: 24 }, t.h3 ]}/>
                        </View>

                        {
                            this.renderStatusTag(item)
                        }

                    </View>

                    <TouchableOpacity style={[ t.w2_12, t.h5, t.itemsEnd, t.justifyCenter, t.block ]} onPress={() => this.props._toggleBottomNavigationView(this.renderContentBS.bind(this, {W, M, S}))}>
                        <Image source={require('../../assets/icons/infoDotsCard.png')} style={[ t.w4, t.h4 ]}/>
                    </TouchableOpacity>

                </View>
            </View>
        )
    }
}

const e = StyleSheet.create({

    // Custom border radius


    // Fix z-index bottom sheet android
    elevation0: { elevation: 0 },
    elevation50: { elevation: 50 },

});
