import React from "react";
import { View, Text, ScrollView } from "react-native";
import { MenuCard } from "../components/menuCard";
import { t, extra } from "../../assets"
import VassesService from "../services/VassesService";
import listController from "../controller/list.controller";
import { CBottomSheet } from "../components/bottomSheet";

export class MenuList extends React.Component<{W, M, S: boolean, _toggleBottomNavigationView: any}, {list: any, vasses: any, visibleBS: boolean, renderContent: any}> {

    constructor(props){
        super(props)
        this.state = {list: [], vasses: [], visibleBS: false, renderContent: () => {}}
    }
    
    componentDidMount(){
        this.getMenuList()
    }

    async getMenuList(){
        var vasses = await this.getVasses()
        var res = await listController.getCollection()
        this.setState({list: res, vasses: vasses})
    }

    async getVasses(){
        return await listController.getVasses()

    }

    render(){
        const {W, M, S} = this.props
        return (
            <View style={[t.mT2, t.mB13]}>
                <ScrollView contentContainerStyle={[t.flex, t.flexWrap, t.flexRow, M && { marginHorizontal: "1%" }, W && {marginHorizontal: "3%"} ]}>                
                
                        {
                            this.state.list.map((el, i) => {
                                return <MenuCard {...{W, M, S}} item={el} vasses={this.state.vasses} refresh={this.getMenuList.bind(this)} _toggleBottomNavigationView={this.props._toggleBottomNavigationView} key={i}></MenuCard>
                            })
                        }                
                </ScrollView>
            </View>
        )
    }
}