import {Dimensions, Platform} from "react-native"
import { CAlert } from "../components/alert"
import { useState, useLayoutEffect } from "react"

const isTablet = () => {
    return Math.round(Dimensions.get("window").width)>1023 || Math.round(Dimensions.get("window").height)>1023
}

const isPortrait = () => {
    return Math.round(Dimensions.get("window").width) < Math.round(Dimensions.get("window").height)
}


/*const mq = (context) => {
    return ({ nativeEvent }) => {
        const { width } = nativeEvent.layout;
        context.setState(() => ({
            WW: width > 1400,
            W: width > 900,
            M: width >= 650 && width <= 900,
            S: width < 650
        }));
    };
}*/

const mq = (context) => {
    if(Platform.OS != "web"){
        return ({ nativeEvent }) => {
            const { width } = nativeEvent.layout;
            context.setState(() => ({
                WW: width > 1400,
                W: width > 900,
                M: width >= 650 && width <= 900,
                S: width < 650
            }));
        };
    }else{
        return () => {
            window.addEventListener('resize', updateSize);
            updateSize()
            function updateSize() {
                const width  = window.innerWidth
                context.setState(() => ({
                    WW: width > 1400,
                    W: width > 900,
                    M: width >= 650 && width <= 900,
                    S: width < 650
                }));
            }
        };
    }
}

const useWindowSize =  () => {
  const [size, setSize] = useState([0, 0]);
  useLayoutEffect(() => {
    function updateSize() {
      setSize([window.innerWidth, window.innerHeight]);
    }
    window.addEventListener('resize', updateSize);
    updateSize();
    return;
  }, []);
  return size;
}

const vas = {
    officedelivery: "Consegna in ufficio",
    delivery: "Consegna a domicilio",
    pick: "Take Away",
    table: "Conto al tavolo",
    pay: "Acquista in negozio"
}

const orderIcon = {
  pay: require("../../assets/icons/order-and-pay.png"),
  delivery: require("../../assets/icons/order-and-delivery.png"),
  officedelivery: require("../../assets/icons/office-delivery.png"),
  pick: require("../../assets/icons/order-and-pick.png"),
  table: require("../../assets/icons/order-at-table.png"),
}

const vasByName = {
  "ORDER-AND-PAY": "Acquista in negozio",
  "ORDER-AND-DELIVERY": "Consegna a domicilio",
  "OFFICE-DELIVERY": "Consegna in ufficio",
  "ORDER-AND-PICK": "Take Away",
  "ORDER-AT-TABLE": "Ordina al tavolo",
}

const vasByIcon = {
  "ORDER-AND-PAY": require("../../assets/icons/order-and-pay.png"),
  "ORDER-AND-DELIVERY": require("../../assets/icons/order-and-delivery.png"),
  "OFFICE-DELIVERY": require("../../assets/icons/office-delivery.png"),
  "ORDER-AND-PICK": require("../../assets/icons/order-and-pick.png"),
  "ORDER-AT-TABLE": require("../../assets/icons/order-at-table.png"),
}

const getAlertType = (state) => {

    return [
        {
          type: "PAYMENT_SUCCESS",
          title: "Pagamento avvenuto con successo",
          message: state && state.data && state.data.content && state.data.content.taxFreeMessage ? state.data.content.taxFreeMessage : "",
          button: "OK"
        },
        {
          type: "PAYMENT_FAILS",
          title: "Pagamento rifiutato",
          message: "Pagamento rifiutato dall'utente",
          button: "OK"
        },
        {
          type: "EXPIRED",
          title: "Tempo scaduto",
          message: "La richiesta di pagamento è scaduta, dovrai effettuarne una nuova",
          button: "OK"
        },
        {
          type: "BILL_DELETED",
          title: "Avviso",
          message: "Le richieste di pagamento sono state aggiornate",
          button: "OK"
        },
        {
          type: "LOGOUT",
          title: "Sei sicuro di voler eliminare l'associazione?",
          message: "",
          button: "Procedi"
        },
        {
          type: "REFUND",
          title: "Sei sicuro di voler effettuare il rimborso?",
          message: "",
          button: "Conferma"
        },
        {
          type: "ERROR_REFUND",
          title: "Errore nel rimborsare la transazione",
          message: "",
          button: "Chiudi"
        },
        {
          type: "SUCCESS_REFUND",
          title: "Rimborso eseguito con successo",
          message: "",
          button: "Ok"
        },
        {
          type: "PAY_REFRESH",
          title: "Le richieste di pagamento sono state aggiornate",
          message: "",
          button: "OK"
        },
        {
          type: "LOGIN-400",
          title: "Login errato",
          message: "Codice non valido",
          button: "OK"
        },
        {
          type: "APP-BLOCKED",
          title: "Aggiorna la tua app",
          message: "Per continuare ad usare POS Tinaba devi fare l'aggiornamento.",
          button: "OK"
        },
        {
          type: "ERROR-500",
          title: "Errore del server",
          message: "Riprovare piu tardi",
          button: "Reinserisci codice"
        },
        {
          type: "OFFLINE",
          title: "Sei offline",
          message: "Riprovare piu tardi",
          button: "Ok"
        },
        {
          type: "CASH_DELETED",
          title: "Cassa eliminata",
          message: "",
          button: "Reinserisci codice"
        },
        {
          type: "DEFAULT",
          title: "",
          message: "",
          button: ""
        }
    ]
}

const toCents = amount => {
  const a = "" + amount; //Making sure is a string
  return parseInt((a.replace(",", ".") * 100).toPrecision(12));
}

const isCloseToBottom = ({layoutMeasurement, contentOffset, contentSize}) => {
    const paddingToBottom = 20
    return layoutMeasurement.height + contentOffset.y >=
      contentSize.height - paddingToBottom
}

const diffCard = (a1: any[], a2: any[]) => {
    let newElement = a1.filter(({ orderId: id1 }) => !a2.some(({orderId: id2}) => id2 === id1));
    return newElement
}

const handleError = (error, logout, props, ev) => {
  if(error){
      switch(error.message){
          case "400" :
              CAlert.showModal({data: {}, type:"LOGIN-400"}, ev)
              break;
          case "404" :
              if(ev == "splash"){
                CAlert.showModal({data: {actionFunc: logout, props: props}, type:"CASH_DELETED"}, ev)
              }else{
                CAlert.showModal({data: {props: props}, type:"PAY_REFRESH"}, ev)
              }
              break;
          case "500":
              CAlert.showModal({data: {actionFunc: logout, props: props}, type:"ERROR-500"}, ev)
              break;
          case "OFFLINE":
              CAlert.showModal({data: {}, type:"OFFLINE"}, ev)
              break;
          default:
              CAlert.showModal({data: {actionFunc: logout, props: props}, type:"ERROR-500"}, ev)
      }
  }

}


export {isTablet, isPortrait, mq, vas, orderIcon, isCloseToBottom, getAlertType, diffCard, vasByName, vasByIcon, handleError, useWindowSize, toCents}
