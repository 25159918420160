import React from 'react';

import {
  Text,
  View,
  Image,
  StyleSheet
} from 'react-native';

import { t } from "../../assets"
import { mq } from '../utils/Utility';
import homeController from '../controller/home.controller';


export class TopBar extends React.Component<{}, { W, M, S: boolean, cashInfo: any }> {

    constructor(navigation: any){
        super(navigation)
        this.state = { W: false, M: false, S: false, cashInfo: ""};
    }

    static navigationOptions = {
        headerStyle: {
         visibility: "hidden",
         height: 0
        },
    };

    async componentDidMount() {
        let userCtx = JSON.parse((await homeController.getCashInfo())!)
        this.setState({cashInfo: {cash: `${userCtx.deviceInfo.deviceName}`, principle: `${userCtx.deviceInfo.standalone ? "ID " + userCtx.deviceInfo.id + "  -  Principale" : "ID " + userCtx.deviceInfo.id }` }})
    }

    render(){
        const { W, M, S } = this.state;
        return (
            <View style={[ t.wFull, t.flexRow, t.pY3, {paddingHorizontal: "1%"}, M && t.pY4, W && t.pY4 ]} onLayout={mq(this)}>
                <View style={[ t.w9_12, {paddingLeft: 3} ]}>
                    <Text style={[ t.fontBd, t.textFirst, t.leadingXl, {fontSize: 16} ]}>{this.state.cashInfo.cash}</Text>
                    <Text style={[ t.float, t.fontRg, t.textSecond, e.customLeading, {fontSize: 15.5} ]}>{this.state.cashInfo.principle}</Text>
                </View>
                <View style={[ t.w3_12, t.itemsEnd, t.justifyCenter ]}>
                    <Image source={require('../../assets/img/tinabaLogoHome.png')} resizeMode="contain" style={[{width: 97, height: 30}, t.mR1 ]}/>
                </View>
            </View>
        )
    }
}

const e = StyleSheet.create({

    customLeading: { lineHeight: 20 }

});
