import React, { Component } from 'react'
import { View, Text, TextInput, TouchableOpacity, TouchableHighlight, Image, Platform, ScrollView } from 'react-native'
import { t } from "../../assets"
import { NumericKeyboard } from './numericKeyboard';
import { CBottomSheet } from './bottomSheet';
import PaymentService from '../services/PaymentService';
import { SafeAreaView } from 'react-navigation';
import { toCents } from '../utils/Utility';

interface IState {
    visible: boolean,
    enabled: boolean,
    deadline: any,
    refPayment: any,
    description: any
    errorRef: boolean,
    errorDesc: boolean
}

interface IProps {
    style:any,
    W, M, S: boolean,
    total: any,
    changeMode: any
}

export class LinkToPayInfo extends React.Component<IProps, IState> {

    total = ""
    deadlines= [6, 12, 24, 48]

    constructor(props){
        super(props)
        this.state = {visible: false, deadline: 48, refPayment: "", description: "", enabled: false, errorDesc: false, errorRef: false}
        this.total = this.props.total
    }

    _toggleBottomNavigationView = () => {
        this.setState({ visible: !this.state.visible });
    };

    setDeadLineValue(value){
        this.setState({deadline: value})
        this._toggleBottomNavigationView()
    }

    renderContent(){
        return (
            <View style={[t.bgWhite, t.roundedTLg, t.p2, t.pB2, t.wFull, t.flexRow]}>
                <View style={[t.w11_12, t.borderB1, t.borderDisGrey]}>
                    {
                        this.deadlines.map(item => {
                            return (
                                <TouchableHighlight onPress={() => {this.setDeadLineValue(item)}}>
                                    <Text style={[s.text]}>{item} ore</Text>
                                </TouchableHighlight>
                            )
                        })
                    }
                </View>
                <TouchableOpacity style={[ t.w1_12, t.h6, t.justifyCenter, t.itemsEnd ]} onPress={() => this._toggleBottomNavigationView()}>
                    <Image source={require('../../assets/icons/iconCross.png')} style={[{width: 14, height: 14}, t.mR1]}/>
                </TouchableOpacity>
            </View>
        )
    }

    validate(){
        var error = false
        var {refPayment, description} = this.state

        error = refPayment.length > 40 || description.length > 40

        this.setState({
            enabled: (refPayment != "" && !error && (this.total !="" && this.total != "0.00")),
            errorRef: refPayment.length > 40,
            errorDesc: description.length > 40
        })

    }

    renderForm(W, M, S){
        var web = Platform.OS == "web"
        return(
            <View style={[W ? t.w1_2 : t.wFull, !web && W && t.h10_12 ]}>
                <View style={[ t.mB2]}>
                    <Text style={[s.label, W && t.mB2]}>Riferimento pagamento*</Text>
                    <TextInput style={[W ? s.input : s.inputMobile]} onChangeText={(value) => {this.setState({refPayment: value}); this.validate()}}></TextInput>
                    {this.state.errorRef && <Text style={[s.error]}>Inserire max. 40 caratteri</Text>}
                </View>
                <View style={[t.mB2]}>
                    <Text style={[s.label, W && t.mB2]}>Descrizione</Text>
                    <TextInput style={[W ? s.input : s.inputMobile]} onChangeText={(value) => {this.setState({description: value}); this.validate()}}></TextInput>
                    {this.state.errorDesc && <Text style={[s.error]}>Inserire max. 40 caratteri</Text>}
                </View>
                <View style={[t.mB2]}>
                    <Text style={[s.label, W && t.mB2]}>Validità link*</Text>
                    <TouchableOpacity style={[W ? s.input : s.inputMobile, t.justifyCenter]} onPress={() => {this.setState({visible: true})}}>
                        <View style={[t.flexRow, t.itemsCenter]}>
                            <Text style={[t.w11_12, t.text5, t.textSecond, t.fontRegular ]}>{this.state.deadline ? this.state.deadline+" ore": ""}</Text>
                            <View style={[t.w1_12]}>
                                <Image source={require('../../assets/icons/arrow_down.png')} style={[ t.w4, t.h4 ]}></Image>
                            </View>
                        </View>
                    </TouchableOpacity>
                </View>
                <Text style={[t.textRed, t.text7]}>* campi obbligatori</Text>
            </View>
        )
    }


    renderKeypadBS( W, M, S ){
        var web = Platform.OS == "web"
        return (
            <View style={[W && [t.w1_2, web && t.hFull, !web && [t.h9_12, t.w5_12, {marginLeft: "5%"}]], !W && [{height: "45%"}, t.mT2]]}>
                <Text style={[s.label, W && t.mL8, t.mB2 ]}>Importo*</Text>
                <View style={[W && t.hFull, t.wFull, t.itemsCenter]}>
                    <View style={[W && [t.h11_12, t.wFull], !W && [t.w11_12, t.h11_12]]}>
                        <NumericKeyboard {...{onTotalChange: this.onTotalChange.bind(this), showConfirmBtn:false, defTotal: this.total}}></NumericKeyboard>
                    </View>
                </View>
            </View>
        )
    }

    onTotalChange(amount){
        this.total = amount
        this.validate()
    }

    async generageLink(){
        var body = {
            productCodeId: this.state.refPayment,
            description: this.state.description,
            validTo: this.state.deadline*60,
            amount: toCents(this.total),
            currency: "EUR"
        }
        console.log(body)
        let response = await PaymentService.generagePayToLink(body)
        if(response){
            var infoRes = {
                linkTopayUrl: response.linkToPayUrl,
                info: body
            }
            this.props.changeMode(false, infoRes)
        }

    }

    renderButton(W, M, S){
        return (
            <View style={[ this.state.enabled ? t.bgMainBlue : t.bgDarkGrey, W && t.w4_12, !W && t.w10_12, t.roundedFull, {padding: 8}]}>
                <TouchableOpacity disabled={!this.state.enabled} onPress={() => {this.generageLink()}}>
                    <Text style={[ t.textCenter, t.textWhite, t.text4, t.fontBold ]}> Genera Link to Pay</Text>
                </TouchableOpacity>
            </View>
        )
    }

    renderMobileView(W, M, S){
        return (
            <SafeAreaView style={[t.hFull, S && t.bgWhite]}>
                <View style={[t.hFull, S && t.bgWhite]}>
                    <View style={[ t.pT1, t.hFull, S && t.bgWhite ]}>
                        {
                            <View style={[t.flexRow, t.itemsCenter, t.wFull, t.p2, (W || M) && t.pL4, t.pT0]}>
                                <TouchableOpacity onPress={() => {this.props.changeMode(true)}} style={[t.w3_12]}>
                                    <Text style={[t.underline, t.text5, t.textSecond, t.fontBd]}>
                                        {"< indietro"}
                                    </Text>
                                </TouchableOpacity>
                                <Text style={[ t.text4, t.textSecond, t.fontBd, t.w1_2, t.textCenter]}>
                                    Link To Pay
                                </Text>
                            </View>
                        }
                        {
                            S && <View style={[t.m3, t.mT1, t.hFull]}>
                                {this.renderForm(W, M, S)}
                                {this.renderKeypadBS(W, M, S)}
                                <View style={[t.wFull, t.itemsCenter, t.mT2]}>
                                    {this.renderButton(W, M, S)}
                                </View>
                            </View>
                        }

                        {
                            (W || M) && <View style={[t.mX3, t.mT1, {height: "86%"}, t.bgWhite, t.pR3, t.pL5, t.pT3, t.roundedLg]}>
                                <View style={[t.flexRow]}>
                                    {this.renderForm(W, M, S)}
                                    {this.renderKeypadBS(W, M, S)}
                                </View>
                                <View style={[t.wFull, t.itemsCenter]}>
                                    {this.renderButton(W, M, S)}
                                </View>
                            </View>
                        }
                    </View>

                    <CBottomSheet { ...{visible: this.state.visible, _toggleBottomNavigationView: this._toggleBottomNavigationView, renderContent: this.renderContent.bind(this)} }></CBottomSheet>
                </View>
            </SafeAreaView>
        )
    }

    renderWebView(W, M, S) {
        return (
            <View style={[t.h10_12, t.bgWhite]}>
                <View style={[ t.hFull, t.pT3, t.bgWhite, t.roundedLg, t.border2, t.borderDisGrey, t.pB5, M && [t.border0], W && [t.border0] ]}>

                    <View style={[W && t.flexRow, t.m3, t.hFull]}>
                        {this.renderForm(W, M, S)}
                        {this.renderKeypadBS(W, M, S)}
                    </View>
                </View>
                <View style={[t.flexRow, t.itemsCenter, t.mT3]}>
                        <TouchableOpacity onPress={() => {this.props.changeMode(true)}} style={[t.w4_12]}>
                            <Text style={[t.text5, t.fontBold, t.underline, t.textSecond]}>Torna alla pagina di pagamento</Text>
                        </TouchableOpacity>
                        {
                            this.renderButton(W, M ,S)
                        }
                </View>
                <CBottomSheet { ...{visible: this.state.visible, _toggleBottomNavigationView: this._toggleBottomNavigationView, renderContent: this.renderContent.bind(this)} }></CBottomSheet>
            </View>
        )
    }

    render() {
        var {W, M, S} = this.props
        if(Platform.OS == "web"){
            return this.renderWebView(W, M, S)
        }else{
            return this.renderMobileView(W, M, S)
        }
    }
}

const s = {
    label: [ t.fontRegular, t.textSecond, t.text5],
    input: [t.h8, t.border1, t.borderDarkGrey, t.rounded, t.p2, t.text5, t.textSecond, t.fontRg],
    inputMobile : [t.h5, t.border1, t.borderDarkGrey, t.text5, t.pX2, t.textSecond, t.fontRg],
    text: [t.h6, t.p2, t.text5, t.textSecond, t.fontRg, t.borderT1, t.borderDisGrey ],
    error: [t.text7, t.textRed, t.fontRg]
}
