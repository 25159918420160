import React from 'react';

import {
  Text,
  View,
  Image,
  StyleSheet,
  TouchableHighlight
} from 'react-native';

import { t } from "../../assets"
import { mq } from '../utils/Utility';
import homeController from '../controller/home.controller';


export class ArrowTopBar extends React.Component<{toogle: any}, { W, M, S: boolean}> {
    
    constructor(navigation: any){
        super(navigation)
        this.state = { W: false, M: false, S: false};
    }

    static navigationOptions = {
        headerStyle: {
         visibility: "hidden",
         height: 0 
        },
    };

    render(){
        const { W, M, S } = this.state;
        return (
            
            <View style={[ t.wFull, t.flexRow, t.pY3, {paddingHorizontal: "2%"}, M && t.pY3, W && t.pY3 ]} onLayout={mq(this)}>
                <TouchableHighlight style={[ t.itemsStart, t.justifyCenter, t.pX2 ]} onPress={() => this.props.toogle()}>
                    <Image source={require('../../assets/icons/arrow-icon.jpg')} style={[{width: 27, height: 27}, t.mR1 ]}/>
                </TouchableHighlight>
                <View style={[ t.w11_12, t.mL2 ]}>
                    <Text style={[ t.text4, t.fontBd, t.textFirst ]}>Listini</Text>
                </View>
            </View>    
   
        )
    }
}