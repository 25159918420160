import React, { Component } from 'react'
import {SkyLightStateless} from 'react-skylight';
import { t } from "../../assets"

import { View } from 'react-native'




export class CBottomSheet extends React.Component<{ visible:boolean, _toggleBottomNavigationView: any, renderContent: any}> {

    render() {

        return (
            
            <SkyLightStateless 

            onOverlayClicked={() => {this.props._toggleBottomNavigationView()}}  
            onCloseClicked={() => {this.props._toggleBottomNavigationView()}} 
            isVisible={this.props.visible} 
            transitionDuration={20} 

            closeButtonStyle={{ fontSize: 0 }}
			dialogStyles={{ height: "100vh", width: "100%", top: 0, left: 0, margin: 0, padding: 0, backgroundColor: "#FFFFFF00"}}>

                <View style={[ t.bgWhite, t.rounded, t.itemsCenter, t.justifyCenter, t.overflowHidden,
                {width: 600, top: "50%", left: "50%", transform: [{ translate: "-50%, -50%" }]}]}>
                    {this.props.renderContent()}  
                </View>

            </SkyLightStateless>
        )
    }
}