import React from "react";
import { 
    View, 
    Text, 
    ScrollView, 
    StyleSheet, 
    Platform, 
    TouchableHighlight,
    TouchableOpacity,
    TextInput,
    Image,
    ColorPropType
} from "react-native";

import { PaymentCard } from "../components/paymentCard";
import movementsController from "../controller/movements.controller";
import { t, col } from "../../assets"
import { Payment } from "../model/Payment";
import { NavigationEvents } from "react-navigation";
import moment from "moment";
import { mq } from "../utils/Utility";
import { CAlert } from "../components/alert";
import { CBottomSheet } from "../components/bottomSheet";
import loginController from "../controller/login.controller";
import { CalendarPicker } from "../components/calendar";
require("moment/min/locales.min");


interface IState {
    W, M, S: boolean;
    list: Payment[];
    pagination: any;
    visible: boolean;
    inAmount, outAmount, totAmount: any; 
    all, today, yesterday, calendar, error, showCalMsg: boolean; 
    startDate; 
    endDate: string;
    dateType: string;
    filterStatusValue: any;
    BSMode: number;
}

export class HistoricPayment extends React.Component<{onRef: any, _toggleBottomNavigationView: any}, IState>{
    
    page: any
    el: any
    params: any
    filterType: any
    sd: string;
    ed: string;
    filterStatus= [
        {
            id: -1,
            name: "Tutti"
        },
        {
            id: "TINABA",
            name: "Tinaba" 
        },
        {
            id: "ALIPAY",
            name: "Alipay" 
        },
        {
            id: "ALIPAY_CONNECT",
            name: "Alipay connect online" 
        },
        {
            id: "PAYPAL_WALLET",
            name: "Paypal wallet" 
        },
        {
            id: "PAYPAL_CARD",
            name: "Paypal carte" 
        }
    ]

    constructor(navigation: any){
        super(navigation)
        this.state = { 
            W: false,
             M: false, 
             S: false, 
             list: [], 
             pagination: {}, 
             visible: false,
            inAmount: 0, 
            outAmount: 0, 
            totAmount: 0, 
            all: true, 
            today: false, 
            yesterday: false, 
            calendar: false, 
            error: false, 
            showCalMsg: false, 
            startDate: "", 
            endDate: "", 
            dateType: "",
            filterStatusValue: this.filterStatus[0], 
            BSMode: 0
        }

        this.params = {}
        this.filterType = "all"
        this.sd = ""
        this.ed = ""
        this.el = Platform.OS == 'web' ? 14 : 10
    }

    componentWillUnmount() {
        this.props.onRef(undefined)
    }

    async componentDidMount(){
        this.props.onRef(this)
        this.page = 0
        this.getHistoricPayment()
    }

    async getHistoricPayment(){
        this.page = 0;
        var res = await movementsController.getHistoricPayments(this.page, this.el, this.params, this.logout.bind(this, this.props), this.props)
        var amounts = this.filterType != "all" ? await movementsController.getMovimentAmounts(this.params) : {outAmount: 0, inAmount: 0, totAmount: 0}
        this.setState({list: res.transaction, pagination: res.page, inAmount: amounts.inAmount, outAmount: amounts.outAmount, totAmount: amounts.totAmount })
    }

    async logout(props){
        loginController.logout(props)
    } 

    async loadMore(){
        if(this.page + 1 < this.state.pagination.totalPages){
            this.page = this.page + 1
            var currentList = this.state.list
            var res = await await movementsController.getHistoricPayments(this.page, this.el, this.params, this.logout.bind(this, this.props), this.props)
            var newList = [...currentList, ...res.transaction].sort((a,b) => +moment(b.timestamp) - +moment(a.timestamp))  
            this.setState({list: newList, pagination: res.page})
        }
    }

    filterCalendarClb(){
        this.setState({list: []})
    }

    filterCallback(data){
        this.filterType = data.filterType
        switch(data.filterType){
            case "all":
                this.params = {}
                break
            case "today":
                this.params.startDate = moment().format("YYYYMMDD")
                this.params.endDate = moment().format("YYYYMMDD")
                break
            case "yesterday":
                this.params.startDate = moment().subtract(1, 'days').format("YYYYMMDD")
                this.params.endDate = moment().subtract(1, 'days').format("YYYYMMDD")
                break;
            case "cal":
                this.params.startDate = moment(data.sd, "DD/MM/YYYY").format("YYYYMMDD")
                this.params.endDate = moment(data.ed, "DD/MM/YYYY").format("YYYYMMDD")
                break; 
        }
        this.getHistoricPayment()

    }

    toogleFilters(ev){
        this.setState({all: ev == "all", today: ev == "today", yesterday: ev == "yesterday", calendar: ev == "cal"})
        if(ev == "all" || ev == "today" || ev == "yesterday"){
            this.setState({showCalMsg: false})
            this.filterCallback({filterType: ev})
        }else{
            this.setState({showCalMsg: true, startDate: "", endDate: ""})
            this.filterCalendarClb()
        }
    }

    _toggleBottomNavigationView = (ev?) => {
        this.setState({ dateType: ev, visible: !this.state.visible });
    };

    validate = () =>{
        console.log("sd: "+this.sd+" ed: "+this.ed)
        if(this.sd != "" && this.ed != ""){
            if(new Date(this.sd).getTime() > new Date(this.ed).getTime()){
                this.setState({error: true})
            }else{
                this.setState({error: false, showCalMsg: false})
                this.filterCallback({filterType: "cal", sd: this.sd, ed: this.ed})
            }
        }
    }

    callBackCalendar(d){
        var date = d.dateString ? moment(d.dateString).format("DD/MM/YYYY") : d
        console.log(date) 
        if(this.state.dateType == "startDate"){
            this.sd = date
            this.setState({startDate: this.sd })
            this._toggleBottomNavigationView()
        }else{
            this.ed = date
            this.setState({endDate: this.ed })
            this._toggleBottomNavigationView()
        }
        this.validate()
    }

    renderBalance(){
        return (
            <View style={[t.wFull, t.itemsCenter, t.justifyCenter, t.mB2]}>
                <View style={[t.wFull, t.itemsCenter]}>
                    <Text style={[t.fondBd, t.fontBold, {fontSize: 30}, t.textMidBlue]}>€ {Number(this.state.totAmount/100).toFixed(2)}</Text>
                    <Text style={[t.fondBd, t.text5, t.textMidBlue]}>INCASSO</Text>
                </View>

                <View style={[this.state.S && t.flexRow, t.mT1, t.itemsCenter, t.justifyCenter, t.wFull]}>
                    <Text style={[t.fondBd, t.text5, t.textDarkGrey, this.state.S && t.w1_2]}>Pagamenti: €{Number(this.state.inAmount/100).toFixed(2)}</Text>
                    <Text style={[t.fondBd, t.text5, t.textDarkGrey]}>Rimborsi: €{Number(this.state.outAmount/100).toFixed(2)}</Text>
                </View>
            </View>
        )
    }

    renderContent(){
        return (
            <View style={[t.bgWhite, t.roundedTLg, t.p4, t.pB5, t.flexWrap, t.flexRow]}>
                <View style={[t.w11_12]}>
                    <CalendarPicker callback={this.callBackCalendar.bind(this)}></CalendarPicker>
                </View>
                <TouchableOpacity style={[ t.w1_12, t.h6, t.justifyCenter, t.itemsEnd ]} onPress={() => this._toggleBottomNavigationView(false)}>
                    <Image source={require('../../assets/icons/iconCross.png')} style={[{width: 14, height: 14}, t.mR1]}/>
                </TouchableOpacity>
            </View>
        )
    }

    renderStatusFilter(W, M, S){
        return(
            <View style={[W && [{width: "35%"}, t.pR3, t.itemsEnd], !W && [t.itemsCenter, t.mB2]]}>
                <View style={[t.flexRow, t.itemsCenter]}>
                    <Text style={[t.text5, t.textSecond, t.fontRegular]}>METODO DI INCASSO</Text>  
                    <TouchableOpacity style={[s.input, t.justifyCenter]} onPress={() => {this.setState({visible: true, BSMode:1})}}>
                        <View style={[t.flexRow, t.itemsCenter]}>
                            <Text style={[t.w11_12, t.text5, t.textSecond, t.fontRegular ]}>{this.state.filterStatusValue ? this.state.filterStatusValue.name : ""}</Text>
                            <View style={[t.w1_12]}>
                                <Image source={require('../../assets/icons/arrow_down.png')} style={[ t.w4, t.h4 ]}></Image>
                            </View>
                        </View>
                    </TouchableOpacity>
                </View> 
            </View>
        )
    }

    renderFilterChoose(){
        return (
            <View style={[t.bgWhite, t.roundedTLg, t.p2, t.pB2, t.wFull, t.flexRow]}>
                <View style={[t.w11_12, t.borderB1, t.borderDisGrey]}>
                    {
                        this.filterStatus.map(item => {
                            return (
                                <TouchableHighlight onPress={() => {this.setFilterStatusValue(item)}}>
                                    <Text style={[s.text]}>{item.name}</Text>
                                </TouchableHighlight>
                            )
                        })
                    }
                </View>
                <TouchableOpacity style={[ t.w1_12, t.h6, t.justifyCenter, t.itemsEnd ]} onPress={() => this._toggleBottomNavigationView()}>
                    <Image source={require('../../assets/icons/iconCross.png')} style={[{width: 14, height: 14}, t.mR1]}/>
                </TouchableOpacity>
            </View>
        )
    }

    setFilterStatusValue(item){
        this.setState({filterStatusValue: item})
        this._toggleBottomNavigationView()
        if(item.id != -1){
            this.params.status = item.id
        }else{
            this.params.status = undefined
        }
        this.getHistoricPayment()
    }

    renderFilter(){
        const { W, M, S, all, today, yesterday, calendar, error } = this.state;
        moment.locale("it")
        return (
            <View style={[t.wFull]}>
                <View style={[t.wFull, t.mB2, t.flexRow, t.itemsCenter, t.justifyCenter ]} onLayout={mq(this)}>
                    <View style={[W && {width: "65%"}, t.flexRow, t.justifyEnd]}>
                        <TouchableHighlight style={[ t.justifyCenter, t.itemsCenter, t.bgThird, t.h5, {borderRadius: 50, width: W || M ? 110 : "27%", marginLeft: 9},  all && t.bgWhite ]} onPress={() => {this.toogleFilters("all")}}>
                            <Text style={[ t.fontBd, t.textWhite, t.text5, t.textMidBlue]}>TUTTI</Text> 
                        </TouchableHighlight>
                        
                        <TouchableHighlight style={[ t.justifyCenter, t.itemsCenter, t.bgThird, t.h5, {borderRadius: 50, width: W || M ? 80 :"20%", marginLeft: 9},  today && t.bgWhite ]} onPress={() => {this.toogleFilters("today")}}>
                            <Text style={[ t.fontBd, t.textWhite, t.text5, t.textMidBlue]}>OGGI</Text> 
                        </TouchableHighlight> 

                        <TouchableHighlight style={[ t.justifyCenter, t.itemsCenter, t.bgThird, t.h5, {borderRadius: 50, width: W || M ? 80 :"20%", marginLeft: 9},  yesterday && t.bgWhite ]} onPress={() => {this.toogleFilters("yesterday")}}>
                            <Text style={[ t.fontBd, t.textWhite, t.text5, t.textMidBlue]}>IERI</Text> 
                        </TouchableHighlight> 

                        <TouchableHighlight style={[ t.justifyCenter, t.itemsCenter, t.bgThird, t.h5, {borderRadius: 50, width: W || M ? 80 :"20%", marginLeft: 9},  calendar && t.bgWhite ]} onPress={() => {this.toogleFilters("cal")}}>
                            <Image style={[t.w4, t.h4, {tintColor: col.midBlue}]} source={require('../../assets/icons/calendar.png')}></Image> 
                        </TouchableHighlight>
                    </View> 
                    {
                        W && this.renderStatusFilter(W, M, S)
                    }
                </View>
                {
                    today && <View style={[t.mL3, t.mB2, W && t.itemsCenter]}>
                        <Text style={[t.text7, t.uppercase, t.textDarkGrey, t.fontBold]}>{moment().format("dddd DD MMMM YYYY")}</Text>
                    </View>
                }
                {
                    calendar && <View style={[t.mB2, t.pL3, t.pR3, t.flexRow, t.wFull, t.itemsCenter, t.justifyCenter]}>
                        
                        <TouchableHighlight  style={[t.h6, t.rounded, {width:  W || M ? 150:"48%"}, t.mR2, t.bgWhite, t.border1, error ? t.borderRed : t.borderWhite]} onPress={() => {this._toggleBottomNavigationView("startDate")}}>
                                <View style={[t.flexWrap, t.flexRow]}>
                                    <View style={[t.h6, t.w3_12, t.justifyCenter, t.itemsCenter]}>
                                        <Text style={[t.mL1, t.textMidBlue, t.text6, t.textCenter]}>Da: </Text>
                                    </View>
                                    <View style={[t.h6, t.w9_12, t.justifyCenter, t.itemsCenter]}>
                                        <Text style={[this.state.startDate != "" ? t.textMidBlue : t.textDarkGrey, t.fontBold, t.text6 ]}>
                                            {this.state.startDate != "" ? this.state.startDate : "DD/MM/YYYY"}
                                        </Text>  
                                    </View>
                                </View>
                        </TouchableHighlight>
                        <TouchableHighlight  style={[t.h6, t.rounded, {width: W || M ? 150: "48%"}, t.bgWhite, t.border1, error ? t.borderRed : t.borderWhite]} onPress={() => {this._toggleBottomNavigationView("endDate")}}>
                                <View style={[t.flexWrap, t.flexRow]}>
                                    <View style={[t.h6, t.w3_12, t.justifyCenter, t.itemsCenter]}>
                                        <Text style={[t.mL1, t.textMidBlue, t.text6, t.textCenter]}>A: </Text>
                                    </View>
                                    <View style={[t.h6, t.w9_12, t.justifyCenter, t.itemsCenter]}>
                                        <Text style={[this.state.endDate != "" ? t.textMidBlue : t.textDarkGrey, t.fontBold, t.text6 ]}>
                                            {this.state.endDate != "" ? this.state.endDate : "DD/MM/YYYY"}
                                        </Text>  
                                    </View>
                                </View>
                        </TouchableHighlight>

                    </View>
                }
                {
                    !W && this.renderStatusFilter(W, M ,S)
                }   
                { this.state.showCalMsg &&
                <View style={[t.itemsCenter, t.mT6]}>
                    <Text style={[t.text5, t.fontBd, t.fontBold, t.textMidBlue]}>Seleziona un arco temporale</Text>
                </View>
                }  
                { this.state.list.length == 0 && !this.state.showCalMsg &&
                <View style={[t.itemsCenter, t.mT6]}>
                    <Text style={[t.text5, t.fontBd, t.fontBold, t.textMidBlue]}>Non ci sono movimenti</Text>
                </View>
                }    
            </View> 
        )
    }

    render(){
        const { W, M, S } = this.state;
        return (
            <View onLayout={ mq(this) } style={[ t.flexWrap, t.flexRow, W && [] ]}>
                <NavigationEvents
                    onDidFocus ={() => {
                        this.page = 0
                        this.getHistoricPayment()
                    }}
                />
                { this.renderFilter()}
                { this.filterType != "all" && this.state.list.length != 0 && this.renderBalance()}
                {
                    this.state.list.map((el, i) => {
                        return <PaymentCard key={i} refresh={this.getHistoricPayment.bind(this)} _toggleBottomNavigationView={this.props._toggleBottomNavigationView} {...{ W, M, S, item: el }}></PaymentCard>
                    })
                }
                
                <CAlert event={"historicPay"}></CAlert>
                <CBottomSheet { ...{visible: this.state.visible, _toggleBottomNavigationView: this._toggleBottomNavigationView, renderContent: this.state.BSMode == 0 ? this.renderContent.bind(this) :  this.renderFilterChoose.bind(this)} }></CBottomSheet>
            </View>
        )
    }
}

const s = {
    input: [ t.w15, t.mL2, t.border1, t.borderDarkGrey, t.pX2, t.text5, t.textSecond, t.fontRegular],
    text: [t.h6, t.p2, t.text5, t.textSecond, t.fontRegular, t.borderT1, t.borderDisGrey ]
} 

const e = StyleSheet.create({

    // Fix height 100% on mobile + web
    heightCustom: { height: (Platform.OS === 'web') ? "100vh" : "100%" },
    
    // Fix z-index bottom sheet android 
    elevation0: { elevation: 0 },
    elevation50: { elevation: 50 },

    // Add custom horizontal margin for iPad view
    customMarginX: { marginHorizontal: "2%" }

});