import React from 'react';

import { 
    View, 
    Text, 
    Image, 
    StyleSheet, 
    TouchableOpacity,
    Platform,
    DeviceEventEmitter
} from "react-native";

import { t, extra } from "../../assets"
import { OrderAndPay } from '../model/OrderAndPay';
import { vas } from "../utils/Utility"
import Moment from 'moment';
import { CBottomSheet } from './bottomSheet';
import { PayDetails } from './payDetail';
import TransactionService from '../services/TransactionService';
import { CAlert } from './alert';



export class PaymentCard extends React.Component<{ W, M, S: boolean, item: any, refresh: any, _toggleBottomNavigationView: any, style?: any },  {visible: boolean, modalType: string}> {
    modalEvent = "historicPay"; 

    constructor(props){
        super(props)
        this.state = {visible: false, modalType: "REFUND"}
    }

    infoStandardCard = {
        pTitle: "Ok",
        page: "Home",
        navigation: "",
        onClick: "",
        value: ""
    }

    openRefundModal(){
        console.log("OpenRefundModal")
        this.setState({modalType: "REFUND"})
        CAlert.showModal({data: {actionFunc: this.refund.bind(this), props: this.props}, type:this.state.modalType}, this.modalEvent)
        Platform.OS == "web" && this.props._toggleBottomNavigationView()
    }

    async refund(){
        this.props.item.refund().then(
            () => {
                CAlert.dismissModal(this.modalEvent)
                //Platform.OS == "web" && this.props._toggleBottomNavigationView()
                this.setState({modalType: "SUCCESS_REFUND"})
                CAlert.showModal({data: {props: this.props}, type:this.state.modalType}, this.modalEvent)
                this.props.refresh()
                this.setState({modalType: "REFUND"})
            },
            () => {
                //Platform.OS == "web" && this.props._toggleBottomNavigationView()
                CAlert.dismissModal(this.modalEvent)
                this.setState({modalType: "ERROR_REFUND"})
                CAlert.showModal({data: {actionFunc: this.refund.bind(this), props: this.props}, type:this.state.modalType}, this.modalEvent)
                this.setState({modalType: "REFUND"})
            }
        )      
    }


    renderContentBS({ W, M, S }){
        return(          
            <PayDetails refresh={this.props.refresh} dismiss={this.props._toggleBottomNavigationView} refund={this.openRefundModal.bind(this)} {...{ W, M, S, item: this.props.item }}></PayDetails>
        )
    }
    
    render(){
        const { W, M, S } = this.props
        return(

            <View style={[ this.state.visible && {zIndex: 100}, t.flex, t.flexWrap, t.flexRow, t.wFull, t.bgWhite, t.rounded, {marginBottom: 9}, 
            e.elevation0, extra.elevationShadow(2), M && [{width: "48%", marginHorizontal: "1%"}, t.mB2], W && [{width: "48%", marginHorizontal: "1%"}, t.mB2] ]}>

                {
                    this.props.item.direction == "OUTGOING" && <View style={[ t.wFull, t.h1, t.bgRed, t.roundedT ]}></View>
                }

                <View style={[ t.flexWrap, t.flexRow, t.wFull, t.p2 ]}>

                    {
                    /*<View style={[ t.w2_12, t.h6, t.justifyCenter ]}>
                        <Image source={require('../../assets/img/profileSmall.png')} style={[ t.w5, t.h5, t.roundedFull ]}/>
                    </View>*/
                    }

                    <View style={[ t.w9_12, t.h6, t.justifyCenter, t.pL1 ]}>
                        <Text style={[ t.fontBd, t.textFirst, this.props.item.direction == "OUTGOING" && t.textRed, t.text5 ]}>{this.props.item.objectAlias ? this.props.item.objectAlias : this.props.item.message}</Text>
                        <Text style={[ t.fontRg, t.textSecond ]}>{Moment(this.props.item.timestamp).isSame(Moment(), 'day') ? "OGGI "+Moment(this.props.item.timestamp).format('HH:mm') : Moment(this.props.item.timestamp).format('DD/MM/YYYY HH:mm')}</Text>
                    </View>
                    
                    <View style={[ t.w3_12, t.h6, t.itemsEnd, t.justifyCenter ]}>
                        <Text style={[ t.fontBd, t.textFirst, t.text4 ]}>{(this.props.item.amount+this.props.item.fee/100).toFixed(2).replace(".", ",")}€</Text>
                    </View>

                    <View style={[ t.wFull, t.borderB1, t.borderDisGrey, {marginTop: 10}, t.mB1 ]}></View>

                    <View style={[ t.w10_12, t.h5, t.flexWrap, t.contentStart, t.justifyCenter, t.block ]}>

                        <View style={[t.w2_12]}>
                            <Image source={require('../../assets/icons/faiPagare.png')} style={[ t.w4, t.h4, t.mL1, t.mR3 ]}/>
                        </View>
                        {
                            this.props.item.direction == "OUTGOING" && <View style={[ t.justifyCenter, t.itemsCenter, t.textCenter, t.bgRed, t.w4_12, t.h4, {borderRadius: 6}]}>
                                <Text style={[ t.fontBd, t.textWhite, t.text5 ]}>Rimborso</Text>
                            </View>
                        }
                        {
                            this.props.item.direction != "OUTGOING" && <View style={[t.w4_12]}> 
                                <Text style={[ t.fontBd, t.textSecond, this.props.item.direction == "OUTGOING" && t.textRed, t.text5]}>Pagamento</Text>
                            </View>
                        }
                        {
                            <View style={[ t.justifyCenter, t.itemsEnd, t.h4, t.w7_12]}>
                                <Text style={[ t.fontRegular, t.text5, t.textSecond, t.textCenter ]}>{this.props.item.getEventType()}</Text>
                            </View>
                        }
                        {
                            this.props.item.direction != "OUTGOING" && <TouchableOpacity style={[ t.w1_12, t.h5, t.itemsEnd, t.justifyCenter ]} onPress={() => this.props._toggleBottomNavigationView(this.renderContentBS.bind(this, {W, M, S}))}>
                                <Image source={require('../../assets/icons/infoDotsCard.png')} style={[ t.w4, t.h4, t.borderL1, t.borderThird ]}/>
                            </TouchableOpacity>
                        }

                    </View>

                </View>
            </View>
        )
    }
}

const e = StyleSheet.create({

    // Custom border radius
  
    
    // Fix z-index bottom sheet android 
    elevation0: { elevation: 0 },
    elevation50: { elevation: 50 },

});