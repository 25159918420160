import React, { Component } from 'react'

import { 
    Text,
    StyleSheet,
    TouchableHighlight, 
} from 'react-native'

import { t } from "../../assets"

interface IButtonProps {
    pTitle: string;
    page?: string;
    onClick: any;
    navigation: any;
    value:any;
    style: any;      //For styling components from the exterior (and also "this.props.style") 
}

export class LargeButtonEmpty extends React.Component<IButtonProps> {
    render() {
        return (

            // Fix button color on click!

            <TouchableHighlight underlayColor="#fafbfc" onPress={() => {this.props.onClick(this.props)}}
            style={[ t.flex, t.justifyCenter, t.border1, e.customBorderColor, t.rounded, this.props.style]}>
                <Text style={[ t.text5, t.fontRg, t.textSecond, t.textCenter]}>{this.props.pTitle}</Text>
            </TouchableHighlight>
        );
    }
}


const e = StyleSheet.create({

    // Custom border color to match inside text
    customBorderColor: { borderColor: "#859aa7" },

});