import React from "react";
import { View, Text, Platform } from "react-native";
import { OrderCard } from "../components/orderCard";
import movementsController from "../controller/movements.controller";
import { t } from "../../assets"
import { NavigationEvents } from "react-navigation";
import moment from "moment";
import { mq } from "../utils/Utility";
import { CAlert } from "../components/alert";
import loginController from "../controller/login.controller";

export class HistoricOrder extends React.Component<{onRef: any, _toggleBottomNavigationView: any}, { W, M, S: boolean, list: any, pagination: any }> {
    
    page
    el

    constructor(navigation: any){
        super(navigation)
        this.state = { W: false, M: false, S: false, list: [], pagination: {} };
        this.el = Platform.OS == 'web' ? 14 : 10
    } 
    async componentDidMount(){
        this.props.onRef(this)
        this.page = 0
        this.getHistoricOrders()
    
    }
 
    componentWillUnmount() {
        this.props.onRef(undefined)
    }

    async getHistoricOrders(){
        this.page = 0
         var res = await movementsController.getHistoricOrders(this.page, this.el, this.logout.bind(this, this.props), this.props)
         this.setState({list: res.transaction, pagination: res.page})
    }

    async loadMore(){
        if(this.page + 1 < this.state.pagination.totalPages){
            this.page = this.page + 1
            var currentList = this.state.list
            var res = await await movementsController.getHistoricOrders(this.page, this.el, this.logout.bind(this, this.props), this.props)
            var newList = [...currentList, ...res.transaction].sort((a,b) => +moment(b.timestamp) - +moment(a.timestamp))  
            this.setState({list: newList, pagination: res.page})
        }
    }

    async logout(props){
        loginController.logout(props)
    } 

    render(){
        const { W, M, S } = this.state;
        return (
            <View onLayout={ mq(this) } style={[ t.flexWrap, t.flexRow, W && [t.justifyBetween] ]}>
                <NavigationEvents
                    onDidFocus ={() => {
                        this.page = 0
                        this.getHistoricOrders()
                    }}
                />
                {
                    this.state.list.map((el, i) => {
                        return <OrderCard key={i} refresh={this.getHistoricOrders.bind(this)} _toggleBottomNavigationView={this.props._toggleBottomNavigationView} {...{ W, M, S, item: el }}></OrderCard>
                    })
                
                }
                <CAlert event={"historicOrder"}></CAlert>
            </View>
        )
    }
}