import ServiceBase from "./ServiceBase";
import { environment } from "../environment/envinments";


class PaymentService extends ServiceBase {

    constructor() {
        super()
    }

    async generatePaymentCode(shopId: number) {
        return await this.GET(`${environment.api.userUrl}/qrcodes/shop/${shopId}`);
      }

    async getPendingRequestedPayments() {
        return await this.GET(`${environment.api.userUrl}/bill/business/pending`)
      }

    async generateNewPurchase(amount: any) {
        return await this.POST(`${environment.api.userUrl}/bill/business/let-pay`, JSON.stringify({
            "amount" : amount,
            "currency" : "EUR",
            "eventType" : "LET_PAY"
        }));
    }

    async generateNewPurchaseWithId(paymentId: any, amount: any) {
        return await this.PATCH(`${environment.api.userUrl}/bill/business/generate`,  JSON.stringify({
            "id" : paymentId,
            "amount" : amount,
            "currency" : "EUR",
            "eventType" : "BILL_TO_PAY"
          }));
    }

    async checkPurchase(purchaseId: any) {
        if(purchaseId){
            return await this.GET(`${environment.api.userUrl}/bill/user?id=${purchaseId}`);
        }else{
            return Promise.reject(
            )
        }
    }

    async deleteRequestedPayment(pendingId: any) {
        return await this.DELETE(`${environment.api.userUrl}/bill/business/${pendingId}`);
    }

    async hideRequestPayement(pendingId: any){
        return await this.POST(`${environment.api.userUrl}/bill/business/pending/hide`, JSON.stringify({
          id: pendingId
        }));
    }

    async generagePayToLink(body){
        return await this.POST(`${environment.api.userUrl}/link-to-pay/initCheckout`, JSON.stringify(body));
    }

}

export default new PaymentService()
