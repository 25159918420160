import { AsyncStorage } from "react-native";
import VassesService from "../services/VassesService";

class ListController{

    vassesIds = [1, 3, 4, 5, 8]

    async getCashInfo(){
        return await AsyncStorage.getItem("wp_merchantDeviceData")
    }

    async getCollection(){
        return this.getCashInfo().then(
            async (values) => {
                const businessId = JSON.parse(values!).id;
                const shopId = JSON.parse(values!).shop.id
                const res =  await VassesService.getCollections(businessId, shopId)
                return res._embedded.menus
        })
    }

    async getVasses(){
        return this.getCashInfo().then(
            async (values) => {
                var vasses: any = []
                const businessId = JSON.parse(values!).id;
                const shopId = JSON.parse(values!).shop.id
                const res =  await VassesService.getVasses(businessId, shopId)
                res._embedded.vasses.forEach(element => {
                    if(this.vassesIds.includes(element.vasTypeId) && element.status == "SUBSCRIBED"){
                        vasses.push(element)
                    }
                });
                return vasses
        })
    }

    async enableVas(vasTypeId, listId){
        return this.getCashInfo().then(
            async (values) => {
                const businessId = JSON.parse(values!).id;
                const shopId = JSON.parse(values!).shop.id
                await VassesService.setVasEnabledList(businessId, shopId, vasTypeId, listId)    
        })
    }

    async setProductVisibility(productId, avail){
        return this.getCashInfo().then(
            async (values) => {
                const businessId = JSON.parse(values!).id;
                return VassesService.setAvailability(businessId, productId, avail).then(
                    () => {return},
                    () => {return}
                )    
        })
    }
}

export default new ListController()