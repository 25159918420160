import {environment} from '../environment/envinments'
import {AsyncStorage, DeviceEventEmitter} from 'react-native'
import AuthService from './AuthService';

var loopHandler = {};
var appBlocked = false;

export default class ServiceBase {
    headers: any
    baseUrl: String

    constructor(headers = {}) {

        this.headers = {
            Accept: "application/json",
            "Content-Type": "application/json",
        };

        Object.assign(this.headers, headers);

        this.baseUrl = environment.api.baseUrl;
    }

    _fullRoute(url: String) {
        return `${this.baseUrl}${url}`;
    }

    _preventLoopAuthentication(route: any) {
      const key = route || "key";
      if (!loopHandler[key]) { loopHandler[key] = 0; }
      loopHandler[key] = loopHandler[key]+1;

      if (loopHandler[key] >= 15){
        appBlocked = true;
      }

      setTimeout(() => {
        loopHandler[key] = 0;
      }, 10000);
    }

    async _fetch(route: String, method: any, body: any, headers = {}) {

        if (!route) {
            throw new Error("Route is undefined");
        }

        if (appBlocked) {
          DeviceEventEmitter.emit("force-logout");
          setTimeout(() => {
            loopHandler = {};
            appBlocked = false;
          }, 2000);

          return Promise.reject();
      }

        var fullRoute = this._fullRoute(route);

        let opts = {
            method,
            headers: {
                ...this.headers,
                ...headers,
            },
            body
        };

        console.log(JSON.stringify(fullRoute));

        if (body) {
            opts.body = body;
        }

        if (!opts.headers['Authorization']) {
            let token = await AsyncStorage.getItem("wp_access_token");
            if(token == null || token == ""){
                DeviceEventEmitter.emit("force-logout")
                return
            }
            opts.headers['Authorization'] = "Bearer " + token;
        }


        return fetch(fullRoute, opts)
            .then(
                (response) => {
                    return new Promise(
                        (resolve, reject) => {
                            if (response.status == 401) reject(401);
                            if(response.status >= 200 && response.status < 400){
                                response.status === 204 ? resolve({}) : resolve(response.json())
                            }else{
                                reject(response.status)
                            }
                        }
                    )
                }
            )
            .catch(
                async (cause) => {
                    console.log(`fetch rejected\ncause: ${cause}\nnow trying fallback ...`);
                    if (cause == 401) {
                      if (!appBlocked) {
                        this._preventLoopAuthentication(route);
                        //this.prevent

                          return AuthService.refresh()
                              .then(this._fetch(route, method, body, headers))
                      } else {
                        console.log("APP BLOCKED RETURNING REJECT");
                        await AsyncStorage.clear().then(() => console.log('Cleared'))
                        return Promise.reject();
                      }

                    }else{


                        return new Promise((resolve, reject) => {
                            reject(cause)
                        })
                    }
                }
            )
            .finally(() => {
                    console.log("fetch concluded");
                }
            )
    }

    async GET(route: String, headers = {}) {
        let msg = await this._fetch(route, "GET", undefined, headers);
        return msg;
    }

    async POST(route: String, body: any, headers = {}) {
        let msg = await this._fetch(route, "POST", body, headers);
        return msg;
    }

    async PUT(route: String, body: any, headers = {}) {
        let msg = await this._fetch(route, "PUT", body, headers);
        return msg;
    }

    async PATCH(route: String, body: any, headers = {}) {
        let msg = await this._fetch(route, "PATCH", body, headers);
        return msg;
    }

    async DELETE(route: String, headers = {}) {
        let msg = await this._fetch(route, "DELETE", undefined, headers);
        return msg;
    }
}
