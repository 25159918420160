import React from "react";
import { 
    Image 
} from "react-native";
import { t } from "../../assets"

export class Avatar extends React.Component<{ imageLink: any}, {avatar: any}> {
    
    constructor(props){
        super(props)
    }
    
    render(){
        return(
            <Image source={this.props.imageLink} style={[ t.w5, t.h5, t.roundedFull ]}/>
        )
    }
}
