import React from 'react';
import Calendar from 'react-calendar';
import { col, t } from '../../assets'
import moment from 'moment';

export class CalendarPicker extends React.Component<{callback: any}, { W, M, S }> {
    
    
    constructor(navigation: any){
        super(navigation)
        this.state = { W: false, M: false, S: false};
    }

    render(){
        return <Calendar
        minDate={new Date("1990-01-01")}
        maxDate={new Date("2100-01-01")}
        onChange={(d) => {this.props.callback(moment(d.toString()).format("DD/MM/YYYY"))}}
    />
    }
}