import React from 'react';

import { 
    View, 
    Text, 
    Image, 
    StyleSheet, 
    Dimensions,
    ColorPropType,
    TouchableOpacity,
    DeviceEventEmitter
} from "react-native";

import Moment from 'moment';
import { t, extra } from "../../assets"
import { LargeButtonEmpty } from './largeButtonEmpty';
import { LargeButtonFilled } from './largeButtonFilled';
import { PaymentsRequests } from '../model/PaymentsRequests';
import { CBottomSheet } from './bottomSheet';
import { NumericKeyboard } from './numericKeyboard';
import homeController from '../controller/home.controller';
import { CAlert } from './alert';
import { Avatar } from './avatar';
import loginController from '../controller/login.controller';

export class PendingPaymentCard extends React.Component<{ W, M, S: boolean, item: PaymentsRequests, refresh: any, _toggleBottomNavigationView: any }, {amount:any, eventType: any}> {
    
    interval: any
    openKeypadListener: any;
    modalEvent = "pendingHome"

    constructor(props){
        super(props)
        this.state = {amount: this.props.item.amount/100, eventType: this.props.item.eventType}
    }

    buttonSettings = {
        BILL_REQUEST: {
            label: "Invia conto",
            color: "#236A95"
        },
        BILL_TO_PAY: {
            label: "Conto inviato",
            color: "#EFAA07"
        },
        PAYMENT_SUCCESS: {
            label: "Tavolo pagato",
            color: "#7ED321"
        },
        PAYMENT_FAILS: {
            label: "Invia conto",
            color: "#236A95"
        }
    }

    infoTableBill = {
        pTitle: "Invia conto",
        page: "Home",
        navigation: "",
        onClick: "",
        value: ""
    }

    infoDenyTableBill = {
        pTitle: "Rifiuta",
        page: "Home",
        navigation: "", 
        onClick: this.refusePayment.bind(this, this.props.item.idRequestedPayment),
        value: ""
    }

    

    confirmPayTotal(id, total){
        this.generatePayment(id, total)
    }

    componentDidMount(){
        this.openKeypadListener = DeviceEventEmitter.addListener("open:keypad", () => this.props._toggleBottomNavigationView(this.renderKeypadBS.bind(this, {confirmPayTotal: this.confirmPayTotal, idPayment: this.props.item.idRequestedPayment}), this.state.eventType, this.acknowledgePayment.bind(this) ))
    }

    componentWillUnmount(){
        clearInterval(this.interval)
        this.openKeypadListener.remove()
    }
    
    async generatePayment(id, total){
        homeController.generatePayment(id, total, this.logout.bind(this, this.props), this.props, this.modalEvent).then(
            (result) => {
                if(result && result.id){
                    this.setState({amount: result.amount/100, eventType: result.eventType}) 
                    this.props._toggleBottomNavigationView() 
                
                    this.interval = setInterval(() => {
                        homeController.checkPurchase(result.id, this.logout.bind(this, this.props), this.props, this.modalEvent).then(
                            (res) => {
                                console.log(res)
                                if(res != "LET_PAY" && res != "BILL_TO_PAY" && res != "PAYMENT_STARTED"){
                                    res != "BILL_DELETED" && this.setState({eventType: res})
                                    clearInterval(this.interval)
                                }
                            },
                            (error) => console.log(error)
                        )
                    }, 2000);
                }else{
                    this.props._toggleBottomNavigationView()
                }
            },
        )
    }

    async logout(props){
        loginController.logout(props)
    } 

    async acknowledgePayment(){
        await homeController.acknowledgePayment(this.props.item.idRequestedPayment)
        this.props.refresh(true)
    }

    async refusePayment(){
        homeController.refusePayment(this.props.item.idRequestedPayment).then(
            () => { this.props.refresh()},
            (error) => { 
                CAlert.showModal({data: {props: this.props}, type:"PAY_REFRESH"}, this.modalEvent)
            }
        )  
    }

    renderKeypadBS(data){
        
        var heightKeysWebPopUp = (Dimensions.get('window').height * 0.6); 

        return (
            <View style={[ t.bgWhite, t.roundedTLg, t.wFull, t.pB5, {height: heightKeysWebPopUp} ]}>

                <TouchableOpacity style={[ t.wFull, t.justifyCenter, t.itemsEnd ]} onPress={() => {this.props._toggleBottomNavigationView(this.renderKeypadBS.bind(this, {confirmPayTotal: this.confirmPayTotal, idPayment: this.props.item.idRequestedPayment}), this.state.eventType,  this.acknowledgePayment.bind(this))}}>
                    <Image source={require('../../assets/icons/iconCross.png')} style={[{width: 14, height: 14}, t.mT3, t.mR3]}/>
                </TouchableOpacity>    

                <NumericKeyboard {...{confirmTotal: data.confirmPayTotal.bind(this), idPayment: data.idPayment, showConfirmBtn: true}}></NumericKeyboard>

            </View>
        )           
    }

    
    render(){
        const { W, M, S } = this.props
        return(

            <View style={[ t.flex, t.flexWrap, t.flexRow, t.wFull, t.bgWhite, t.rounded, t.mB1, t.p2, 
            extra.elevationShadow(1), e.elevation0, M && [{width: "48%", marginHorizontal: "1%"}, t.mB2], W && [{width: "48%", marginHorizontal: "1%"}, t.mB2] ]}>
                
                <View style={[ t.w2_12, t.h6, t.flex, t.justifyCenter ]}>
                    <Avatar imageLink={this.props.item.avatar}/>
                </View>
                <View style={[ t.w7_12, t.h6, t.flex, t.justifyCenter ]}>
                    <Text style={[ t.fontBd, t.textFirst, t.text5 ]} >{this.props.item.contactAlias}</Text>
                    <Text style={[ t.fontRg, t.textSecond ]}>{Moment(this.props.item.timestamp).isSame(Moment(), 'day') ? "OGGI "+Moment(this.props.item.timestamp).format('HH:mm') : Moment(this.props.item.timestamp).format('DD/MM/YYYY HH:mm')}</Text>
                </View>
                <View style={[ t.w3_12, t.h6, t.flex, t.itemsEnd, t.justifyCenter ]}>
                    <Text style={[ t.fontBd, t.textFirst, t.text4 ]}>{"Tavolo "+ this.props.item.table}</Text>
                </View>

                <View style={[ t.wFull, t.borderB1, t.borderDisGrey, {marginTop: 10}, t.mB1 ]}></View>

                <View style={[ t.w2_12, t.h5, t.flex, t.justifyCenter ]}>
                    <Image source={require('../../assets/icons/faiPagare.png')} style={[ t.w4, t.h4, t.mL1 ]}/>
                </View>
                
                <View style={[ t.w7_12, t.h5, t.flex, t.justifyCenter ]}>
                    <Text style={[ t.fontBd, t.textSecond, t.text5 ]} >Conto al tavolo</Text>
                </View>
                <View style={[ t.w3_12, t.h6, t.flex, t.itemsEnd, t.justifyCenter ]}>
                    <Text style={[ t.fontBd, t.textFirst, t.text4 ]}>{this.state.amount ? this.state.amount.toFixed(2).replace(".", ","): ""}</Text>
                </View>

                <LargeButtonFilled style={[ {width: "65%", marginRight: "1.666%"}, this.state.eventType == "PAYMENT_SUCCESS" && t.wFull, t.mT2, t.h5, {backgroundColor: this.buttonSettings[this.state.eventType].color} ]} { ...{pTitle: this.buttonSettings[this.state.eventType].label ,page: "Home", navigation: "", onClick: this.props._toggleBottomNavigationView.bind(this, this.renderKeypadBS.bind(this, {confirmPayTotal: this.confirmPayTotal, idPayment: this.props.item.idRequestedPayment}), this.state.eventType, this.acknowledgePayment.bind(this)), value: ""} }></LargeButtonFilled>
                {
                    this.state.eventType !== "PAYMENT_SUCCESS" && <LargeButtonEmpty style={[ t.w4_12, t.mT2, t.h5 ]} { ...this.infoDenyTableBill }></LargeButtonEmpty>
                }
            </View>
        )
    }
}

const e = StyleSheet.create({
    
    // Fix z-index bottom sheet android 
    elevation0: { elevation: 0 },
    elevation50: { elevation: 50 },

    dialogSwiper: {
        width: 70,
        height: 5,
        backgroundColor: "#a4a4a4",
        borderRadius: 2.5,
        borderColor: "#a4a4a4",
        marginTop: 10,
        marginBottom: 5,
    }
});