import React from 'react';

import { 
    View, 
    Text, 
    Image, 
    StyleSheet, 
    TouchableOpacity,
    Dimensions,
    AsyncStorage,
    Platform,
    DeviceEventEmitter,
    ScrollView
} from "react-native";

import { t, extra } from "../../assets"
import Moment from 'moment';
import { vas, orderIcon } from '../utils/Utility';
import { OrderDetails } from './orderDetail';
import { CAlert } from './alert';
import { Avatar } from './avatar';



export class OrderCard extends React.Component<{ W, M, S: boolean, item: any, refresh: any,  _toggleBottomNavigationView: any }, {visible: boolean, modalType: string}> {
    
    refundModal
    modalEvent = "historicOrder";

    constructor(props){
        super(props)
        this.state = {visible: false, modalType: "REFUND"}
    }

    infoStandardCard = {
        pTitle: "Ok",
        page: "Home",
        navigation: "",
        onClick: "",
        value: ""
    }

    openRefundModal(){
        this.setState({modalType: "REFUND"})
        CAlert.showModal({data: {actionFunc: this.refund.bind(this), props: this.props}, type:this.state.modalType}, this.modalEvent)
        Platform.OS == "web" && this.props._toggleBottomNavigationView()
    }

    async refund(){
        this.props.item.refund().then(
            () => {
                CAlert.dismissModal(this.modalEvent)
                this.setState({modalType: "SUCCESS_REFUND"})
                CAlert.showModal({data: {props: this.props}, type:this.state.modalType}, this.modalEvent)
                this.props.refresh()
                this.setState({modalType: "REFUND"})
            },
            () => {
                Platform.OS == "web" && this.props._toggleBottomNavigationView()
                CAlert.dismissModal(this.modalEvent)
                this.setState({modalType: "ERROR_REFUND"})
                CAlert.showModal({data: {actionFunc: this.refund.bind(this), props: this.props}, type:this.state.modalType}, this.modalEvent)
                this.setState({modalType: "REFUND"})
            }
        )
        
    }
    
    renderContentBS({ W, M, S }){

        return(

            <OrderDetails refresh={this.props.refresh} event={"historicOrder"} dismiss={this.props._toggleBottomNavigationView.bind(this)} refund={this.openRefundModal.bind(this)} {...{ W, M, S, item: this.props.item }}></OrderDetails>

        )
    }
    
    render(){
        
        const { W, M, S } = this.props
        return(

            <View style={[ t.flexWrap, t.flexRow,  t.wFull, t.bgWhite, t.rounded, {marginBottom: 9}, 
            extra.elevationShadow(1), e.elevation0, M && [{width: "48%", marginHorizontal: "1%"}, t.mB2], W && [{width: "48%", marginHorizontal: "1%"}, t.mB2] ]}>
    
                {
                    this.props.item.status == "ORDER_REFUNDED" && <View style={[ t.wFull, t.h1, t.bgRed, t.roundedT ]}></View>
                }

                <View style={[ t.flexWrap, t.flexRow, t.wFull, t.p2 ]}>

                    <View style={[ t.w2_12, t.h6, t.justifyCenter ]}>
                        <Avatar imageLink={this.props.item.avatar}/>
                    </View>
                    <View style={[ t.w7_12, t.h6, t.justifyCenter ]}>
                        <Text style={[ t.fontBd, t.textFirst, this.props.item.status == "ORDER_REFUNDED" && t.textRed, t.text5 ]}>{this.props.item.user.contactAlias}</Text>
                        <Text style={[ t.fontRg, t.textSecond ]}>{Moment(this.props.item.timestamp).isSame(Moment(), 'day') ? "OGGI "+Moment(this.props.item.timestamp).format('HH:mm') : Moment(this.props.item.timestamp).format('DD/MM/YYYY HH:mm')}</Text>
                    </View>
                    <View style={[ t.w3_12, t.h6, t.itemsEnd, t.justifyCenter ]}>
                        <Text style={[ t.fontBd, t.textFirst, t.text4  ]}>{this.props.item.amount.toFixed(2).replace(".", ",")}€</Text>
                    </View>

                    <View style={[ t.wFull, t.borderB1, t.borderDisGrey, {marginTop: 10}, t.mB1 ]}></View>

                    <View style={[ t.w2_12, t.h5, t.justifyCenter ]}>
                        <Image source={orderIcon[this.props.item.vasType]} style={[ t.w4, t.h4, t.mL1 ]}/>
                    </View>

                    <TouchableOpacity style={[ t.w8_12, t.h5, t.flexWrap, t.contentStart, t.justifyCenter ]} onPress={() => this.props._toggleBottomNavigationView(this.renderContentBS.bind(this, {W, M, S}))}>

                        {
                            this.props.item.status == "ORDER_REFUNDED" && <View style={[ t.justifyCenter, t.itemsCenter, t.mR2, t.textCenter, t.bgRed, t.w10, t.h4, {borderRadius: 6}]}>
                                <Text style={[ t.fontBd, t.textWhite, t.text5 ]}>Rimborso</Text>
                            </View>
                        }
                        <Text style={[ t.fontBd, t.textSecond, this.props.item.status == "ORDER_REFUNDED" && t.textRed, t.text5]}>{vas[this.props.item.vasType]}</Text>

                    </TouchableOpacity>

                    <TouchableOpacity style={[ t.w2_12, t.h5, t.itemsEnd, t.justifyCenter ]} onPress={() => this.props._toggleBottomNavigationView(this.renderContentBS.bind(this, {W, M, S}))}>
                        <Image source={require('../../assets/icons/infoDotsCard.png')} style={[ t.w4, t.h4 ]}/>
                    </TouchableOpacity>

                </View>
            </View>
        )
    }
}

const e = StyleSheet.create({
    
    // Fix z-index bottom sheet android 
    elevation0: { elevation: 0 },
    elevation50: { elevation: 50 },

});