import ServiceBase from "./ServiceBase";
import { environment } from "../environment/envinments";


class TransactionService extends ServiceBase {

    constructor() {
        super()
    }

async getMerchantP2PTransaction(page: number, el: number, params ) {
    var url
    if(params.startDate != undefined && params.endDate != undefined){
      url = `${environment.api.userUrl}/wallet-transactions/business?transactionTypes=PAYMENT_BUSINESS,REFUND&page=${page}&size=${el}&merchantDeviceId=${params.merchantDeviceId}&shopId=${params.shopId}&dateStart=${params.startDate}&dateEnd=${params.endDate}&sort=lastModifiedDate,desc`
    }else{
      url = `${environment.api.userUrl}/wallet-transactions/business?transactionTypes=PAYMENT_BUSINESS,REFUND&page=${page}&size=${el}&merchantDeviceId=${params.merchantDeviceId}&shopId=${params.shopId}&sort=lastModifiedDate,desc`
    }
    if(params.status){
      url = url + `&paymentMethods=${params.status}`
    }
    return await this.GET(url);
}

async getLinkToPayTransaction(page: number, el: number, params){
  var url
  if(params.startDate != undefined && params.endDate != undefined){
    url = `${environment.api.userUrl}/link-to-pay/search?page=${page}&size=${el}&dateFrom=${params.startDate}&dateTo=${params.endDate}&sort=createdDate,desc`
  }else{
    url = `${environment.api.userUrl}/link-to-pay/search?page=${page}&size=${el}&sort=createdDate,desc`
  }
  if(params.status != undefined){
    url = `${url}&statuses=${params.status}`
  }
  return await this.GET(url);
}

async getMovementAmounts(params){
      var url
      if(params.startDate != undefined && params.endDate != undefined){
        url = `${environment.api.userUrl}/wallet-transactions/business/amountnet?transactionTypes=PAYMENT_BUSINESS,REFUND&merchantDeviceId=${params.merchantDeviceId}&shopId=${params.shopId}&dateStart=${params.startDate}&dateEnd=${params.endDate}`
      }else{
        url = `${environment.api.userUrl}/wallet-transactions/business/amountnet?transactionTypes=PAYMENT_BUSINESS,REFUND&merchantDeviceId=${params.merchantDeviceId}&shopId=${params.shopId}`
      }
      if(params.status){
        url = url + `&externalEventType=${params.status}`
      }
      return await this.GET(url);
}

async refundTransaction(idBusiness: number, transactionIdforRefund: any) {
    return await this.POST(`${environment.api.ecommerceUrl}/businesses/${idBusiness}/orders/${transactionIdforRefund}/refund`, {});
  }
  
async businessRefund(req: any){
    return await this.POST(`${environment.api.userUrl}/businesses/me/pay/refund`, JSON.stringify(req));
  }

}

export default new TransactionService()