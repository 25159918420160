import React from "react";
import { 
    View, 
    Text, 
    Image, 
    StyleSheet, 
    TouchableOpacity,
    TouchableHighlight, 
    Platform, 
    ScrollView
} from "react-native";

import { t, extra } from "../../assets"
import Moment from 'moment';
import { vas } from '../utils/Utility';
import { CAlert } from './alert';
import homeController from "../controller/home.controller";
import { Avatar } from "./avatar";


export class OrderDetails extends React.Component<{ W, M, S: boolean, item: any, refresh: any, refund: any, dismiss: any, event?: string, ack?:boolean }> {
    
    constructor(props){
        super(props)
    }

    async acknowledgeOrder(){
        await homeController.acknowledgeOrder(this.props.item.businessId, this.props.item.orderId)
        this.props.refresh(true)
        this.props.dismiss()
    }

    renderProduct(prod, i){
        return (
            <View style={[ t.flexWrap, t.flexRow, t.wFull ]} key={i}> 

                <View style={[ t.w1_12, t.pY1, t.justifyCenter, t.itemsStart ]}>
                    <Text style={[ t.fontBd, t.textSecond, t.text6 ]}>{prod.quantity} x</Text>
                </View>
                <View style={[ t.w8_12, t.pY1, t.justifyCenter ]}>
                    <Text style={[ t.fontBd, t.textSecond, t.text6 ]}>{prod.name}</Text>
                </View>
                <View style={[ t.w3_12, t.pY1, t.itemsEnd, t.justifyCenter ]}>
                    <Text style={[ t.fontBd, t.textFirst, t.text5  ]}>{prod.price.toFixed(2).replace(".", ",")}€</Text>
                </View>
                
            </View>
        )
    }
    
    render(){

        const { W, M, S, item} = this.props
        var showDAInfo = item.vasType == "delivery" || item.vasType == "officedelivery"
        var subtotalInfo = item.vasType == "delivery" || item.vasType == "officedelivery"
        var showTable = item.vasType == "table" 
        var takeAway = item.vasType == "pick" 
        console.log(JSON.stringify(item))
        return(

            <View style={[ t.wFull, t.bgWhite, t.roundedT, extra.elevationShadow(1), e.elevation0, item.status == "ORDER_REFUNDED" && [{borderTopWidth: 5}, t.borderRed], 
            Platform.OS == "ios" && W && [t.w1_2, {marginHorizontal:"25%"}] ]}>

                <ScrollView showsVerticalScrollIndicator={false}>

                    <View style={[{padding: 8}, t.pB6]}>

                        <View style={[ t.flexRow ]}>
                            <View style={[ t.w10_12, t.h6, t.p2, t.mB1 ]}>
                                <Text style={[ t.fontBd, t.textFirst, t.text5 ]}>Dettaglio movimento</Text>
                            </View>
                            <TouchableOpacity style={[ t.w2_12, t.h6, t.justifyCenter, t.itemsEnd, t.pX2, t.mB1 ]} onPress={() => this.props.dismiss()}>
                                <Image source={require('../../assets/icons/iconCross.png')} style={{width: 14, height: 14}}/>
                            </TouchableOpacity>
                        </View>

                        <View style={[ t.flexWrap, t.flexRow, t.wFull, t.p2 ]}>

                            <View style={[ t.w2_12, t.h6, t.justifyCenter ]}>
                                <Avatar imageLink={this.props.item.avatar}/>
                            </View>
                            <View style={[ t.w7_12, t.h6, t.justifyCenter ]}>
                                <Text style={[ t.fontBd, t.textFirst, item.status == "ORDER_REFUNDED" && t.textRed, t.text5 ]}>{item.user ? item.user.contactAlias : ""}</Text>
                                <Text style={[ t.fontRg, t.textSecond ]}>{Moment(item.timestamp).format('DD/MM/YYYY HH:mm')}</Text>
                            </View>
                            <View style={[ t.w3_12, t.h6, t.itemsEnd, t.justifyCenter ]}>
                                <Text style={[ t.fontBd, t.textFirst, t.text4  ]}>{item.amount ? item.amount.toFixed(2).replace(".", ",")+"€" : ""}</Text>
                            </View>


                            <View style={[ t.wFull, t.borderB1, t.borderDisGrey, t.mT2 ]}></View>
                            <View style={[ t.w8_12, t.wFull ]}>
                                <Text style={[ t.fontBd, t.textSecond, t.text5, t.mB2, t.mT3, item.status == "ORDER_REFUNDED" && t.textRed ]}>{vas[item.vasType]}</Text>
                            </View>
                            
                            {
                                showDAInfo && <View style={[ t.mB3 ]}>
                                    { item.vasType != "officedelivery" && <Text style={[ t.fontRg, t.textSecond, t.text6 ]}>{item.deliveryAddress.givenName} {item.deliveryAddress.familyName}</Text>}
                                    { item.vasType == "officedelivery" && <Text style={[ t.fontRg, t.textSecond, t.text6 ]}>{item.companyNameOD}</Text>}
                                    <Text style={[ t.fontRg, t.textSecond, t.text6 ]}>Telefono:  {item.deliveryAddress.telephone ? item.deliveryAddress.telephone : item.user.phoneNumber}</Text>
                                    <Text style={[ t.fontRg, t.textSecond, t.text6 ]}>{item.deliveryAddress.streetAddress} {item.deliveryAddress.streetNumber},  {item.deliveryAddress.city} ({item.deliveryAddress.state}) CAP: {item.deliveryAddress.postalCode}</Text>
                                    { item.vasType != "officedelivery" && item.slot && <Text style={[ t.fontRg, t.textSecond, t.text6 ]}>Orario di consegna:  {Moment(item.slot.startDate).format('HH:mm')} - {Moment(item.slot.endDate).format('HH:mm')}</Text>}
                                    { item.vasType == "officedelivery" && item.slot && <Text style={[ t.fontRg, t.textSecond, t.text6 ]}>Orario di consegna:  {Moment(item.slot.startDate).format('HH:mm')}</Text>}
                                </View>
                            }
                            {
                                showTable && <View style={[ t.mB3 ]}>    
                                    <Text style={[ t.fontRg, t.textSecond, t.text6 ]}>Tavolo N°: {item.tableNumber}</Text>
                                </View>
                            }
                            {
                                takeAway && <View style={[ t.mB3 ]}>
                                    <Text style={[ t.fontRg, t.textSecond, t.text6 ]}>Telefono:  {item.user.phoneNumber}</Text>
                                    {item.slot && <Text style={[ t.fontRg, t.textSecond, t.text6 ]}>Orari di ritiro:  {Moment(item.slot.startDate).format('HH:mm')}</Text>}
                                </View>
                            }

                            <View style={[ t.wFull, t.borderB1, t.borderDisGrey, t.mB2 ]}></View>

                            { 
                                item.items && item.items.map((el, i) => {
                                    return this.renderProduct(el, i)
                                })
                            }

                            <View style={[ t.wFull, t.borderB1, t.borderDisGrey, t.mY2 ]}></View>

                            {
                                subtotalInfo && <View style={[ t.flexWrap, t.flexRow, t.wFull, t.mT1 ]}>   
                                    <View style={[ t.w9_12, t.h4 ]}>
                                        <Text style={[ t.fontBd, t.textSecond, t.text6 ]}>Subtotale:</Text>
                                    </View>
                                    <View style={[ t.w3_12, t.h4, t.itemsEnd ]}>
                                        <Text style={[ t.fontBd, t.textFirst, t.text5 ]}>{item.subtotal.toFixed(2).replace(".", ",")}€</Text>
                                    </View>
                                    <View style={[ t.w9_12, t.h4 ]}>
                                        <Text style={[ t.fontBd, t.textSecond, t.text6 ]}>Consegna:</Text>
                                    </View>
                                    <View style={[ t.w3_12, t.h4, t.itemsEnd ]}>
                                        <Text style={[ t.fontBd, t.textFirst, t.text5 ]}>{item.deliveryCost.toFixed(2).replace(".", ",")}€</Text>
                                    </View>
                                </View>
                            }

                            <TouchableHighlight style={[ t.bgMainBlue, t.roundedFull, t.wFull, t.mT5, {padding: 9} ]} underlayColor="#0f4a6d"
                            onPress={() => { this.props.ack ? this.acknowledgeOrder() : this.props.dismiss()}}>
                                <Text style={[ t.textCenter, t.textWhite, t.text5, t.fontBd ]}>Ok</Text>
                            </TouchableHighlight>

                            {
                                item.refundable && <TouchableHighlight onPress={() => {this.props.refund()}} underlayColor="#eff4f6"
                                style={[ t.roundedFull, t.border1, t.borderMainBlue, t.wFull, t.mT1, {padding: 9} ]}>
                                        <Text style={[ t.textCenter, t.textMainBlue, t.text5, t.fontBd ]}>{this.props.event == "pendingHome" ? "Rifiuta e rimborsa" : "Rimborsa"}</Text>
                                </TouchableHighlight>        
                            }
                                
                        </View>
                        { Platform.OS != "web" && <CAlert event={this.props.event}></CAlert>}

                    </View>

                </ScrollView>
                    
            </View>
        )
    }
}

const e = StyleSheet.create({
    
    // Fix z-index bottom sheet android 
    elevation0: { elevation: 0 },
    elevation50: { elevation: 50 },

});
