import React from "react";

import {
    SafeAreaView,
    StyleSheet,
    TextInput,
    Text,
    View,
    Image,
    Platform,
    TouchableOpacity,
    TouchableHighlight,
    KeyboardAvoidingView
} from "react-native";

import { t, extra } from "../../assets"
import { mq, handleError } from '../utils/Utility';

import { LargeButtonEmpty } from '../components/largeButtonEmpty';
import LoginController from '../controller/login.controller';
import { CBottomSheet } from "../components/bottomSheet";
import { CAlert } from "../components/alert";
import loginController from "../controller/login.controller";



export class Login extends React.Component<{ navigation: any }, { W, M, S: boolean, text: string, visible: boolean }> {

    modalEvent = "login"

	constructor(navigation: any){
        super(navigation)
        this.state = { W: false, M: false, S: false, text: "", visible: false };
    }

    static navigationOptions = {
        headerStyle: {
         visibility: "hidden",
         height: 0
        },
    };

    _toggleBottomNavigationView = () => {
        this.setState({ visible: !this.state.visible });
    };

    doLogin(logInProps){
        LoginController.executeLogin(logInProps).then(
            (error) => {
                handleError(error, this.logout.bind(this, this.props), this.props, this.modalEvent)
            }
        )
    }

    async logout(props){
        loginController.logout(props)
    }

    renderContentBS(){
        return(

            <View style={[ t.bgWhite, t.roundedTLg, t.p4, t.flexWrap ]}>

                <View style={[ t.flexRow, t.mB3 ]}>
                    <Text style={[ t.w10_12, t.h6, t.fontBd, t.text5, t.textMainBlue ]}>Per accedere all’App POS devi avere {"\n"}un profilo Tinaba Business attivo:</Text>
                    <TouchableOpacity style={[ t.w2_12, t.h6, t.itemsEnd ]} onPress={() => this._toggleBottomNavigationView()}>
                        <Image source={require('../../assets/icons/iconCross.png')} style={{width: 14, height: 14}}/>
                    </TouchableOpacity>
                </View>

                <View style={[ t.flexRow ]}>
                    <Text style={[ t.flex1, t.fontRg, t.text5, t.mB2 ]}>
                        Il codice di attivazione di una cassa Tinaba Pos è composto da <Text style={[ t.fontBd ]}>10 cifre alfanumeriche.</Text>
                    </Text>
                </View>
                <View style={[ t.flexRow ]}>
                    <Text style={[ t.flex1, t.fontRg, t.text5, t.mB2 ]}>
                        Se non sei il titolare dell’account Tinaba puoi contattare l’assistenza al numero <Text style={[ t.fontBd ]}>800.591.801</Text> altrimenti accedi al tuo profilo Business dal sito <Text style={[ t.fontBd ]}>www.tinaba.com</Text>
                    </Text>
                </View>

                <View style={[ t.flexRow ]}>
                    <Text style={[ t.flex1, t.fontRg, t.text5, t.mB3 ]}>
                        Utilizza le credenziali che hai registrato e naviga la sezione CASSE per recuperare tutte le informazioni necessarie.
                    </Text>
                </View>

            </View>
        )
    }

    render() {
        const { W, M, S } = this.state;

        const infoPOSCodeProps = {
            pTitle: "Dove trovo il codice?",
            page: "Home",
            navigation: this.props.navigation,
            onClick: this._toggleBottomNavigationView,
            value: ""
        }

        const logInProps = {
            pTitle: "Accedi",
            page: "Home",
            navigation: this.props.navigation,
            value: this.state.text
        }

        return (

            <KeyboardAvoidingView
            behavior={Platform.OS === "ios" ? "padding" : undefined}
            keyboardVerticalOffset={Platform.select({ios: 20, android: -255})}>

                <SafeAreaView style={[ t.bgLightBlue, e.heightCustom, t.itemsCenter ]} onLayout={ mq(this) }>

                    <View style={[t.h8_12, t.wFull, t.itemsCenter, t.justifyEnd ]}>

                        <Image source={require('../../assets/img/tinabaLogoLogin.png')} resizeMode="contain" style={{height:48, width: 160}}/>

                        <View style={[ t.wFull, t.itemsCenter ]}>

                            <Text style={[ t.textCenter, t.text4, t.fontBd, t.textFirst, t.mY8, M && t.text3 ]}>Benvenuto in Tinaba App POS</Text>

                            <View style={[ t.w11_12, t.h15, t.bgWhite, t.rounded, t.flex, t.itemsCenter, t.justifyCenter, extra.elevationShadow(5, "bgWhite"),
                            M && [t.w8_12, t.mB6], W && [{width: 520}, t.mB7]]}>

                                <View style={[ t.w11_12, t.p2 ]}>
                                    <Text style={[ t.mB2, t.textSecond, t.fontRg, t.text5 ]}>Codice Attivazione Cassa</Text>
                                    <TextInput style={[ t.h5, t.border1, t.borderThird, t.roundedSm, t.mB2, t.p1 ]} onChangeText={(text) => this.setState({text})}/>
                                    <LargeButtonEmpty style={[ t.h6 ]} { ...infoPOSCodeProps }></LargeButtonEmpty>
                                </View>

                            </View>
                        </View>

                    </View>
                    <View style={[t.h4_12, t.wFull, t.itemsCenter, t.justifyCenter ]}>

                        <TouchableHighlight style={[ t.bgMainBlue, t.mY1, t.p2, t.wFull, t.roundedFull, t.w7_12,
                        M && [t.w6_12, t.mB4], W && [{width: 350}, t.mB4]]}  onPress={() => {this.doLogin(logInProps)}}>
                            <Text style={[ t.textCenter, t.textWhite, t.fontBd, t.text5 ]}>Accedi</Text>
                        </TouchableHighlight>

                    </View>

                    <CAlert event={"login"}></CAlert>
                    <CBottomSheet { ...{visible: this.state.visible, _toggleBottomNavigationView: this._toggleBottomNavigationView, renderContent: this.renderContentBS} }></CBottomSheet>

                </SafeAreaView>

            </KeyboardAvoidingView>

        );
	};
}



const e = StyleSheet.create({

    // Fix height 100% on mobile + web
    heightCustom: { height: (Platform.OS === 'web') ? "100vh" : "100%" },

});
