export const byteArrayToUrl = function (arr: any) {
    let blob = new Blob(arr, {type: 'image/png'});
    let fileURL = URL.createObjectURL(blob);
    return fileURL;
} 

export const readAllChunks = function(readableStream) {
    const reader = readableStream.getReader();
    const chunks = [] as any;
   
    function pump() {
      return reader.read().then(({ value, done }) => {
        if (done) {
          return chunks;
        }
        chunks.push(value);
        return pump();
      });
    }
   
    return pump();
}

export const asyncForEach = async function(array, callback) {
    for (let index = 0; index < array.length; index++) {
      await callback(array[index], index, array);
    }
}