import _defineProperty from "/opt/build/repo/node_modules/babel-preset-react-app/node_modules/@babel/runtime/helpers/esm/defineProperty";

var _StyleSheet$create;

import { StyleSheet } from 'react-native';
var t = StyleSheet.create((_StyleSheet$create = {
  _m1: {
    margin: -5
  },
  _m10: {
    margin: -77
  },
  _m11: {
    margin: -85
  },
  _m12: {
    margin: -110
  },
  _m13: {
    margin: -135
  },
  _m14: {
    margin: -160
  },
  _m15: {
    margin: -185
  },
  _m16: {
    margin: -210
  },
  _m17: {
    margin: -235
  },
  _m18: {
    margin: -260
  },
  _m19: {
    margin: -285
  },
  _m2: {
    margin: -13
  },
  _m20: {
    margin: -310
  },
  _m21: {
    margin: -335
  },
  _m22: {
    margin: -360
  },
  _m23: {
    margin: -385
  },
  _m24: {
    margin: -410
  },
  _m25: {
    margin: -435
  },
  _m3: {
    margin: -21
  },
  _m4: {
    margin: -29
  },
  _m5: {
    margin: -37
  },
  _m6: {
    margin: -45
  },
  _m7: {
    margin: -53
  },
  _m8: {
    margin: -61
  },
  _m9: {
    margin: -69
  },
  _mB1: {
    marginBottom: -5
  },
  _mB10: {
    marginBottom: -77
  },
  _mB11: {
    marginBottom: -85
  },
  _mB12: {
    marginBottom: -110
  },
  _mB13: {
    marginBottom: -135
  },
  _mB14: {
    marginBottom: -160
  },
  _mB15: {
    marginBottom: -185
  },
  _mB16: {
    marginBottom: -210
  },
  _mB17: {
    marginBottom: -235
  },
  _mB18: {
    marginBottom: -260
  },
  _mB19: {
    marginBottom: -285
  },
  _mB2: {
    marginBottom: -13
  },
  _mB20: {
    marginBottom: -310
  },
  _mB21: {
    marginBottom: -335
  },
  _mB22: {
    marginBottom: -360
  },
  _mB23: {
    marginBottom: -385
  },
  _mB24: {
    marginBottom: -410
  },
  _mB25: {
    marginBottom: -435
  },
  _mB3: {
    marginBottom: -21
  },
  _mB4: {
    marginBottom: -29
  },
  _mB5: {
    marginBottom: -37
  },
  _mB6: {
    marginBottom: -45
  },
  _mB7: {
    marginBottom: -53
  },
  _mB8: {
    marginBottom: -61
  },
  _mB9: {
    marginBottom: -69
  },
  _mL1: {
    marginLeft: -5
  },
  _mL10: {
    marginLeft: -77
  },
  _mL11: {
    marginLeft: -85
  },
  _mL12: {
    marginLeft: -110
  },
  _mL13: {
    marginLeft: -135
  },
  _mL14: {
    marginLeft: -160
  },
  _mL15: {
    marginLeft: -185
  },
  _mL16: {
    marginLeft: -210
  },
  _mL17: {
    marginLeft: -235
  },
  _mL18: {
    marginLeft: -260
  },
  _mL19: {
    marginLeft: -285
  },
  _mL2: {
    marginLeft: -13
  },
  _mL20: {
    marginLeft: -310
  },
  _mL21: {
    marginLeft: -335
  },
  _mL22: {
    marginLeft: -360
  },
  _mL23: {
    marginLeft: -385
  },
  _mL24: {
    marginLeft: -410
  },
  _mL25: {
    marginLeft: -435
  },
  _mL3: {
    marginLeft: -21
  },
  _mL4: {
    marginLeft: -29
  },
  _mL5: {
    marginLeft: -37
  },
  _mL6: {
    marginLeft: -45
  },
  _mL7: {
    marginLeft: -53
  },
  _mL8: {
    marginLeft: -61
  },
  _mL9: {
    marginLeft: -69
  },
  _mR1: {
    marginRight: -5
  },
  _mR10: {
    marginRight: -77
  },
  _mR11: {
    marginRight: -85
  },
  _mR12: {
    marginRight: -110
  },
  _mR13: {
    marginRight: -135
  },
  _mR14: {
    marginRight: -160
  },
  _mR15: {
    marginRight: -185
  },
  _mR16: {
    marginRight: -210
  },
  _mR17: {
    marginRight: -235
  },
  _mR18: {
    marginRight: -260
  },
  _mR19: {
    marginRight: -285
  },
  _mR2: {
    marginRight: -13
  },
  _mR20: {
    marginRight: -310
  },
  _mR21: {
    marginRight: -335
  },
  _mR22: {
    marginRight: -360
  },
  _mR23: {
    marginRight: -385
  },
  _mR24: {
    marginRight: -410
  },
  _mR25: {
    marginRight: -435
  },
  _mR3: {
    marginRight: -21
  },
  _mR4: {
    marginRight: -29
  },
  _mR5: {
    marginRight: -37
  },
  _mR6: {
    marginRight: -45
  },
  _mR7: {
    marginRight: -53
  },
  _mR8: {
    marginRight: -61
  },
  _mR9: {
    marginRight: -69
  },
  _mT1: {
    marginTop: -5
  },
  _mT10: {
    marginTop: -77
  },
  _mT11: {
    marginTop: -85
  },
  _mT12: {
    marginTop: -110
  },
  _mT13: {
    marginTop: -135
  },
  _mT14: {
    marginTop: -160
  },
  _mT15: {
    marginTop: -185
  },
  _mT16: {
    marginTop: -210
  },
  _mT17: {
    marginTop: -235
  },
  _mT18: {
    marginTop: -260
  },
  _mT19: {
    marginTop: -285
  },
  _mT2: {
    marginTop: -13
  },
  _mT20: {
    marginTop: -310
  },
  _mT21: {
    marginTop: -335
  },
  _mT22: {
    marginTop: -360
  },
  _mT23: {
    marginTop: -385
  },
  _mT24: {
    marginTop: -410
  },
  _mT25: {
    marginTop: -435
  },
  _mT3: {
    marginTop: -21
  },
  _mT4: {
    marginTop: -29
  },
  _mT5: {
    marginTop: -37
  },
  _mT6: {
    marginTop: -45
  },
  _mT7: {
    marginTop: -53
  },
  _mT8: {
    marginTop: -61
  },
  _mT9: {
    marginTop: -69
  },
  _mX1: {
    marginHorizontal: -5
  },
  _mX10: {
    marginHorizontal: -77
  },
  _mX11: {
    marginHorizontal: -85
  },
  _mX12: {
    marginHorizontal: -110
  },
  _mX13: {
    marginHorizontal: -135
  },
  _mX14: {
    marginHorizontal: -160
  },
  _mX15: {
    marginHorizontal: -185
  },
  _mX16: {
    marginHorizontal: -210
  },
  _mX17: {
    marginHorizontal: -235
  },
  _mX18: {
    marginHorizontal: -260
  },
  _mX19: {
    marginHorizontal: -285
  },
  _mX2: {
    marginHorizontal: -13
  },
  _mX20: {
    marginHorizontal: -310
  },
  _mX21: {
    marginHorizontal: -335
  },
  _mX22: {
    marginHorizontal: -360
  },
  _mX23: {
    marginHorizontal: -385
  },
  _mX24: {
    marginHorizontal: -410
  },
  _mX25: {
    marginHorizontal: -435
  },
  _mX3: {
    marginHorizontal: -21
  },
  _mX4: {
    marginHorizontal: -29
  },
  _mX5: {
    marginHorizontal: -37
  },
  _mX6: {
    marginHorizontal: -45
  },
  _mX7: {
    marginHorizontal: -53
  },
  _mX8: {
    marginHorizontal: -61
  },
  _mX9: {
    marginHorizontal: -69
  },
  _mY1: {
    marginVertical: -5
  },
  _mY10: {
    marginVertical: -77
  },
  _mY11: {
    marginVertical: -85
  },
  _mY12: {
    marginVertical: -110
  },
  _mY13: {
    marginVertical: -135
  },
  _mY14: {
    marginVertical: -160
  },
  _mY15: {
    marginVertical: -185
  },
  _mY16: {
    marginVertical: -210
  },
  _mY17: {
    marginVertical: -235
  },
  _mY18: {
    marginVertical: -260
  },
  _mY19: {
    marginVertical: -285
  },
  _mY2: {
    marginVertical: -13
  },
  _mY20: {
    marginVertical: -310
  },
  _mY21: {
    marginVertical: -335
  },
  _mY22: {
    marginVertical: -360
  },
  _mY23: {
    marginVertical: -385
  },
  _mY24: {
    marginVertical: -410
  },
  _mY25: {
    marginVertical: -435
  },
  _mY3: {
    marginVertical: -21
  },
  _mY4: {
    marginVertical: -29
  },
  _mY5: {
    marginVertical: -37
  },
  _mY6: {
    marginVertical: -45
  },
  _mY7: {
    marginVertical: -53
  },
  _mY8: {
    marginVertical: -61
  },
  _mY9: {
    marginVertical: -69
  },
  absolute: {
    position: 'absolute'
  },
  alignAuto: {
    textAlignVertical: 'auto'
  },
  alignBottom: {
    textAlignVertical: 'bottom'
  },
  alignCenter: {
    textAlignVertical: 'center'
  },
  alignTop: {
    textAlignVertical: 'top'
  },
  backfaceHidden: {
    backfaceVisibility: 'hidden'
  },
  backfaceVisible: {
    backfaceVisibility: 'visible'
  },
  bgBlack: {
    backgroundColor: '#000000'
  },
  bgClear: {
    backgroundColor: '#00000000'
  },
  bgDarkGrey: {
    backgroundColor: '#9B9B9B'
  },
  bgDisGrey: {
    backgroundColor: '#EFEFEF'
  },
  bgFirst: {
    backgroundColor: '#4A5155'
  },
  bgGreen: {
    backgroundColor: '#7ED321'
  },
  bgLightBlue: {
    backgroundColor: '#F1F5F8'
  },
  bgLightOrange: {
    backgroundColor: '#FFEDE7'
  },
  bgMainBlue: {
    backgroundColor: '#236A95'
  },
  bgMidBlue: {
    backgroundColor: '#4380A4'
  }
}, _defineProperty(_StyleSheet$create, "bgLightBlue", {
  backgroundColor: '#E8EEF2'
}), _defineProperty(_StyleSheet$create, "bgOrange", {
  backgroundColor: '#F76122'
}), _defineProperty(_StyleSheet$create, "bgRed", {
  backgroundColor: '#D0021B'
}), _defineProperty(_StyleSheet$create, "bgSecond", {
  backgroundColor: '#707F89'
}), _defineProperty(_StyleSheet$create, "bgThird", {
  backgroundColor: '#CBD1D5'
}), _defineProperty(_StyleSheet$create, "bgWarmGrey", {
  backgroundColor: '#FAF9F8'
}), _defineProperty(_StyleSheet$create, "bgWhite", {
  backgroundColor: '#FFFFFF'
}), _defineProperty(_StyleSheet$create, "border", {
  borderWidth: 2
}), _defineProperty(_StyleSheet$create, "border0", {
  borderWidth: 0
}), _defineProperty(_StyleSheet$create, "border1", {
  borderWidth: 1
}), _defineProperty(_StyleSheet$create, "border2", {
  borderWidth: 1.5
}), _defineProperty(_StyleSheet$create, "border3", {
  borderWidth: 2
}), _defineProperty(_StyleSheet$create, "border4", {
  borderWidth: 2.5
}), _defineProperty(_StyleSheet$create, "border5", {
  borderWidth: 3
}), _defineProperty(_StyleSheet$create, "border6", {
  borderWidth: 3.5
}), _defineProperty(_StyleSheet$create, "borderB", {
  borderBottomWidth: 2
}), _defineProperty(_StyleSheet$create, "borderB0", {
  borderBottomWidth: 0
}), _defineProperty(_StyleSheet$create, "borderB1", {
  borderBottomWidth: 1
}), _defineProperty(_StyleSheet$create, "borderB2", {
  borderBottomWidth: 1.5
}), _defineProperty(_StyleSheet$create, "borderB3", {
  borderBottomWidth: 2
}), _defineProperty(_StyleSheet$create, "borderB4", {
  borderBottomWidth: 2.5
}), _defineProperty(_StyleSheet$create, "borderB5", {
  borderBottomWidth: 3
}), _defineProperty(_StyleSheet$create, "borderB6", {
  borderBottomWidth: 3.5
}), _defineProperty(_StyleSheet$create, "borderBlack", {
  borderColor: '#000000'
}), _defineProperty(_StyleSheet$create, "borderClear", {
  borderColor: '#00000000'
}), _defineProperty(_StyleSheet$create, "borderDarkGrey", {
  borderColor: '#9B9B9B'
}), _defineProperty(_StyleSheet$create, "borderDashed", {
  borderStyle: 'dashed'
}), _defineProperty(_StyleSheet$create, "borderDir", {
  borderWidth: 2
}), _defineProperty(_StyleSheet$create, "borderDir0", {
  borderWidth: 0
}), _defineProperty(_StyleSheet$create, "borderDir1", {
  borderWidth: 1
}), _defineProperty(_StyleSheet$create, "borderDir2", {
  borderWidth: 1.5
}), _defineProperty(_StyleSheet$create, "borderDir3", {
  borderWidth: 2
}), _defineProperty(_StyleSheet$create, "borderDir4", {
  borderWidth: 2.5
}), _defineProperty(_StyleSheet$create, "borderDir5", {
  borderWidth: 3
}), _defineProperty(_StyleSheet$create, "borderDir6", {
  borderWidth: 3.5
}), _defineProperty(_StyleSheet$create, "borderDirE", {
  borderEndWidth: 2
}), _defineProperty(_StyleSheet$create, "borderDirE0", {
  borderEndWidth: 0
}), _defineProperty(_StyleSheet$create, "borderDirE1", {
  borderEndWidth: 1
}), _defineProperty(_StyleSheet$create, "borderDirE2", {
  borderEndWidth: 1.5
}), _defineProperty(_StyleSheet$create, "borderDirE3", {
  borderEndWidth: 2
}), _defineProperty(_StyleSheet$create, "borderDirE4", {
  borderEndWidth: 2.5
}), _defineProperty(_StyleSheet$create, "borderDirE5", {
  borderEndWidth: 3
}), _defineProperty(_StyleSheet$create, "borderDirE6", {
  borderEndWidth: 3.5
}), _defineProperty(_StyleSheet$create, "borderDirS", {
  borderStartWidth: 2
}), _defineProperty(_StyleSheet$create, "borderDirS0", {
  borderStartWidth: 0
}), _defineProperty(_StyleSheet$create, "borderDirS1", {
  borderStartWidth: 1
}), _defineProperty(_StyleSheet$create, "borderDirS2", {
  borderStartWidth: 1.5
}), _defineProperty(_StyleSheet$create, "borderDirS3", {
  borderStartWidth: 2
}), _defineProperty(_StyleSheet$create, "borderDirS4", {
  borderStartWidth: 2.5
}), _defineProperty(_StyleSheet$create, "borderDirS5", {
  borderStartWidth: 3
}), _defineProperty(_StyleSheet$create, "borderDirS6", {
  borderStartWidth: 3.5
}), _defineProperty(_StyleSheet$create, "borderDirX", {
  borderEndWidth: 2,
  borderStartWidth: 2
}), _defineProperty(_StyleSheet$create, "borderDirX0", {
  borderEndWidth: 0,
  borderStartWidth: 0
}), _defineProperty(_StyleSheet$create, "borderDirX1", {
  borderEndWidth: 1,
  borderStartWidth: 1
}), _defineProperty(_StyleSheet$create, "borderDirX2", {
  borderEndWidth: 1.5,
  borderStartWidth: 1.5
}), _defineProperty(_StyleSheet$create, "borderDirX3", {
  borderEndWidth: 2,
  borderStartWidth: 2
}), _defineProperty(_StyleSheet$create, "borderDirX4", {
  borderEndWidth: 2.5,
  borderStartWidth: 2.5
}), _defineProperty(_StyleSheet$create, "borderDirX5", {
  borderEndWidth: 3,
  borderStartWidth: 3
}), _defineProperty(_StyleSheet$create, "borderDirX6", {
  borderEndWidth: 3.5,
  borderStartWidth: 3.5
}), _defineProperty(_StyleSheet$create, "borderDisGrey", {
  borderColor: '#EFEFEF'
}), _defineProperty(_StyleSheet$create, "borderDotted", {
  borderStyle: 'dotted'
}), _defineProperty(_StyleSheet$create, "borderFirst", {
  borderColor: '#4A5155'
}), _defineProperty(_StyleSheet$create, "borderGreen", {
  borderColor: '#7ED321'
}), _defineProperty(_StyleSheet$create, "borderL", {
  borderLeftWidth: 2
}), _defineProperty(_StyleSheet$create, "borderL0", {
  borderLeftWidth: 0
}), _defineProperty(_StyleSheet$create, "borderL1", {
  borderLeftWidth: 1
}), _defineProperty(_StyleSheet$create, "borderL2", {
  borderLeftWidth: 1.5
}), _defineProperty(_StyleSheet$create, "borderL3", {
  borderLeftWidth: 2
}), _defineProperty(_StyleSheet$create, "borderL4", {
  borderLeftWidth: 2.5
}), _defineProperty(_StyleSheet$create, "borderL5", {
  borderLeftWidth: 3
}), _defineProperty(_StyleSheet$create, "borderL6", {
  borderLeftWidth: 3.5
}), _defineProperty(_StyleSheet$create, "borderLightBlue", {
  borderColor: '#F1F5F8'
}), _defineProperty(_StyleSheet$create, "borderLightOrange", {
  borderColor: '#FFEDE7'
}), _defineProperty(_StyleSheet$create, "borderMainBlue", {
  borderColor: '#236A95'
}), _defineProperty(_StyleSheet$create, "borderMidBlue", {
  borderColor: '#4380A4'
}), _defineProperty(_StyleSheet$create, "borderOrange", {
  borderColor: '#F76122'
}), _defineProperty(_StyleSheet$create, "borderR", {
  borderRightWidth: 2
}), _defineProperty(_StyleSheet$create, "borderR0", {
  borderRightWidth: 0
}), _defineProperty(_StyleSheet$create, "borderR1", {
  borderRightWidth: 1
}), _defineProperty(_StyleSheet$create, "borderR2", {
  borderRightWidth: 1.5
}), _defineProperty(_StyleSheet$create, "borderR3", {
  borderRightWidth: 2
}), _defineProperty(_StyleSheet$create, "borderR4", {
  borderRightWidth: 2.5
}), _defineProperty(_StyleSheet$create, "borderR5", {
  borderRightWidth: 3
}), _defineProperty(_StyleSheet$create, "borderR6", {
  borderRightWidth: 3.5
}), _defineProperty(_StyleSheet$create, "borderRed", {
  borderColor: '#D0021B'
}), _defineProperty(_StyleSheet$create, "borderSecond", {
  borderColor: '#707F89'
}), _defineProperty(_StyleSheet$create, "borderSolid", {
  borderStyle: 'solid'
}), _defineProperty(_StyleSheet$create, "borderT", {
  borderTopWidth: 2
}), _defineProperty(_StyleSheet$create, "borderT0", {
  borderTopWidth: 0
}), _defineProperty(_StyleSheet$create, "borderT1", {
  borderTopWidth: 1
}), _defineProperty(_StyleSheet$create, "borderT2", {
  borderTopWidth: 1.5
}), _defineProperty(_StyleSheet$create, "borderT3", {
  borderTopWidth: 2
}), _defineProperty(_StyleSheet$create, "borderT4", {
  borderTopWidth: 2.5
}), _defineProperty(_StyleSheet$create, "borderT5", {
  borderTopWidth: 3
}), _defineProperty(_StyleSheet$create, "borderT6", {
  borderTopWidth: 3.5
}), _defineProperty(_StyleSheet$create, "borderThird", {
  borderColor: '#CBD1D5'
}), _defineProperty(_StyleSheet$create, "borderWarmGrey", {
  borderColor: '#FAF9F8'
}), _defineProperty(_StyleSheet$create, "borderWhite", {
  borderColor: '#FFFFFF'
}), _defineProperty(_StyleSheet$create, "borderX", {
  borderLeftWidth: 2,
  borderRightWidth: 2
}), _defineProperty(_StyleSheet$create, "borderX0", {
  borderLeftWidth: 0,
  borderRightWidth: 0
}), _defineProperty(_StyleSheet$create, "borderX1", {
  borderLeftWidth: 1,
  borderRightWidth: 1
}), _defineProperty(_StyleSheet$create, "borderX2", {
  borderLeftWidth: 1.5,
  borderRightWidth: 1.5
}), _defineProperty(_StyleSheet$create, "borderX3", {
  borderLeftWidth: 2,
  borderRightWidth: 2
}), _defineProperty(_StyleSheet$create, "borderX4", {
  borderLeftWidth: 2.5,
  borderRightWidth: 2.5
}), _defineProperty(_StyleSheet$create, "borderX5", {
  borderLeftWidth: 3,
  borderRightWidth: 3
}), _defineProperty(_StyleSheet$create, "borderX6", {
  borderLeftWidth: 3.5,
  borderRightWidth: 3.5
}), _defineProperty(_StyleSheet$create, "borderY", {
  borderBottomWidth: 2,
  borderTopWidth: 2
}), _defineProperty(_StyleSheet$create, "borderY0", {
  borderBottomWidth: 0,
  borderTopWidth: 0
}), _defineProperty(_StyleSheet$create, "borderY1", {
  borderBottomWidth: 1,
  borderTopWidth: 1
}), _defineProperty(_StyleSheet$create, "borderY2", {
  borderBottomWidth: 1.5,
  borderTopWidth: 1.5
}), _defineProperty(_StyleSheet$create, "borderY3", {
  borderBottomWidth: 2,
  borderTopWidth: 2
}), _defineProperty(_StyleSheet$create, "borderY4", {
  borderBottomWidth: 2.5,
  borderTopWidth: 2.5
}), _defineProperty(_StyleSheet$create, "borderY5", {
  borderBottomWidth: 3,
  borderTopWidth: 3
}), _defineProperty(_StyleSheet$create, "borderY6", {
  borderBottomWidth: 3.5,
  borderTopWidth: 3.5
}), _defineProperty(_StyleSheet$create, "bottom0", {
  bottom: 0
}), _defineProperty(_StyleSheet$create, "capitalize", {
  textTransform: 'capitalize'
}), _defineProperty(_StyleSheet$create, "contentAround", {
  alignContent: 'space-around'
}), _defineProperty(_StyleSheet$create, "contentBetween", {
  alignContent: 'space-between'
}), _defineProperty(_StyleSheet$create, "contentCenter", {
  alignContent: 'center'
}), _defineProperty(_StyleSheet$create, "contentEnd", {
  alignContent: 'flex-end'
}), _defineProperty(_StyleSheet$create, "contentStart", {
  alignContent: 'flex-start'
}), _defineProperty(_StyleSheet$create, "contentStretch", {
  alignContent: 'stretch'
}), _defineProperty(_StyleSheet$create, "directionInherit", {
  direction: 'inherit'
}), _defineProperty(_StyleSheet$create, "directionLtr", {
  direction: 'ltr'
}), _defineProperty(_StyleSheet$create, "directionRtl", {
  direction: 'rtl'
}), _defineProperty(_StyleSheet$create, "end0", {
  end: 0
}), _defineProperty(_StyleSheet$create, "flex", {
  display: 'flex'
}), _defineProperty(_StyleSheet$create, "flex1", {
  flex: 1
}), _defineProperty(_StyleSheet$create, "flex10_12", {
  flex: 0.83333333
}), _defineProperty(_StyleSheet$create, "flex11_12", {
  flex: 0.91666667
}), _defineProperty(_StyleSheet$create, "flex1_12", {
  flex: 0.8333333
}), _defineProperty(_StyleSheet$create, "flex1_2", {
  flex: 0.5
}), _defineProperty(_StyleSheet$create, "flex1_3", {
  flex: 0.33333333
}), _defineProperty(_StyleSheet$create, "flex1_4", {
  flex: 0.25
}), _defineProperty(_StyleSheet$create, "flex1_5", {
  flex: 0.2
}), _defineProperty(_StyleSheet$create, "flex1_6", {
  flex: 0.16666667
}), _defineProperty(_StyleSheet$create, "flex2_12", {
  flex: 0.16666667
}), _defineProperty(_StyleSheet$create, "flex2_3", {
  flex: 0.66666667
}), _defineProperty(_StyleSheet$create, "flex2_4", {
  flex: 0.5
}), _defineProperty(_StyleSheet$create, "flex2_5", {
  flex: 0.4
}), _defineProperty(_StyleSheet$create, "flex2_6", {
  flex: 0.33333333
}), _defineProperty(_StyleSheet$create, "flex3_12", {
  flex: 0.25
}), _defineProperty(_StyleSheet$create, "flex3_4", {
  flex: 0.75
}), _defineProperty(_StyleSheet$create, "flex3_5", {
  flex: 0.6
}), _defineProperty(_StyleSheet$create, "flex3_6", {
  flex: 0.5
}), _defineProperty(_StyleSheet$create, "flex4_12", {
  flex: 0.33333333
}), _defineProperty(_StyleSheet$create, "flex4_5", {
  flex: 0.8
}), _defineProperty(_StyleSheet$create, "flex4_6", {
  flex: 0.66666667
}), _defineProperty(_StyleSheet$create, "flex5_12", {
  flex: 0.41666667
}), _defineProperty(_StyleSheet$create, "flex5_6", {
  flex: 0.83333333
}), _defineProperty(_StyleSheet$create, "flex6_12", {
  flex: 0.5
}), _defineProperty(_StyleSheet$create, "flex7_12", {
  flex: 0.58333333
}), _defineProperty(_StyleSheet$create, "flex8_12", {
  flex: 0.66666667
}), _defineProperty(_StyleSheet$create, "flex9_12", {
  flex: 0.75
}), _defineProperty(_StyleSheet$create, "flexCol", {
  flexDirection: 'column'
}), _defineProperty(_StyleSheet$create, "flexColReverse", {
  flexDirection: 'column-reverse'
}), _defineProperty(_StyleSheet$create, "flexGrow", {
  flexGrow: 1
}), _defineProperty(_StyleSheet$create, "flexGrow0", {
  flexGrow: 0
}), _defineProperty(_StyleSheet$create, "flexNoWrap", {
  flexWrap: 'nowrap'
}), _defineProperty(_StyleSheet$create, "flexRow", {
  flexDirection: 'row'
}), _defineProperty(_StyleSheet$create, "flexRowReverse", {
  flexDirection: 'row-reverse'
}), _defineProperty(_StyleSheet$create, "flexShrink", {
  flexShrink: 1
}), _defineProperty(_StyleSheet$create, "flexShrink0", {
  flexShrink: 0
}), _defineProperty(_StyleSheet$create, "flexWrap", {
  flexWrap: 'wrap'
}), _defineProperty(_StyleSheet$create, "flexWrapReverse", {
  flexWrap: 'wrap-reverse'
}), _defineProperty(_StyleSheet$create, "fontBd", {
  fontFamily: 'BuloRounded-Bold'
}), _defineProperty(_StyleSheet$create, "fontBlack", {
  fontWeight: '900'
}), _defineProperty(_StyleSheet$create, "fontBold", {
  fontWeight: 'bold'
}), _defineProperty(_StyleSheet$create, "fontExtrabold", {
  fontWeight: '800'
}), _defineProperty(_StyleSheet$create, "fontHairline", {
  fontWeight: '100'
}), _defineProperty(_StyleSheet$create, "fontLight", {
  fontWeight: '300'
}), _defineProperty(_StyleSheet$create, "fontMedium", {
  fontWeight: '500'
}), _defineProperty(_StyleSheet$create, "fontNormal", {
  fontWeight: 'normal'
}), _defineProperty(_StyleSheet$create, "fontRg", {
  fontFamily: 'BuloRounded-Regular'
}), _defineProperty(_StyleSheet$create, "fontSemibold", {
  fontWeight: '600'
}), _defineProperty(_StyleSheet$create, "fontThin", {
  fontWeight: '200'
}), _defineProperty(_StyleSheet$create, "h1", {
  height: 5
}), _defineProperty(_StyleSheet$create, "h10", {
  height: 77
}), _defineProperty(_StyleSheet$create, "h11", {
  height: 85
}), _defineProperty(_StyleSheet$create, "h12", {
  height: 110
}), _defineProperty(_StyleSheet$create, "h13", {
  height: 135
}), _defineProperty(_StyleSheet$create, "h14", {
  height: 160
}), _defineProperty(_StyleSheet$create, "h15", {
  height: 185
}), _defineProperty(_StyleSheet$create, "h16", {
  height: 210
}), _defineProperty(_StyleSheet$create, "h17", {
  height: 235
}), _defineProperty(_StyleSheet$create, "h18", {
  height: 260
}), _defineProperty(_StyleSheet$create, "h19", {
  height: 285
}), _defineProperty(_StyleSheet$create, "h2", {
  height: 13
}), _defineProperty(_StyleSheet$create, "h20", {
  height: 310
}), _defineProperty(_StyleSheet$create, "h21", {
  height: 335
}), _defineProperty(_StyleSheet$create, "h22", {
  height: 360
}), _defineProperty(_StyleSheet$create, "h23", {
  height: 385
}), _defineProperty(_StyleSheet$create, "h24", {
  height: 410
}), _defineProperty(_StyleSheet$create, "h25", {
  height: 435
}), _defineProperty(_StyleSheet$create, "h3", {
  height: 21
}), _defineProperty(_StyleSheet$create, "h4", {
  height: 29
}), _defineProperty(_StyleSheet$create, "h5", {
  height: 37
}), _defineProperty(_StyleSheet$create, "h6", {
  height: 45
}), _defineProperty(_StyleSheet$create, "h7", {
  height: 53
}), _defineProperty(_StyleSheet$create, "h8", {
  height: 61
}), _defineProperty(_StyleSheet$create, "h9", {
  height: 69
}), _defineProperty(_StyleSheet$create, "hAuto", {
  height: 'auto'
}), _defineProperty(_StyleSheet$create, "hFull", {
  height: '100%'
}), _defineProperty(_StyleSheet$create, "hScreen", {
  height: '100vh'
}), _defineProperty(_StyleSheet$create, "hidden", {
  display: 'none'
}), _defineProperty(_StyleSheet$create, "inset0", {
  bottom: 0,
  left: 0,
  right: 0,
  top: 0
}), _defineProperty(_StyleSheet$create, "insetDir0", {
  bottom: 0,
  end: 0,
  start: 0,
  top: 0
}), _defineProperty(_StyleSheet$create, "insetDirX0", {
  end: 0,
  start: 0
}), _defineProperty(_StyleSheet$create, "insetX0", {
  left: 0,
  right: 0
}), _defineProperty(_StyleSheet$create, "insetY0", {
  bottom: 0,
  top: 0
}), _defineProperty(_StyleSheet$create, "italic", {
  fontStyle: 'italic'
}), _defineProperty(_StyleSheet$create, "itemsBaseline", {
  alignItems: 'baseline'
}), _defineProperty(_StyleSheet$create, "itemsCenter", {
  alignItems: 'center'
}), _defineProperty(_StyleSheet$create, "itemsEnd", {
  alignItems: 'flex-end'
}), _defineProperty(_StyleSheet$create, "itemsStart", {
  alignItems: 'flex-start'
}), _defineProperty(_StyleSheet$create, "itemsStretch", {
  alignItems: 'stretch'
}), _defineProperty(_StyleSheet$create, "justifyAround", {
  justifyContent: 'space-around'
}), _defineProperty(_StyleSheet$create, "justifyBetween", {
  justifyContent: 'space-between'
}), _defineProperty(_StyleSheet$create, "justifyCenter", {
  justifyContent: 'center'
}), _defineProperty(_StyleSheet$create, "justifyEnd", {
  justifyContent: 'flex-end'
}), _defineProperty(_StyleSheet$create, "justifyEvenly", {
  justifyContent: 'space-evenly'
}), _defineProperty(_StyleSheet$create, "justifyStart", {
  justifyContent: 'flex-start'
}), _defineProperty(_StyleSheet$create, "leading2xl", {
  lineHeight: 29
}), _defineProperty(_StyleSheet$create, "leading2xs", {
  lineHeight: 16
}), _defineProperty(_StyleSheet$create, "leading3xl", {
  lineHeight: 32
}), _defineProperty(_StyleSheet$create, "leading3xs", {
  lineHeight: 14
}), _defineProperty(_StyleSheet$create, "leadingBase", {
  lineHeight: 22
}), _defineProperty(_StyleSheet$create, "leadingLg", {
  lineHeight: 24
}), _defineProperty(_StyleSheet$create, "leadingNone", {
  lineHeight: 0
}), _defineProperty(_StyleSheet$create, "leadingSm", {
  lineHeight: 20
}), _defineProperty(_StyleSheet$create, "leadingXl", {
  lineHeight: 26
}), _defineProperty(_StyleSheet$create, "leadingXs", {
  lineHeight: 18
}), _defineProperty(_StyleSheet$create, "left0", {
  left: 0
}), _defineProperty(_StyleSheet$create, "lineThrough", {
  textDecorationLine: 'line-through'
}), _defineProperty(_StyleSheet$create, "lowercase", {
  textTransform: 'lowercase'
}), _defineProperty(_StyleSheet$create, "m1", {
  margin: 5
}), _defineProperty(_StyleSheet$create, "m10", {
  margin: 77
}), _defineProperty(_StyleSheet$create, "m11", {
  margin: 85
}), _defineProperty(_StyleSheet$create, "m12", {
  margin: 110
}), _defineProperty(_StyleSheet$create, "m13", {
  margin: 135
}), _defineProperty(_StyleSheet$create, "m14", {
  margin: 160
}), _defineProperty(_StyleSheet$create, "m15", {
  margin: 185
}), _defineProperty(_StyleSheet$create, "m16", {
  margin: 210
}), _defineProperty(_StyleSheet$create, "m17", {
  margin: 235
}), _defineProperty(_StyleSheet$create, "m18", {
  margin: 260
}), _defineProperty(_StyleSheet$create, "m19", {
  margin: 285
}), _defineProperty(_StyleSheet$create, "m2", {
  margin: 13
}), _defineProperty(_StyleSheet$create, "m20", {
  margin: 310
}), _defineProperty(_StyleSheet$create, "m21", {
  margin: 335
}), _defineProperty(_StyleSheet$create, "m22", {
  margin: 360
}), _defineProperty(_StyleSheet$create, "m23", {
  margin: 385
}), _defineProperty(_StyleSheet$create, "m24", {
  margin: 410
}), _defineProperty(_StyleSheet$create, "m25", {
  margin: 435
}), _defineProperty(_StyleSheet$create, "m3", {
  margin: 21
}), _defineProperty(_StyleSheet$create, "m4", {
  margin: 29
}), _defineProperty(_StyleSheet$create, "m5", {
  margin: 37
}), _defineProperty(_StyleSheet$create, "m6", {
  margin: 45
}), _defineProperty(_StyleSheet$create, "m7", {
  margin: 53
}), _defineProperty(_StyleSheet$create, "m8", {
  margin: 61
}), _defineProperty(_StyleSheet$create, "m9", {
  margin: 69
}), _defineProperty(_StyleSheet$create, "mAuto", {
  margin: 'auto'
}), _defineProperty(_StyleSheet$create, "mB1", {
  marginBottom: 5
}), _defineProperty(_StyleSheet$create, "mB10", {
  marginBottom: 77
}), _defineProperty(_StyleSheet$create, "mB11", {
  marginBottom: 85
}), _defineProperty(_StyleSheet$create, "mB12", {
  marginBottom: 110
}), _defineProperty(_StyleSheet$create, "mB13", {
  marginBottom: 135
}), _defineProperty(_StyleSheet$create, "mB14", {
  marginBottom: 160
}), _defineProperty(_StyleSheet$create, "mB15", {
  marginBottom: 185
}), _defineProperty(_StyleSheet$create, "mB16", {
  marginBottom: 210
}), _defineProperty(_StyleSheet$create, "mB17", {
  marginBottom: 235
}), _defineProperty(_StyleSheet$create, "mB18", {
  marginBottom: 260
}), _defineProperty(_StyleSheet$create, "mB19", {
  marginBottom: 285
}), _defineProperty(_StyleSheet$create, "mB2", {
  marginBottom: 13
}), _defineProperty(_StyleSheet$create, "mB20", {
  marginBottom: 310
}), _defineProperty(_StyleSheet$create, "mB21", {
  marginBottom: 335
}), _defineProperty(_StyleSheet$create, "mB22", {
  marginBottom: 360
}), _defineProperty(_StyleSheet$create, "mB23", {
  marginBottom: 385
}), _defineProperty(_StyleSheet$create, "mB24", {
  marginBottom: 410
}), _defineProperty(_StyleSheet$create, "mB25", {
  marginBottom: 435
}), _defineProperty(_StyleSheet$create, "mB3", {
  marginBottom: 21
}), _defineProperty(_StyleSheet$create, "mB4", {
  marginBottom: 29
}), _defineProperty(_StyleSheet$create, "mB5", {
  marginBottom: 37
}), _defineProperty(_StyleSheet$create, "mB6", {
  marginBottom: 45
}), _defineProperty(_StyleSheet$create, "mB7", {
  marginBottom: 53
}), _defineProperty(_StyleSheet$create, "mB8", {
  marginBottom: 61
}), _defineProperty(_StyleSheet$create, "mB9", {
  marginBottom: 69
}), _defineProperty(_StyleSheet$create, "mBAuto", {
  marginBottom: 'auto'
}), _defineProperty(_StyleSheet$create, "mL1", {
  marginLeft: 5
}), _defineProperty(_StyleSheet$create, "mL10", {
  marginLeft: 77
}), _defineProperty(_StyleSheet$create, "mL11", {
  marginLeft: 85
}), _defineProperty(_StyleSheet$create, "mL12", {
  marginLeft: 110
}), _defineProperty(_StyleSheet$create, "mL13", {
  marginLeft: 135
}), _defineProperty(_StyleSheet$create, "mL14", {
  marginLeft: 160
}), _defineProperty(_StyleSheet$create, "mL15", {
  marginLeft: 185
}), _defineProperty(_StyleSheet$create, "mL16", {
  marginLeft: 210
}), _defineProperty(_StyleSheet$create, "mL17", {
  marginLeft: 235
}), _defineProperty(_StyleSheet$create, "mL18", {
  marginLeft: 260
}), _defineProperty(_StyleSheet$create, "mL19", {
  marginLeft: 285
}), _defineProperty(_StyleSheet$create, "mL2", {
  marginLeft: 13
}), _defineProperty(_StyleSheet$create, "mL20", {
  marginLeft: 310
}), _defineProperty(_StyleSheet$create, "mL21", {
  marginLeft: 335
}), _defineProperty(_StyleSheet$create, "mL22", {
  marginLeft: 360
}), _defineProperty(_StyleSheet$create, "mL23", {
  marginLeft: 385
}), _defineProperty(_StyleSheet$create, "mL24", {
  marginLeft: 410
}), _defineProperty(_StyleSheet$create, "mL25", {
  marginLeft: 435
}), _defineProperty(_StyleSheet$create, "mL3", {
  marginLeft: 21
}), _defineProperty(_StyleSheet$create, "mL4", {
  marginLeft: 29
}), _defineProperty(_StyleSheet$create, "mL5", {
  marginLeft: 37
}), _defineProperty(_StyleSheet$create, "mL6", {
  marginLeft: 45
}), _defineProperty(_StyleSheet$create, "mL7", {
  marginLeft: 53
}), _defineProperty(_StyleSheet$create, "mL8", {
  marginLeft: 61
}), _defineProperty(_StyleSheet$create, "mL9", {
  marginLeft: 69
}), _defineProperty(_StyleSheet$create, "mLAuto", {
  marginLeft: 'auto'
}), _defineProperty(_StyleSheet$create, "mR1", {
  marginRight: 5
}), _defineProperty(_StyleSheet$create, "mR10", {
  marginRight: 77
}), _defineProperty(_StyleSheet$create, "mR11", {
  marginRight: 85
}), _defineProperty(_StyleSheet$create, "mR12", {
  marginRight: 110
}), _defineProperty(_StyleSheet$create, "mR13", {
  marginRight: 135
}), _defineProperty(_StyleSheet$create, "mR14", {
  marginRight: 160
}), _defineProperty(_StyleSheet$create, "mR15", {
  marginRight: 185
}), _defineProperty(_StyleSheet$create, "mR16", {
  marginRight: 210
}), _defineProperty(_StyleSheet$create, "mR17", {
  marginRight: 235
}), _defineProperty(_StyleSheet$create, "mR18", {
  marginRight: 260
}), _defineProperty(_StyleSheet$create, "mR19", {
  marginRight: 285
}), _defineProperty(_StyleSheet$create, "mR2", {
  marginRight: 13
}), _defineProperty(_StyleSheet$create, "mR20", {
  marginRight: 310
}), _defineProperty(_StyleSheet$create, "mR21", {
  marginRight: 335
}), _defineProperty(_StyleSheet$create, "mR22", {
  marginRight: 360
}), _defineProperty(_StyleSheet$create, "mR23", {
  marginRight: 385
}), _defineProperty(_StyleSheet$create, "mR24", {
  marginRight: 410
}), _defineProperty(_StyleSheet$create, "mR25", {
  marginRight: 435
}), _defineProperty(_StyleSheet$create, "mR3", {
  marginRight: 21
}), _defineProperty(_StyleSheet$create, "mR4", {
  marginRight: 29
}), _defineProperty(_StyleSheet$create, "mR5", {
  marginRight: 37
}), _defineProperty(_StyleSheet$create, "mR6", {
  marginRight: 45
}), _defineProperty(_StyleSheet$create, "mR7", {
  marginRight: 53
}), _defineProperty(_StyleSheet$create, "mR8", {
  marginRight: 61
}), _defineProperty(_StyleSheet$create, "mR9", {
  marginRight: 69
}), _defineProperty(_StyleSheet$create, "mRAuto", {
  marginRight: 'auto'
}), _defineProperty(_StyleSheet$create, "mT1", {
  marginTop: 5
}), _defineProperty(_StyleSheet$create, "mT10", {
  marginTop: 77
}), _defineProperty(_StyleSheet$create, "mT11", {
  marginTop: 85
}), _defineProperty(_StyleSheet$create, "mT12", {
  marginTop: 110
}), _defineProperty(_StyleSheet$create, "mT13", {
  marginTop: 135
}), _defineProperty(_StyleSheet$create, "mT14", {
  marginTop: 160
}), _defineProperty(_StyleSheet$create, "mT15", {
  marginTop: 185
}), _defineProperty(_StyleSheet$create, "mT16", {
  marginTop: 210
}), _defineProperty(_StyleSheet$create, "mT17", {
  marginTop: 235
}), _defineProperty(_StyleSheet$create, "mT18", {
  marginTop: 260
}), _defineProperty(_StyleSheet$create, "mT19", {
  marginTop: 285
}), _defineProperty(_StyleSheet$create, "mT2", {
  marginTop: 13
}), _defineProperty(_StyleSheet$create, "mT20", {
  marginTop: 310
}), _defineProperty(_StyleSheet$create, "mT21", {
  marginTop: 335
}), _defineProperty(_StyleSheet$create, "mT22", {
  marginTop: 360
}), _defineProperty(_StyleSheet$create, "mT23", {
  marginTop: 385
}), _defineProperty(_StyleSheet$create, "mT24", {
  marginTop: 410
}), _defineProperty(_StyleSheet$create, "mT25", {
  marginTop: 435
}), _defineProperty(_StyleSheet$create, "mT3", {
  marginTop: 21
}), _defineProperty(_StyleSheet$create, "mT4", {
  marginTop: 29
}), _defineProperty(_StyleSheet$create, "mT5", {
  marginTop: 37
}), _defineProperty(_StyleSheet$create, "mT6", {
  marginTop: 45
}), _defineProperty(_StyleSheet$create, "mT7", {
  marginTop: 53
}), _defineProperty(_StyleSheet$create, "mT8", {
  marginTop: 61
}), _defineProperty(_StyleSheet$create, "mT9", {
  marginTop: 69
}), _defineProperty(_StyleSheet$create, "mTAuto", {
  marginTop: 'auto'
}), _defineProperty(_StyleSheet$create, "mX1", {
  marginHorizontal: 5
}), _defineProperty(_StyleSheet$create, "mX10", {
  marginHorizontal: 77
}), _defineProperty(_StyleSheet$create, "mX11", {
  marginHorizontal: 85
}), _defineProperty(_StyleSheet$create, "mX12", {
  marginHorizontal: 110
}), _defineProperty(_StyleSheet$create, "mX13", {
  marginHorizontal: 135
}), _defineProperty(_StyleSheet$create, "mX14", {
  marginHorizontal: 160
}), _defineProperty(_StyleSheet$create, "mX15", {
  marginHorizontal: 185
}), _defineProperty(_StyleSheet$create, "mX16", {
  marginHorizontal: 210
}), _defineProperty(_StyleSheet$create, "mX17", {
  marginHorizontal: 235
}), _defineProperty(_StyleSheet$create, "mX18", {
  marginHorizontal: 260
}), _defineProperty(_StyleSheet$create, "mX19", {
  marginHorizontal: 285
}), _defineProperty(_StyleSheet$create, "mX2", {
  marginHorizontal: 13
}), _defineProperty(_StyleSheet$create, "mX20", {
  marginHorizontal: 310
}), _defineProperty(_StyleSheet$create, "mX21", {
  marginHorizontal: 335
}), _defineProperty(_StyleSheet$create, "mX22", {
  marginHorizontal: 360
}), _defineProperty(_StyleSheet$create, "mX23", {
  marginHorizontal: 385
}), _defineProperty(_StyleSheet$create, "mX24", {
  marginHorizontal: 410
}), _defineProperty(_StyleSheet$create, "mX25", {
  marginHorizontal: 435
}), _defineProperty(_StyleSheet$create, "mX3", {
  marginHorizontal: 21
}), _defineProperty(_StyleSheet$create, "mX4", {
  marginHorizontal: 29
}), _defineProperty(_StyleSheet$create, "mX5", {
  marginHorizontal: 37
}), _defineProperty(_StyleSheet$create, "mX6", {
  marginHorizontal: 45
}), _defineProperty(_StyleSheet$create, "mX7", {
  marginHorizontal: 53
}), _defineProperty(_StyleSheet$create, "mX8", {
  marginHorizontal: 61
}), _defineProperty(_StyleSheet$create, "mX9", {
  marginHorizontal: 69
}), _defineProperty(_StyleSheet$create, "mXAuto", {
  marginHorizontal: 'auto'
}), _defineProperty(_StyleSheet$create, "mY1", {
  marginVertical: 5
}), _defineProperty(_StyleSheet$create, "mY10", {
  marginVertical: 77
}), _defineProperty(_StyleSheet$create, "mY11", {
  marginVertical: 85
}), _defineProperty(_StyleSheet$create, "mY12", {
  marginVertical: 110
}), _defineProperty(_StyleSheet$create, "mY13", {
  marginVertical: 135
}), _defineProperty(_StyleSheet$create, "mY14", {
  marginVertical: 160
}), _defineProperty(_StyleSheet$create, "mY15", {
  marginVertical: 185
}), _defineProperty(_StyleSheet$create, "mY16", {
  marginVertical: 210
}), _defineProperty(_StyleSheet$create, "mY17", {
  marginVertical: 235
}), _defineProperty(_StyleSheet$create, "mY18", {
  marginVertical: 260
}), _defineProperty(_StyleSheet$create, "mY19", {
  marginVertical: 285
}), _defineProperty(_StyleSheet$create, "mY2", {
  marginVertical: 13
}), _defineProperty(_StyleSheet$create, "mY20", {
  marginVertical: 310
}), _defineProperty(_StyleSheet$create, "mY21", {
  marginVertical: 335
}), _defineProperty(_StyleSheet$create, "mY22", {
  marginVertical: 360
}), _defineProperty(_StyleSheet$create, "mY23", {
  marginVertical: 385
}), _defineProperty(_StyleSheet$create, "mY24", {
  marginVertical: 410
}), _defineProperty(_StyleSheet$create, "mY25", {
  marginVertical: 435
}), _defineProperty(_StyleSheet$create, "mY3", {
  marginVertical: 21
}), _defineProperty(_StyleSheet$create, "mY4", {
  marginVertical: 29
}), _defineProperty(_StyleSheet$create, "mY5", {
  marginVertical: 37
}), _defineProperty(_StyleSheet$create, "mY6", {
  marginVertical: 45
}), _defineProperty(_StyleSheet$create, "mY7", {
  marginVertical: 53
}), _defineProperty(_StyleSheet$create, "mY8", {
  marginVertical: 61
}), _defineProperty(_StyleSheet$create, "mY9", {
  marginVertical: 69
}), _defineProperty(_StyleSheet$create, "mYAuto", {
  marginVertical: 'auto'
}), _defineProperty(_StyleSheet$create, "maxHFull", {
  maxHeight: '100%'
}), _defineProperty(_StyleSheet$create, "maxHScreen", {
  maxHeight: '100vh'
}), _defineProperty(_StyleSheet$create, "maxW2xl", {
  maxWidth: 672
}), _defineProperty(_StyleSheet$create, "maxW3xl", {
  maxWidth: 768
}), _defineProperty(_StyleSheet$create, "maxW4xl", {
  maxWidth: 896
}), _defineProperty(_StyleSheet$create, "maxW5xl", {
  maxWidth: 1024
}), _defineProperty(_StyleSheet$create, "maxW6xl", {
  maxWidth: 1152
}), _defineProperty(_StyleSheet$create, "maxWFull", {
  maxWidth: '100%'
}), _defineProperty(_StyleSheet$create, "maxWLg", {
  maxWidth: 512
}), _defineProperty(_StyleSheet$create, "maxWMd", {
  maxWidth: 448
}), _defineProperty(_StyleSheet$create, "maxWSm", {
  maxWidth: 384
}), _defineProperty(_StyleSheet$create, "maxWXl", {
  maxWidth: 576
}), _defineProperty(_StyleSheet$create, "maxWXs", {
  maxWidth: 320
}), _defineProperty(_StyleSheet$create, "minH0", {
  minHeight: 0
}), _defineProperty(_StyleSheet$create, "minHFull", {
  minHeight: '100%'
}), _defineProperty(_StyleSheet$create, "minHScreen", {
  minHeight: '100vh'
}), _defineProperty(_StyleSheet$create, "minW0", {
  minWidth: 0
}), _defineProperty(_StyleSheet$create, "minWFull", {
  minWidth: '100%'
}), _defineProperty(_StyleSheet$create, "noUnderline", {
  textDecorationLine: 'none'
}), _defineProperty(_StyleSheet$create, "normalCase", {
  textTransform: 'none'
}), _defineProperty(_StyleSheet$create, "notItalic", {
  fontStyle: 'normal'
}), _defineProperty(_StyleSheet$create, "objectCenter", {
  resizeMode: 'center'
}), _defineProperty(_StyleSheet$create, "objectContain", {
  resizeMode: 'contain'
}), _defineProperty(_StyleSheet$create, "objectCover", {
  resizeMode: 'cover'
}), _defineProperty(_StyleSheet$create, "objectRepeat", {
  resizeMode: 'repeat'
}), _defineProperty(_StyleSheet$create, "objectStretch", {
  resizeMode: 'stretch'
}), _defineProperty(_StyleSheet$create, "opacity0", {
  opacity: 0
}), _defineProperty(_StyleSheet$create, "opacity100", {
  opacity: 1
}), _defineProperty(_StyleSheet$create, "opacity25", {
  opacity: 0.25
}), _defineProperty(_StyleSheet$create, "opacity50", {
  opacity: 0.5
}), _defineProperty(_StyleSheet$create, "opacity75", {
  opacity: 0.75
}), _defineProperty(_StyleSheet$create, "overflowHidden", {
  overflow: 'hidden'
}), _defineProperty(_StyleSheet$create, "overflowVisible", {
  overflow: 'visible'
}), _defineProperty(_StyleSheet$create, "p1", {
  padding: 5
}), _defineProperty(_StyleSheet$create, "p10", {
  padding: 77
}), _defineProperty(_StyleSheet$create, "p11", {
  padding: 85
}), _defineProperty(_StyleSheet$create, "p12", {
  padding: 110
}), _defineProperty(_StyleSheet$create, "p13", {
  padding: 135
}), _defineProperty(_StyleSheet$create, "p14", {
  padding: 160
}), _defineProperty(_StyleSheet$create, "p15", {
  padding: 185
}), _defineProperty(_StyleSheet$create, "p16", {
  padding: 210
}), _defineProperty(_StyleSheet$create, "p17", {
  padding: 235
}), _defineProperty(_StyleSheet$create, "p18", {
  padding: 260
}), _defineProperty(_StyleSheet$create, "p19", {
  padding: 285
}), _defineProperty(_StyleSheet$create, "p2", {
  padding: 13
}), _defineProperty(_StyleSheet$create, "p20", {
  padding: 310
}), _defineProperty(_StyleSheet$create, "p21", {
  padding: 335
}), _defineProperty(_StyleSheet$create, "p22", {
  padding: 360
}), _defineProperty(_StyleSheet$create, "p23", {
  padding: 385
}), _defineProperty(_StyleSheet$create, "p24", {
  padding: 410
}), _defineProperty(_StyleSheet$create, "p25", {
  padding: 435
}), _defineProperty(_StyleSheet$create, "p3", {
  padding: 21
}), _defineProperty(_StyleSheet$create, "p4", {
  padding: 29
}), _defineProperty(_StyleSheet$create, "p5", {
  padding: 37
}), _defineProperty(_StyleSheet$create, "p6", {
  padding: 45
}), _defineProperty(_StyleSheet$create, "p7", {
  padding: 53
}), _defineProperty(_StyleSheet$create, "p8", {
  padding: 61
}), _defineProperty(_StyleSheet$create, "p9", {
  padding: 69
}), _defineProperty(_StyleSheet$create, "pB1", {
  paddingBottom: 5
}), _defineProperty(_StyleSheet$create, "pB10", {
  paddingBottom: 77
}), _defineProperty(_StyleSheet$create, "pB11", {
  paddingBottom: 85
}), _defineProperty(_StyleSheet$create, "pB12", {
  paddingBottom: 110
}), _defineProperty(_StyleSheet$create, "pB13", {
  paddingBottom: 135
}), _defineProperty(_StyleSheet$create, "pB14", {
  paddingBottom: 160
}), _defineProperty(_StyleSheet$create, "pB15", {
  paddingBottom: 185
}), _defineProperty(_StyleSheet$create, "pB16", {
  paddingBottom: 210
}), _defineProperty(_StyleSheet$create, "pB17", {
  paddingBottom: 235
}), _defineProperty(_StyleSheet$create, "pB18", {
  paddingBottom: 260
}), _defineProperty(_StyleSheet$create, "pB19", {
  paddingBottom: 285
}), _defineProperty(_StyleSheet$create, "pB2", {
  paddingBottom: 13
}), _defineProperty(_StyleSheet$create, "pB20", {
  paddingBottom: 310
}), _defineProperty(_StyleSheet$create, "pB21", {
  paddingBottom: 335
}), _defineProperty(_StyleSheet$create, "pB22", {
  paddingBottom: 360
}), _defineProperty(_StyleSheet$create, "pB23", {
  paddingBottom: 385
}), _defineProperty(_StyleSheet$create, "pB24", {
  paddingBottom: 410
}), _defineProperty(_StyleSheet$create, "pB25", {
  paddingBottom: 435
}), _defineProperty(_StyleSheet$create, "pB3", {
  paddingBottom: 21
}), _defineProperty(_StyleSheet$create, "pB4", {
  paddingBottom: 29
}), _defineProperty(_StyleSheet$create, "pB5", {
  paddingBottom: 37
}), _defineProperty(_StyleSheet$create, "pB6", {
  paddingBottom: 45
}), _defineProperty(_StyleSheet$create, "pB7", {
  paddingBottom: 53
}), _defineProperty(_StyleSheet$create, "pB8", {
  paddingBottom: 61
}), _defineProperty(_StyleSheet$create, "pB9", {
  paddingBottom: 69
}), _defineProperty(_StyleSheet$create, "pL1", {
  paddingLeft: 5
}), _defineProperty(_StyleSheet$create, "pL10", {
  paddingLeft: 77
}), _defineProperty(_StyleSheet$create, "pL11", {
  paddingLeft: 85
}), _defineProperty(_StyleSheet$create, "pL12", {
  paddingLeft: 110
}), _defineProperty(_StyleSheet$create, "pL13", {
  paddingLeft: 135
}), _defineProperty(_StyleSheet$create, "pL14", {
  paddingLeft: 160
}), _defineProperty(_StyleSheet$create, "pL15", {
  paddingLeft: 185
}), _defineProperty(_StyleSheet$create, "pL16", {
  paddingLeft: 210
}), _defineProperty(_StyleSheet$create, "pL17", {
  paddingLeft: 235
}), _defineProperty(_StyleSheet$create, "pL18", {
  paddingLeft: 260
}), _defineProperty(_StyleSheet$create, "pL19", {
  paddingLeft: 285
}), _defineProperty(_StyleSheet$create, "pL2", {
  paddingLeft: 13
}), _defineProperty(_StyleSheet$create, "pL20", {
  paddingLeft: 310
}), _defineProperty(_StyleSheet$create, "pL21", {
  paddingLeft: 335
}), _defineProperty(_StyleSheet$create, "pL22", {
  paddingLeft: 360
}), _defineProperty(_StyleSheet$create, "pL23", {
  paddingLeft: 385
}), _defineProperty(_StyleSheet$create, "pL24", {
  paddingLeft: 410
}), _defineProperty(_StyleSheet$create, "pL25", {
  paddingLeft: 435
}), _defineProperty(_StyleSheet$create, "pL3", {
  paddingLeft: 21
}), _defineProperty(_StyleSheet$create, "pL4", {
  paddingLeft: 29
}), _defineProperty(_StyleSheet$create, "pL5", {
  paddingLeft: 37
}), _defineProperty(_StyleSheet$create, "pL6", {
  paddingLeft: 45
}), _defineProperty(_StyleSheet$create, "pL7", {
  paddingLeft: 53
}), _defineProperty(_StyleSheet$create, "pL8", {
  paddingLeft: 61
}), _defineProperty(_StyleSheet$create, "pL9", {
  paddingLeft: 69
}), _defineProperty(_StyleSheet$create, "pR1", {
  paddingRight: 5
}), _defineProperty(_StyleSheet$create, "pR10", {
  paddingRight: 77
}), _defineProperty(_StyleSheet$create, "pR11", {
  paddingRight: 85
}), _defineProperty(_StyleSheet$create, "pR12", {
  paddingRight: 110
}), _defineProperty(_StyleSheet$create, "pR13", {
  paddingRight: 135
}), _defineProperty(_StyleSheet$create, "pR14", {
  paddingRight: 160
}), _defineProperty(_StyleSheet$create, "pR15", {
  paddingRight: 185
}), _defineProperty(_StyleSheet$create, "pR16", {
  paddingRight: 210
}), _defineProperty(_StyleSheet$create, "pR17", {
  paddingRight: 235
}), _defineProperty(_StyleSheet$create, "pR18", {
  paddingRight: 260
}), _defineProperty(_StyleSheet$create, "pR19", {
  paddingRight: 285
}), _defineProperty(_StyleSheet$create, "pR2", {
  paddingRight: 13
}), _defineProperty(_StyleSheet$create, "pR20", {
  paddingRight: 310
}), _defineProperty(_StyleSheet$create, "pR21", {
  paddingRight: 335
}), _defineProperty(_StyleSheet$create, "pR22", {
  paddingRight: 360
}), _defineProperty(_StyleSheet$create, "pR23", {
  paddingRight: 385
}), _defineProperty(_StyleSheet$create, "pR24", {
  paddingRight: 410
}), _defineProperty(_StyleSheet$create, "pR25", {
  paddingRight: 435
}), _defineProperty(_StyleSheet$create, "pR3", {
  paddingRight: 21
}), _defineProperty(_StyleSheet$create, "pR4", {
  paddingRight: 29
}), _defineProperty(_StyleSheet$create, "pR5", {
  paddingRight: 37
}), _defineProperty(_StyleSheet$create, "pR6", {
  paddingRight: 45
}), _defineProperty(_StyleSheet$create, "pR7", {
  paddingRight: 53
}), _defineProperty(_StyleSheet$create, "pR8", {
  paddingRight: 61
}), _defineProperty(_StyleSheet$create, "pR9", {
  paddingRight: 69
}), _defineProperty(_StyleSheet$create, "pT1", {
  paddingTop: 5
}), _defineProperty(_StyleSheet$create, "pT10", {
  paddingTop: 77
}), _defineProperty(_StyleSheet$create, "pT11", {
  paddingTop: 85
}), _defineProperty(_StyleSheet$create, "pT12", {
  paddingTop: 110
}), _defineProperty(_StyleSheet$create, "pT13", {
  paddingTop: 135
}), _defineProperty(_StyleSheet$create, "pT14", {
  paddingTop: 160
}), _defineProperty(_StyleSheet$create, "pT15", {
  paddingTop: 185
}), _defineProperty(_StyleSheet$create, "pT16", {
  paddingTop: 210
}), _defineProperty(_StyleSheet$create, "pT17", {
  paddingTop: 235
}), _defineProperty(_StyleSheet$create, "pT18", {
  paddingTop: 260
}), _defineProperty(_StyleSheet$create, "pT19", {
  paddingTop: 285
}), _defineProperty(_StyleSheet$create, "pT2", {
  paddingTop: 13
}), _defineProperty(_StyleSheet$create, "pT20", {
  paddingTop: 310
}), _defineProperty(_StyleSheet$create, "pT21", {
  paddingTop: 335
}), _defineProperty(_StyleSheet$create, "pT22", {
  paddingTop: 360
}), _defineProperty(_StyleSheet$create, "pT23", {
  paddingTop: 385
}), _defineProperty(_StyleSheet$create, "pT24", {
  paddingTop: 410
}), _defineProperty(_StyleSheet$create, "pT25", {
  paddingTop: 435
}), _defineProperty(_StyleSheet$create, "pT3", {
  paddingTop: 21
}), _defineProperty(_StyleSheet$create, "pT4", {
  paddingTop: 29
}), _defineProperty(_StyleSheet$create, "pT5", {
  paddingTop: 37
}), _defineProperty(_StyleSheet$create, "pT6", {
  paddingTop: 45
}), _defineProperty(_StyleSheet$create, "pT7", {
  paddingTop: 53
}), _defineProperty(_StyleSheet$create, "pT8", {
  paddingTop: 61
}), _defineProperty(_StyleSheet$create, "pT9", {
  paddingTop: 69
}), _defineProperty(_StyleSheet$create, "pX1", {
  paddingHorizontal: 5
}), _defineProperty(_StyleSheet$create, "pX10", {
  paddingHorizontal: 77
}), _defineProperty(_StyleSheet$create, "pX11", {
  paddingHorizontal: 85
}), _defineProperty(_StyleSheet$create, "pX12", {
  paddingHorizontal: 110
}), _defineProperty(_StyleSheet$create, "pX13", {
  paddingHorizontal: 135
}), _defineProperty(_StyleSheet$create, "pX14", {
  paddingHorizontal: 160
}), _defineProperty(_StyleSheet$create, "pX15", {
  paddingHorizontal: 185
}), _defineProperty(_StyleSheet$create, "pX16", {
  paddingHorizontal: 210
}), _defineProperty(_StyleSheet$create, "pX17", {
  paddingHorizontal: 235
}), _defineProperty(_StyleSheet$create, "pX18", {
  paddingHorizontal: 260
}), _defineProperty(_StyleSheet$create, "pX19", {
  paddingHorizontal: 285
}), _defineProperty(_StyleSheet$create, "pX2", {
  paddingHorizontal: 13
}), _defineProperty(_StyleSheet$create, "pX20", {
  paddingHorizontal: 310
}), _defineProperty(_StyleSheet$create, "pX21", {
  paddingHorizontal: 335
}), _defineProperty(_StyleSheet$create, "pX22", {
  paddingHorizontal: 360
}), _defineProperty(_StyleSheet$create, "pX23", {
  paddingHorizontal: 385
}), _defineProperty(_StyleSheet$create, "pX24", {
  paddingHorizontal: 410
}), _defineProperty(_StyleSheet$create, "pX25", {
  paddingHorizontal: 435
}), _defineProperty(_StyleSheet$create, "pX3", {
  paddingHorizontal: 21
}), _defineProperty(_StyleSheet$create, "pX4", {
  paddingHorizontal: 29
}), _defineProperty(_StyleSheet$create, "pX5", {
  paddingHorizontal: 37
}), _defineProperty(_StyleSheet$create, "pX6", {
  paddingHorizontal: 45
}), _defineProperty(_StyleSheet$create, "pX7", {
  paddingHorizontal: 53
}), _defineProperty(_StyleSheet$create, "pX8", {
  paddingHorizontal: 61
}), _defineProperty(_StyleSheet$create, "pX9", {
  paddingHorizontal: 69
}), _defineProperty(_StyleSheet$create, "pY1", {
  paddingVertical: 5
}), _defineProperty(_StyleSheet$create, "pY10", {
  paddingVertical: 77
}), _defineProperty(_StyleSheet$create, "pY11", {
  paddingVertical: 85
}), _defineProperty(_StyleSheet$create, "pY12", {
  paddingVertical: 110
}), _defineProperty(_StyleSheet$create, "pY13", {
  paddingVertical: 135
}), _defineProperty(_StyleSheet$create, "pY14", {
  paddingVertical: 160
}), _defineProperty(_StyleSheet$create, "pY15", {
  paddingVertical: 185
}), _defineProperty(_StyleSheet$create, "pY16", {
  paddingVertical: 210
}), _defineProperty(_StyleSheet$create, "pY17", {
  paddingVertical: 235
}), _defineProperty(_StyleSheet$create, "pY18", {
  paddingVertical: 260
}), _defineProperty(_StyleSheet$create, "pY19", {
  paddingVertical: 285
}), _defineProperty(_StyleSheet$create, "pY2", {
  paddingVertical: 13
}), _defineProperty(_StyleSheet$create, "pY20", {
  paddingVertical: 310
}), _defineProperty(_StyleSheet$create, "pY21", {
  paddingVertical: 335
}), _defineProperty(_StyleSheet$create, "pY22", {
  paddingVertical: 360
}), _defineProperty(_StyleSheet$create, "pY23", {
  paddingVertical: 385
}), _defineProperty(_StyleSheet$create, "pY24", {
  paddingVertical: 410
}), _defineProperty(_StyleSheet$create, "pY25", {
  paddingVertical: 435
}), _defineProperty(_StyleSheet$create, "pY3", {
  paddingVertical: 21
}), _defineProperty(_StyleSheet$create, "pY4", {
  paddingVertical: 29
}), _defineProperty(_StyleSheet$create, "pY5", {
  paddingVertical: 37
}), _defineProperty(_StyleSheet$create, "pY6", {
  paddingVertical: 45
}), _defineProperty(_StyleSheet$create, "pY7", {
  paddingVertical: 53
}), _defineProperty(_StyleSheet$create, "pY8", {
  paddingVertical: 61
}), _defineProperty(_StyleSheet$create, "pY9", {
  paddingVertical: 69
}), _defineProperty(_StyleSheet$create, "relative", {
  position: 'relative'
}), _defineProperty(_StyleSheet$create, "resizeCenter", {
  resizeMode: 'center'
}), _defineProperty(_StyleSheet$create, "resizeContain", {
  resizeMode: 'contain'
}), _defineProperty(_StyleSheet$create, "resizeCover", {
  resizeMode: 'cover'
}), _defineProperty(_StyleSheet$create, "resizeRepeat", {
  resizeMode: 'repeat'
}), _defineProperty(_StyleSheet$create, "resizeStretch", {
  resizeMode: 'stretch'
}), _defineProperty(_StyleSheet$create, "right0", {
  right: 0
}), _defineProperty(_StyleSheet$create, "rounded", {
  borderRadius: 8
}), _defineProperty(_StyleSheet$create, "roundedB", {
  borderBottomLeftRadius: 8,
  borderBottomRightRadius: 8
}), _defineProperty(_StyleSheet$create, "roundedBFull", {
  borderBottomLeftRadius: 9999,
  borderBottomRightRadius: 9999
}), _defineProperty(_StyleSheet$create, "roundedBLg", {
  borderBottomLeftRadius: 30,
  borderBottomRightRadius: 30
}), _defineProperty(_StyleSheet$create, "roundedBNone", {
  borderBottomLeftRadius: 0,
  borderBottomRightRadius: 0
}), _defineProperty(_StyleSheet$create, "roundedBSm", {
  borderBottomLeftRadius: 3,
  borderBottomRightRadius: 3
}), _defineProperty(_StyleSheet$create, "roundedBl", {
  borderBottomLeftRadius: 8
}), _defineProperty(_StyleSheet$create, "roundedBlFull", {
  borderBottomLeftRadius: 9999
}), _defineProperty(_StyleSheet$create, "roundedBlLg", {
  borderBottomLeftRadius: 30
}), _defineProperty(_StyleSheet$create, "roundedBlNone", {
  borderBottomLeftRadius: 0
}), _defineProperty(_StyleSheet$create, "roundedBlSm", {
  borderBottomLeftRadius: 3
}), _defineProperty(_StyleSheet$create, "roundedBr", {
  borderBottomRightRadius: 8
}), _defineProperty(_StyleSheet$create, "roundedBrFull", {
  borderBottomRightRadius: 9999
}), _defineProperty(_StyleSheet$create, "roundedBrLg", {
  borderBottomRightRadius: 30
}), _defineProperty(_StyleSheet$create, "roundedBrNone", {
  borderBottomRightRadius: 0
}), _defineProperty(_StyleSheet$create, "roundedBrSm", {
  borderBottomRightRadius: 3
}), _defineProperty(_StyleSheet$create, "roundedDir", {
  borderRadius: 8
}), _defineProperty(_StyleSheet$create, "roundedDirBe", {
  borderBottomEndRadius: 8
}), _defineProperty(_StyleSheet$create, "roundedDirBeFull", {
  borderBottomEndRadius: 9999
}), _defineProperty(_StyleSheet$create, "roundedDirBeLg", {
  borderBottomEndRadius: 30
}), _defineProperty(_StyleSheet$create, "roundedDirBeNone", {
  borderBottomEndRadius: 0
}), _defineProperty(_StyleSheet$create, "roundedDirBeSm", {
  borderBottomEndRadius: 3
}), _defineProperty(_StyleSheet$create, "roundedDirBs", {
  borderBottomStartRadius: 8
}), _defineProperty(_StyleSheet$create, "roundedDirBsFull", {
  borderBottomStartRadius: 9999
}), _defineProperty(_StyleSheet$create, "roundedDirBsLg", {
  borderBottomStartRadius: 30
}), _defineProperty(_StyleSheet$create, "roundedDirBsNone", {
  borderBottomStartRadius: 0
}), _defineProperty(_StyleSheet$create, "roundedDirBsSm", {
  borderBottomStartRadius: 3
}), _defineProperty(_StyleSheet$create, "roundedDirE", {
  borderBottomEndRadius: 8,
  borderTopEndRadius: 8
}), _defineProperty(_StyleSheet$create, "roundedDirEFull", {
  borderBottomEndRadius: 9999,
  borderTopEndRadius: 9999
}), _defineProperty(_StyleSheet$create, "roundedDirELg", {
  borderBottomEndRadius: 30,
  borderTopEndRadius: 30
}), _defineProperty(_StyleSheet$create, "roundedDirENone", {
  borderBottomEndRadius: 0,
  borderTopEndRadius: 0
}), _defineProperty(_StyleSheet$create, "roundedDirESm", {
  borderBottomEndRadius: 3,
  borderTopEndRadius: 3
}), _defineProperty(_StyleSheet$create, "roundedDirFull", {
  borderRadius: 9999
}), _defineProperty(_StyleSheet$create, "roundedDirLg", {
  borderRadius: 30
}), _defineProperty(_StyleSheet$create, "roundedDirNone", {
  borderRadius: 0
}), _defineProperty(_StyleSheet$create, "roundedDirS", {
  borderBottomStartRadius: 8,
  borderTopStartRadius: 8
}), _defineProperty(_StyleSheet$create, "roundedDirSFull", {
  borderBottomStartRadius: 9999,
  borderTopStartRadius: 9999
}), _defineProperty(_StyleSheet$create, "roundedDirSLg", {
  borderBottomStartRadius: 30,
  borderTopStartRadius: 30
}), _defineProperty(_StyleSheet$create, "roundedDirSNone", {
  borderBottomStartRadius: 0,
  borderTopStartRadius: 0
}), _defineProperty(_StyleSheet$create, "roundedDirSSm", {
  borderBottomStartRadius: 3,
  borderTopStartRadius: 3
}), _defineProperty(_StyleSheet$create, "roundedDirSm", {
  borderRadius: 3
}), _defineProperty(_StyleSheet$create, "roundedDirTe", {
  borderTopEndRadius: 8
}), _defineProperty(_StyleSheet$create, "roundedDirTeFull", {
  borderTopEndRadius: 9999
}), _defineProperty(_StyleSheet$create, "roundedDirTeLg", {
  borderTopEndRadius: 30
}), _defineProperty(_StyleSheet$create, "roundedDirTeNone", {
  borderTopEndRadius: 0
}), _defineProperty(_StyleSheet$create, "roundedDirTeSm", {
  borderTopEndRadius: 3
}), _defineProperty(_StyleSheet$create, "roundedDirTs", {
  borderTopStartRadius: 8
}), _defineProperty(_StyleSheet$create, "roundedDirTsFull", {
  borderTopStartRadius: 9999
}), _defineProperty(_StyleSheet$create, "roundedDirTsLg", {
  borderTopStartRadius: 30
}), _defineProperty(_StyleSheet$create, "roundedDirTsNone", {
  borderTopStartRadius: 0
}), _defineProperty(_StyleSheet$create, "roundedDirTsSm", {
  borderTopStartRadius: 3
}), _defineProperty(_StyleSheet$create, "roundedFull", {
  borderRadius: 9999
}), _defineProperty(_StyleSheet$create, "roundedL", {
  borderBottomLeftRadius: 8,
  borderTopLeftRadius: 8
}), _defineProperty(_StyleSheet$create, "roundedLFull", {
  borderBottomLeftRadius: 9999,
  borderTopLeftRadius: 9999
}), _defineProperty(_StyleSheet$create, "roundedLLg", {
  borderBottomLeftRadius: 30,
  borderTopLeftRadius: 30
}), _defineProperty(_StyleSheet$create, "roundedLNone", {
  borderBottomLeftRadius: 0,
  borderTopLeftRadius: 0
}), _defineProperty(_StyleSheet$create, "roundedLSm", {
  borderBottomLeftRadius: 3,
  borderTopLeftRadius: 3
}), _defineProperty(_StyleSheet$create, "roundedLg", {
  borderRadius: 30
}), _defineProperty(_StyleSheet$create, "roundedNone", {
  borderRadius: 0
}), _defineProperty(_StyleSheet$create, "roundedR", {
  borderBottomRightRadius: 8,
  borderTopRightRadius: 8
}), _defineProperty(_StyleSheet$create, "roundedRFull", {
  borderBottomRightRadius: 9999,
  borderTopRightRadius: 9999
}), _defineProperty(_StyleSheet$create, "roundedRLg", {
  borderBottomRightRadius: 30,
  borderTopRightRadius: 30
}), _defineProperty(_StyleSheet$create, "roundedRNone", {
  borderBottomRightRadius: 0,
  borderTopRightRadius: 0
}), _defineProperty(_StyleSheet$create, "roundedRSm", {
  borderBottomRightRadius: 3,
  borderTopRightRadius: 3
}), _defineProperty(_StyleSheet$create, "roundedSm", {
  borderRadius: 3
}), _defineProperty(_StyleSheet$create, "roundedT", {
  borderTopLeftRadius: 8,
  borderTopRightRadius: 8
}), _defineProperty(_StyleSheet$create, "roundedTFull", {
  borderTopLeftRadius: 9999,
  borderTopRightRadius: 9999
}), _defineProperty(_StyleSheet$create, "roundedTLg", {
  borderTopLeftRadius: 30,
  borderTopRightRadius: 30
}), _defineProperty(_StyleSheet$create, "roundedTNone", {
  borderTopLeftRadius: 0,
  borderTopRightRadius: 0
}), _defineProperty(_StyleSheet$create, "roundedTSm", {
  borderTopLeftRadius: 3,
  borderTopRightRadius: 3
}), _defineProperty(_StyleSheet$create, "roundedTl", {
  borderTopLeftRadius: 8
}), _defineProperty(_StyleSheet$create, "roundedTlFull", {
  borderTopLeftRadius: 9999
}), _defineProperty(_StyleSheet$create, "roundedTlLg", {
  borderTopLeftRadius: 30
}), _defineProperty(_StyleSheet$create, "roundedTlNone", {
  borderTopLeftRadius: 0
}), _defineProperty(_StyleSheet$create, "roundedTlSm", {
  borderTopLeftRadius: 3
}), _defineProperty(_StyleSheet$create, "roundedTr", {
  borderTopRightRadius: 8
}), _defineProperty(_StyleSheet$create, "roundedTrFull", {
  borderTopRightRadius: 9999
}), _defineProperty(_StyleSheet$create, "roundedTrLg", {
  borderTopRightRadius: 30
}), _defineProperty(_StyleSheet$create, "roundedTrNone", {
  borderTopRightRadius: 0
}), _defineProperty(_StyleSheet$create, "roundedTrSm", {
  borderTopRightRadius: 3
}), _defineProperty(_StyleSheet$create, "selfAuto", {
  alignSelf: 'auto'
}), _defineProperty(_StyleSheet$create, "selfBaseline", {
  alignSelf: 'baseline'
}), _defineProperty(_StyleSheet$create, "selfCenter", {
  alignSelf: 'center'
}), _defineProperty(_StyleSheet$create, "selfEnd", {
  alignSelf: 'flex-end'
}), _defineProperty(_StyleSheet$create, "selfStart", {
  alignSelf: 'flex-start'
}), _defineProperty(_StyleSheet$create, "selfStretch", {
  alignSelf: 'stretch'
}), _defineProperty(_StyleSheet$create, "shadow", {
  elevation: 1.5,
  shadowColor: 'rgba(0,0,0,0.1)',
  shadowOffset: {
    height: 1,
    width: 0
  },
  shadowOpacity: 1,
  shadowRadius: 3
}), _defineProperty(_StyleSheet$create, "shadow2xl", {
  elevation: 25,
  shadowColor: 'rgba(0,0,0,0.25)',
  shadowOffset: {
    height: 25,
    width: 0
  },
  shadowOpacity: 1,
  shadowRadius: 50
}), _defineProperty(_StyleSheet$create, "shadowInner", {
  elevation: 0,
  shadowColor: 'rgba(0,0,0,0)',
  shadowOffset: {
    height: 0,
    width: 0
  },
  shadowOpacity: 0,
  shadowRadius: 0
}), _defineProperty(_StyleSheet$create, "shadowLg", {
  elevation: 7.5,
  shadowColor: 'rgba(0,0,0,0.1)',
  shadowOffset: {
    height: 10,
    width: 0
  },
  shadowOpacity: 1,
  shadowRadius: 15
}), _defineProperty(_StyleSheet$create, "shadowMd", {
  elevation: 3,
  shadowColor: 'rgba(0,0,0,0.1)',
  shadowOffset: {
    height: 4,
    width: 0
  },
  shadowOpacity: 1,
  shadowRadius: 6
}), _defineProperty(_StyleSheet$create, "shadowNone", {
  elevation: 0,
  shadowColor: 'rgba(0,0,0,0)',
  shadowOffset: {
    height: 0,
    width: 0
  },
  shadowOpacity: 0,
  shadowRadius: 0
}), _defineProperty(_StyleSheet$create, "shadowOutline", {
  elevation: 0,
  shadowColor: 'rgba(66,153,225,0.5)',
  shadowOffset: {
    height: 0,
    width: 0
  },
  shadowOpacity: 1,
  shadowRadius: 0
}), _defineProperty(_StyleSheet$create, "shadowXl", {
  elevation: 12.5,
  shadowColor: 'rgba(0,0,0,0.1)',
  shadowOffset: {
    height: 20,
    width: 0
  },
  shadowOpacity: 1,
  shadowRadius: 25
}), _defineProperty(_StyleSheet$create, "start0", {
  start: 0
}), _defineProperty(_StyleSheet$create, "text1", {
  fontSize: 26
}), _defineProperty(_StyleSheet$create, "text1_2", {
  fontSize: 32
}), _defineProperty(_StyleSheet$create, "text2", {
  fontSize: 22.7
}), _defineProperty(_StyleSheet$create, "text3", {
  fontSize: 21.1
}), _defineProperty(_StyleSheet$create, "text4", {
  fontSize: 19.5
}), _defineProperty(_StyleSheet$create, "text5", {
  fontSize: 16.2
}), _defineProperty(_StyleSheet$create, "text6", {
  fontSize: 14.6
}), _defineProperty(_StyleSheet$create, "text7", {
  fontSize: 13
}), _defineProperty(_StyleSheet$create, "text8", {
  fontSize: 9.7
}), _defineProperty(_StyleSheet$create, "textAuto", {
  textAlign: "auto"
}), _defineProperty(_StyleSheet$create, "textBlack", {
  color: "#000000"
}), _defineProperty(_StyleSheet$create, "textCenter", {
  textAlign: "center"
}), _defineProperty(_StyleSheet$create, "textClear", {
  color: "#00000000"
}), _defineProperty(_StyleSheet$create, "textDarkGrey", {
  color: "#9B9B9B"
}), _defineProperty(_StyleSheet$create, "textDisGrey", {
  color: "#EFEFEF"
}), _defineProperty(_StyleSheet$create, "textFirst", {
  color: "#4A5155"
}), _defineProperty(_StyleSheet$create, "textGreen", {
  color: "#7ED321"
}), _defineProperty(_StyleSheet$create, "textJustify", {
  textAlign: "justify"
}), _defineProperty(_StyleSheet$create, "textLeft", {
  textAlign: "left"
}), _defineProperty(_StyleSheet$create, "textLightBlue", {
  color: "#F1F5F8"
}), _defineProperty(_StyleSheet$create, "textLightOrange", {
  color: "#FFEDE7"
}), _defineProperty(_StyleSheet$create, "textMainBlue", {
  color: "#236A95"
}), _defineProperty(_StyleSheet$create, "textMidBlue", {
  color: "#4380A4"
}), _defineProperty(_StyleSheet$create, "textOrange", {
  color: "#F76122"
}), _defineProperty(_StyleSheet$create, "textRed", {
  color: "#D0021B"
}), _defineProperty(_StyleSheet$create, "textRight", {
  textAlign: "right"
}), _defineProperty(_StyleSheet$create, "textSecond", {
  color: "#707F89"
}), _defineProperty(_StyleSheet$create, "textShadow", {
  textShadowColor: 'rgba(0,0,0,0.1)',
  textShadowOffset: {
    height: 1,
    width: 0
  },
  textShadowRadius: 3
}), _defineProperty(_StyleSheet$create, "textShadow2xl", {
  textShadowColor: 'rgba(0,0,0,0.25)',
  textShadowOffset: {
    height: 25,
    width: 0
  },
  textShadowRadius: 50
}), _defineProperty(_StyleSheet$create, "textShadowInner", {
  textShadowColor: 'rgba(0,0,0,0)',
  textShadowOffset: {
    height: 0,
    width: 0
  },
  textShadowRadius: 0
}), _defineProperty(_StyleSheet$create, "textShadowLg", {
  textShadowColor: 'rgba(0,0,0,0.1)',
  textShadowOffset: {
    height: 10,
    width: 0
  },
  textShadowRadius: 15
}), _defineProperty(_StyleSheet$create, "textShadowMd", {
  textShadowColor: 'rgba(0,0,0,0.1)',
  textShadowOffset: {
    height: 4,
    width: 0
  },
  textShadowRadius: 6
}), _defineProperty(_StyleSheet$create, "textShadowNone", {
  textShadowColor: 'rgba(0,0,0,0)',
  textShadowOffset: {
    height: 0,
    width: 0
  },
  textShadowRadius: 0
}), _defineProperty(_StyleSheet$create, "textShadowOutline", {
  textShadowColor: 'rgba(66,153,225,0.5)',
  textShadowOffset: {
    height: 0,
    width: 0
  },
  textShadowRadius: 0
}), _defineProperty(_StyleSheet$create, "textShadowXl", {
  textShadowColor: 'rgba(0,0,0,0.1)',
  textShadowOffset: {
    height: 20,
    width: 0
  },
  textShadowRadius: 25
}), _defineProperty(_StyleSheet$create, "textThird", {
  color: "#CBD1D5"
}), _defineProperty(_StyleSheet$create, "textWarmGrey", {
  color: "#FAF9F8"
}), _defineProperty(_StyleSheet$create, "textWhite", {
  color: "#FFFFFF"
}), _defineProperty(_StyleSheet$create, "tintBlack", {
  tintColor: "#000000"
}), _defineProperty(_StyleSheet$create, "tintClear", {
  tintColor: "#00000000"
}), _defineProperty(_StyleSheet$create, "tintDarkGrey", {
  tintColor: "#9B9B9B"
}), _defineProperty(_StyleSheet$create, "tintDisGrey", {
  tintColor: "#EFEFEF"
}), _defineProperty(_StyleSheet$create, "tintFirst", {
  tintColor: "#4A5155"
}), _defineProperty(_StyleSheet$create, "tintGreen", {
  tintColor: "#7ED321"
}), _defineProperty(_StyleSheet$create, "tintLightBlue", {
  tintColor: "#F1F5F8"
}), _defineProperty(_StyleSheet$create, "tintLightOrange", {
  tintColor: "#FFEDE7"
}), _defineProperty(_StyleSheet$create, "tintMainBlue", {
  tintColor: "#236A95"
}), _defineProperty(_StyleSheet$create, "tintMidBlue", {
  tintColor: "#4380A4"
}), _defineProperty(_StyleSheet$create, "tintOrange", {
  tintColor: "#F76122"
}), _defineProperty(_StyleSheet$create, "tintRed", {
  tintColor: "#D0021B"
}), _defineProperty(_StyleSheet$create, "tintSecond", {
  tintColor: "#707F89"
}), _defineProperty(_StyleSheet$create, "tintThird", {
  tintColor: "#CBD1D5"
}), _defineProperty(_StyleSheet$create, "tintWarmGrey", {
  tintColor: "#FAF9F8"
}), _defineProperty(_StyleSheet$create, "tintWhite", {
  tintColor: "#FFFFFF"
}), _defineProperty(_StyleSheet$create, "top0", {
  top: 0
}), _defineProperty(_StyleSheet$create, "trackingNormal", {
  letterSpacing: 0
}), _defineProperty(_StyleSheet$create, "trackingTight", {
  letterSpacing: -0.4
}), _defineProperty(_StyleSheet$create, "trackingTighter", {
  letterSpacing: -0.8
}), _defineProperty(_StyleSheet$create, "trackingWide", {
  letterSpacing: 0.4
}), _defineProperty(_StyleSheet$create, "trackingWider", {
  letterSpacing: 0.8
}), _defineProperty(_StyleSheet$create, "trackingWidest", {
  letterSpacing: 1.6
}), _defineProperty(_StyleSheet$create, "underline", {
  textDecorationLine: "underline"
}), _defineProperty(_StyleSheet$create, "underlineLineThrough", {
  textDecorationLine: "underline line-through"
}), _defineProperty(_StyleSheet$create, "uppercase", {
  textTransform: "uppercase"
}), _defineProperty(_StyleSheet$create, "w1", {
  width: 5
}), _defineProperty(_StyleSheet$create, "w10", {
  width: 77
}), _defineProperty(_StyleSheet$create, "w10_12", {
  width: "83.333333%"
}), _defineProperty(_StyleSheet$create, "w11", {
  width: 85
}), _defineProperty(_StyleSheet$create, "w11_12", {
  width: "91.666666%"
}), _defineProperty(_StyleSheet$create, "w12", {
  width: 110
}), _defineProperty(_StyleSheet$create, "w13", {
  width: 135
}), _defineProperty(_StyleSheet$create, "w14", {
  width: 160
}), _defineProperty(_StyleSheet$create, "w15", {
  width: 185
}), _defineProperty(_StyleSheet$create, "w16", {
  width: 210
}), _defineProperty(_StyleSheet$create, "w17", {
  width: 235
}), _defineProperty(_StyleSheet$create, "w18", {
  width: 260
}), _defineProperty(_StyleSheet$create, "w19", {
  width: 285
}), _defineProperty(_StyleSheet$create, "w1_12", {
  width: "8.333333%"
}), _defineProperty(_StyleSheet$create, "w1_2", {
  width: "50%"
}), _defineProperty(_StyleSheet$create, "w1_3", {
  width: "33.333333%"
}), _defineProperty(_StyleSheet$create, "w1_4", {
  width: "25%"
}), _defineProperty(_StyleSheet$create, "w1_5", {
  width: "20%"
}), _defineProperty(_StyleSheet$create, "w1_6", {
  width: "16.666666%"
}), _defineProperty(_StyleSheet$create, "w2", {
  width: 13
}), _defineProperty(_StyleSheet$create, "w20", {
  width: 310
}), _defineProperty(_StyleSheet$create, "w21", {
  width: 335
}), _defineProperty(_StyleSheet$create, "w22", {
  width: 360
}), _defineProperty(_StyleSheet$create, "w23", {
  width: 385
}), _defineProperty(_StyleSheet$create, "w24", {
  width: 410
}), _defineProperty(_StyleSheet$create, "w25", {
  width: 435
}), _defineProperty(_StyleSheet$create, "w2_12", {
  width: "16.666666%"
}), _defineProperty(_StyleSheet$create, "w2_3", {
  width: "66.666666%"
}), _defineProperty(_StyleSheet$create, "w2_4", {
  width: "50%"
}), _defineProperty(_StyleSheet$create, "w2_5", {
  width: "40%"
}), _defineProperty(_StyleSheet$create, "w2_6", {
  width: "33.333333%"
}), _defineProperty(_StyleSheet$create, "w3", {
  width: 21
}), _defineProperty(_StyleSheet$create, "w3_12", {
  width: "25%"
}), _defineProperty(_StyleSheet$create, "w3_4", {
  width: "75%"
}), _defineProperty(_StyleSheet$create, "w3_5", {
  width: "60%"
}), _defineProperty(_StyleSheet$create, "w3_6", {
  width: "50%"
}), _defineProperty(_StyleSheet$create, "w4", {
  width: 29
}), _defineProperty(_StyleSheet$create, "w4_12", {
  width: "33.333333%"
}), _defineProperty(_StyleSheet$create, "w4_5", {
  width: "80%"
}), _defineProperty(_StyleSheet$create, "w4_6", {
  width: "66.666666%"
}), _defineProperty(_StyleSheet$create, "w5", {
  width: 37
}), _defineProperty(_StyleSheet$create, "w5_12", {
  width: "41.666666%"
}), _defineProperty(_StyleSheet$create, "w5_6", {
  width: "83.333333%"
}), _defineProperty(_StyleSheet$create, "w6", {
  width: 45
}), _defineProperty(_StyleSheet$create, "w6_12", {
  width: "50%"
}), _defineProperty(_StyleSheet$create, "w7", {
  width: 53
}), _defineProperty(_StyleSheet$create, "w7_12", {
  width: "58.333333%"
}), _defineProperty(_StyleSheet$create, "w8", {
  width: 61
}), _defineProperty(_StyleSheet$create, "w8_12", {
  width: "66.666666%"
}), _defineProperty(_StyleSheet$create, "w9", {
  width: 69
}), _defineProperty(_StyleSheet$create, "w9_12", {
  width: "75%"
}), _defineProperty(_StyleSheet$create, "wAuto", {
  width: "auto"
}), _defineProperty(_StyleSheet$create, "wFull", {
  width: "100%"
}), _defineProperty(_StyleSheet$create, "wScreen", {
  width: "100vw"
}), _defineProperty(_StyleSheet$create, "h1_2", {
  height: "50%"
}), _defineProperty(_StyleSheet$create, "h1_3", {
  height: "33.333333%"
}), _defineProperty(_StyleSheet$create, "h2_3", {
  height: "66.666666%"
}), _defineProperty(_StyleSheet$create, "h1_4", {
  height: "25%"
}), _defineProperty(_StyleSheet$create, "h2_4", {
  height: "50%"
}), _defineProperty(_StyleSheet$create, "h3_4", {
  height: "75%"
}), _defineProperty(_StyleSheet$create, "h1_5", {
  height: "20%"
}), _defineProperty(_StyleSheet$create, "h2_5", {
  height: "40%"
}), _defineProperty(_StyleSheet$create, "h3_5", {
  height: "60%"
}), _defineProperty(_StyleSheet$create, "h4_5", {
  height: "80%"
}), _defineProperty(_StyleSheet$create, "h1_6", {
  height: "16.666666%"
}), _defineProperty(_StyleSheet$create, "h2_6", {
  height: "33.333333%"
}), _defineProperty(_StyleSheet$create, "h3_6", {
  height: "50%"
}), _defineProperty(_StyleSheet$create, "h4_6", {
  height: "66.666666%"
}), _defineProperty(_StyleSheet$create, "h5_6", {
  height: "83.333333%"
}), _defineProperty(_StyleSheet$create, "h1_12", {
  height: "8.333333%"
}), _defineProperty(_StyleSheet$create, "h2_12", {
  height: "16.666666%"
}), _defineProperty(_StyleSheet$create, "h3_12", {
  height: "25%"
}), _defineProperty(_StyleSheet$create, "h4_12", {
  height: "33.333333%"
}), _defineProperty(_StyleSheet$create, "h5_12", {
  height: "41.666666%"
}), _defineProperty(_StyleSheet$create, "h6_12", {
  height: "50%"
}), _defineProperty(_StyleSheet$create, "h7_12", {
  height: "58.333333%"
}), _defineProperty(_StyleSheet$create, "h8_12", {
  height: "66.666666%"
}), _defineProperty(_StyleSheet$create, "h9_12", {
  height: "75%"
}), _defineProperty(_StyleSheet$create, "h10_12", {
  height: "83.333333%"
}), _defineProperty(_StyleSheet$create, "h11_12", {
  height: "91.666666%"
}), _defineProperty(_StyleSheet$create, "z0", {
  zIndex: 0
}), _defineProperty(_StyleSheet$create, "z10", {
  zIndex: 10
}), _defineProperty(_StyleSheet$create, "z20", {
  zIndex: 20
}), _defineProperty(_StyleSheet$create, "z30", {
  zIndex: 30
}), _defineProperty(_StyleSheet$create, "z40", {
  zIndex: 40
}), _defineProperty(_StyleSheet$create, "z50", {
  zIndex: 50
}), _defineProperty(_StyleSheet$create, "zAuto", {
  zIndex: 0
}), _StyleSheet$create));
export default t;