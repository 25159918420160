import React from "react";
import { View, Text, Platform, StyleSheet } from "react-native";
import { LinkToPayInfo } from "../components/LinkToPayInfo";
import { LinkToPayResult } from "../components/LinkToPayResult";
import { SafeAreaView, NavigationActions } from "react-navigation";
import { mq } from "../utils/Utility";
import { t, extra } from "../../assets"

export class LinkToPay extends React.Component<{navigation}, {W, M, S, info, linkToPayMode}>{

    total = ""

    constructor(props){
        super(props)
        this.state = {W: false, M: false, S: false, info: undefined, linkToPayMode: 0}
    }

    static navigationOptions = {
        headerStyle: {
         visibility: "hidden",
         height: 0 
        },
    };

    goBack(){
        const backAction = NavigationActions.back();
          this.props.navigation.dispatch(backAction);   
    }

    changeMode(back = false, info?){
        if(info){
            this.setState({info: info})
        }
        if(back){
            this.total = ""
            this.goBack()
        }else{
            if(this.state.linkToPayMode < 1){
                this.setState({linkToPayMode: this.state.linkToPayMode + 1})
            }else{
                this.total = ""
                this.goBack()
            }
        }
    }

    renderLinkToPayInfo(){
        this.total = this.props.navigation.getParam("total")
        const { W, M, S } = this.state;
        return(  
            <LinkToPayInfo style={[]} {...{W, M, S, total: this.total, changeMode: this.changeMode.bind(this)}}></LinkToPayInfo>
        )
    }

    renderLinkToPayResult(){
        const { W, M, S } = this.state;
        return(
           <LinkToPayResult {...{W, M, S, changeMode: this.changeMode.bind(this), data: this.state.info}}></LinkToPayResult>
        )
    }

    render(){
        return(
            <SafeAreaView style={[ e.heightCustom, e.bgCustomLight ]} onLayout={ mq(this) }>
                <View style={[ e.heightCustom, t.bgLightBlue]}>
                    {this.state.linkToPayMode == 0 ?  this.renderLinkToPayInfo() : this.renderLinkToPayResult()}
                </View>
            </SafeAreaView>
        )
    }
}

const e = StyleSheet.create({


    // Custom background external area
    bgCustomLight: {
        backgroundColor: (Platform.OS === 'web') ? "#c9d1d7" : "#e8eef2",
    },

    // Fix height 100% on mobile + web
    heightCustom: {
        height: (Platform.OS === 'web') ? "100vh" : "100%",
    },

    // Custom fixed container
    fixedContainer: { 
        height: (Platform.OS === 'web') ? "90vh" : "100%",
        width: (Platform.OS === 'web') ? "1400px" : "100%",
        margin: (Platform.OS === 'web') ? "auto" : 0,
        marginTop: (Platform.OS === 'web') ? "4.5vh" : 0,
    },
    
    // Fix z-index bottom sheet android 
    elevation0: { elevation: 0 },
    elevation50: { elevation: 50 }

});