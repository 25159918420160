import { AsyncStorage, Platform } from "react-native";
import { environment } from "../environment/envinments";
import { byteArrayToUrl, readAllChunks } from "./Utils";

class ImageService {
    async get(url) {
        return (
            url && {
                uri: url,
                method: "GET",
                headers: {
                    Authorization: `Bearer ${await AsyncStorage.getItem("wp_access_token")}`,
                },
            }
        );
    }
    
    async getAvatar(url){
        if(url && url != ""){
            if(Platform.OS == "web"){
                return fetch(url.includes(environment.api.baseUrl.split("/valid-r2")[0]) ? url : `${environment.api.baseUrl}/api${url}`, {
                    method: 'GET',
                    headers: {
                        'authorization': `Bearer ${await AsyncStorage.getItem("wp_access_token")}`,
                    },
                }
                ).then(res => {return res.body})
                .then(async array => {
                        let img = byteArrayToUrl(await readAllChunks(array))
                        return img
                },
                () => {
                    return require("../../assets/icons/user.png")
                })
            }else{
                return await this.get(url.includes(environment.api.baseUrl.split("/valid-r2")[0]) ? url : `${environment.api.baseUrl}/api${url}`)
            }
        }else{
            return require("../../assets/icons/user.png")
        }
    }       
}




export default new ImageService();