import React, { useLayoutEffect, useState }  from 'react';


import { 
    View, 
    Text, 
    StyleSheet, 
    Platform, 
    Image, 
    Dimensions
} from 'react-native';

import { createAppContainer } from 'react-navigation';
import { createStackNavigator } from 'react-navigation-stack';
import { Login } from './pages/Login'
import { Home } from './pages/Home'
import { Splash } from './pages/Splash'
import { Movements } from './pages/Movements'
import { Settings } from './pages/Settings'
import { LinkToPay } from './pages/LinkToPay'
import { Lists } from './pages/Lists'
import { CBottomDrawer } from './components/bottomDrawer'
import { createBottomTabNavigator, BottomTabBar} from "react-navigation-tabs"
import { t, extra, appIcons } from "../assets"
import {useWindowSize} from "./utils/Utility"

 
const TabNavigator = createBottomTabNavigator(
    Platform.OS == "web" ? 
    {
        cash: Home,
        pay: CBottomDrawer,
        movements: Movements,
        setting: Settings
    } : 
    {
        cash: Home,
        movements: Movements,
        setting: Settings
    },
    {
        defaultNavigationOptions: ({ navigation }) => ({

            tabBarIcon: ({ focused }) => {

                const { routeName } = navigation.state;
                const iconName = appIcons.tabBar[routeName][focused];

                return (
                    <Image source={iconName} style={[ t.w4, t.h4, t.mB1 ]}/>
                );
            },

            tabBarLabel: ({ focused }) => {
                const { routeName } = navigation.state;
                let labelText;

                switch (routeName) {
                    case "cash":
                        labelText = "Home";
                        break;
                    case "movements":
                        labelText = "Movimenti";
                        break;
                    case "setting":
                        labelText = "Impostazioni";
                        break;
                    case "pay":
                        labelText = "Fai pagare";
                        break;
                }
                return (
                    <Text style={[ e.fontMenu, t.fontBd, t.textCenter, t._mT1, t[`text${focused ? "Orange" : "Second"}`]]}>
                        {labelText}
                    </Text>    
                );
            },
            tabBarComponent: props => {
                var [width] = Platform.OS == "web" ? useWindowSize() : "100%"
                return (
                <View style={[ e.marginCustom, e.fixedContainer, 
                {width: (Platform.OS === 'web') ? width < 1400 ? "100%" : "1400px" : "100%"},
                {bottom: (Platform.OS === 'web') ? width < 1400 ? 0 : "5.5vh" : 0},
                ]}>
                    <BottomTabBar
                    {...props} style={[ {height: 63}, t.alignBottom, t.absolute, t.pY1, t.bottom0, 
                    e.borderFix, t.roundedTLg, t.pR1, extra.elevationShadow(5)]} />
                </View>
                )
            },
        }),

        tabBarOptions: { 
            tabStyle: { 
                flexDirection: 'column', 
                alignItems: 'center', 
                justifyContent: 'space-around',
                alignContent: 'center'
            },
        }
    }
);
 

const appNavigator = createStackNavigator(
    {
        Splash: {
            screen: Splash
        },  
        Login: Login,
        Home: {
            screen: TabNavigator,
            navigationOptions: {
                headerStyle: {
                    visibility: "hidden",
                    height: 0 
                },
            }
        },
        LinkToPay: {
            screen: LinkToPay,
            navigationOptions: {
                headerLeft: null,
                headerStyle: {
                    visibility: "hidden",
                    height: 0 
                },
            }
        }
    }, 
    {
        initialRouteName: 'Splash',
    }
);


const e = StyleSheet.create({
    // Fix menu position web + mobile
    marginCustom: { marginTop: (Platform.OS === 'web') ? "100vh" : 0},

    // Fix thin grey border menu iOS
    borderFix: { borderTopColor: "#f0f2f2" },

    // Custom menu font height (standard font6 = 14.6)
    fontMenu: { fontSize: 13.5 },

    // Custom fixed container
    fixedContainer: { 
        margin: (Platform.OS === 'web') ? "auto" : 0,
    },

});


export default createAppContainer(appNavigator)