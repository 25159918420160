import ServiceBase from "./ServiceBase";
import { environment } from "../environment/envinments";


class OrderService extends ServiceBase {

    constructor() {
        super()
    }

    async getHistoricOrders(businessId: number, start: number, end: number){
        return await this.GET(`${environment.api.ecommerceUrl}/businesses/${businessId}/orders/search?page=${start}&size=${end}&sort=creationDate,desc`)
    }
  
    async getPendingOrders(businessId: number, start: number, end: number){
      return await this.GET(`${environment.api.ecommerceUrl}/businesses/${businessId}/orders/search?acknowledged=false&page=${start}&size=${end}&sort=creationDate,desc`)
    }
  
    async getOrderDetail(id: number){
      return await this.GET(`${environment.api.ecommerceUrl}/orders/${id}`);
    }
  
    async getCartDetail(id: number, vasId: number){
      return await this.GET(`${environment.api.ecommerceUrl}/vasses/${vasId}/carts/${id}`);
    }

    async confirmOrder(businessId: number, orderId: number){
        return await this.POST(`${environment.api.ecommerceUrl}/businesses/${businessId}/orders/${orderId}/acknowledge`, {})
    }
}

export default new OrderService()