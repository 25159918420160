import React from 'react';

import { 
    View, 
    Text, 
    Image, 
    StyleSheet, 
    TouchableOpacity,
    Platform,
    DeviceEventEmitter,
    ScrollView,
    TouchableHighlight
} from "react-native";

import { t, extra } from "../../assets"
import { LargeButtonEmpty } from './largeButtonEmpty';
import listController from '../controller/list.controller';
import { vasByName, vasByIcon } from '../utils/Utility';

export class ProductCard extends React.Component<{ W?, M?, S?: boolean, item: any, style?: any },  {visible: any}> {

    constructor(props){
        super(props)
        this.state = {visible: false}
    }

    componentDidMount(){
        this.setState({visible: this.props.item.published})
    }

    async setVisible(){
        this.setState({visible: !this.state.visible})
        await listController.setProductVisibility(this.props.item.id, !this.state.visible)
        this.props.item.published = !this.props.item.published
    }
    
    render(){
        const { W, S, M, item } = this.props
        return(
                <View style={[t.mY1]}>
                    <View style={[ t.flexWrap, t.flexRow, t.wFull, t.pX2 ]}>

                            {
                                this.state.visible && <TouchableOpacity style={[ t.w2_12,  t.itemsStart, t.justifyCenter ]} onPress={() => this.setVisible()}>
                                    <Image source={require('../../assets/icons/visible.png')} style={{width: 33, height: 21}}/>
                                </TouchableOpacity>
                            }
                            {
                                !this.state.visible && <TouchableOpacity style={[ t.w2_12,  t.itemsStart, t.justifyCenter ]} onPress={() => this.setVisible()}>
                                        <Image source={require('../../assets/icons/not_visible.png')} style={{width: 33, height: 21}}/>
                                </TouchableOpacity>
                            }
                        

                        <View style={[ t.w8_12, t.justifyCenter ]}>
                            <Text style={[ t.fontBd, t.textFirst, t.text5 ]}>{item.name}</Text>
                        </View>

                        <View style={[ t.w2_12, t.itemsEnd, t.justifyCenter, t.pR1 ]}>
                            <Text style={[ t.fontBd, t.textFirst, t.text5 ]}>{item.sellingPrice}€</Text>
                        </View>

                    </View>
                    <View style={[ t.flexWrap, t.flexRow, t.wFull, t.mT1, t.pX2 ]}>

                        <View style={[ t.w2_12 ]}>
                        </View>

                        <View style={[ t.w10_12, t.justifyCenter, t.mT1]}>
                            <Text style={[ t.fontRg, t.textSecond ]}>{item.description}</Text>
                        </View>

                    </View>
                    <View style={[ t.wFull, t.borderB1, t.borderDisGrey, {marginTop: 10}, t.mB1 ]}></View>
                </View>
            
        )
    }
}