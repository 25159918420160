import { AsyncStorage } from 'react-native';
import OrderService from '../services/OrderService';
import { OrderAndPay } from '../model/OrderAndPay';
import { asyncForEach } from '../services/Utils';
import TransactionService from '../services/TransactionService';
import { Payment } from '../model/Payment';
import { handleError } from '../utils/Utility';
import ImageService from '../services/ImageService';
import { LinkToPay } from '../model/LinkToPay';

class MovemetsController{

    async getCashInfo(){
        return await AsyncStorage.getItem("wp_merchantDeviceData")
    }

    async getHistoricPayments(page, el, params, logout, props){
        var transaction: Payment[] = []
        var list = {
            transaction: transaction,
            page: {}
        }
        var merchantDeviceInfo = JSON.parse((await this.getCashInfo())!)
        params.merchantDeviceId = merchantDeviceInfo!.deviceInfo.id
        params.shopId = merchantDeviceInfo.deviceInfo.shopId
        return TransactionService.getMerchantP2PTransaction(page, el, params).then(
            async (res) => {
                var data = res && res._embedded ? res._embedded.walletTransactions : [];
                list.page = res && res.page ? res.page : {};
                await asyncForEach(data, async el => 
                    {
                        list.transaction.push(new Payment(el));
                    });
                return list
            },
            (error) => {
                handleError(error, logout, props, "historicPay")
                return list
            }
        )
    }

    async getHistoricLinkToPay(page, el, params, logout, props){
        var transaction: LinkToPay[] = []
        var list = {
            transaction: transaction,
            page: {}
        }
        
        return TransactionService.getLinkToPayTransaction(page, el, params).then(
            async (res) => {
                var data = res && res._embedded ? res._embedded.requestPayments : [];
                list.page = res && res.page ? res.page : {};
                await asyncForEach(data, async el => 
                    {
                        list.transaction.push(new LinkToPay(el));
                    });
                return list
            },
            (error) => {
                handleError(error, logout, props, "historicPay")
                return list
            }
        )
    }
    


    async getHistoricOrders(page, el, logout, props){
        var transaction: OrderAndPay[] = []
        var list = {
            transaction: transaction,
            page: {}
        }
        return this.getCashInfo().then(
            async (values) => {
            return OrderService.getHistoricOrders(JSON.parse(values!).id, page, el).then(
                async (res) => {
                    var data = res._embedded ? res._embedded.orders : [];
                    list.page = res.page ? res.page : {};
                    await asyncForEach(data, async el => 
                        {
                            var order = new OrderAndPay(el, el.customer, JSON.parse(values!).id)
                            order.avatar = await ImageService.getAvatar(order.user._links ? order.user._links.avatar.href : "")
                            list.transaction.push(order);
                        });
                    return list
                },
                (error) => {
                    handleError(error, logout, props, "historicOrder")
                    return list
                }
            )
        })
    }

    async getMovimentAmounts(params){
        var merchantDeviceInfo = JSON.parse((await this.getCashInfo())!)
        params.merchantDeviceId = merchantDeviceInfo!.deviceInfo.id
        params.shopId = merchantDeviceInfo.deviceInfo.shopId
        var res = await TransactionService.getMovementAmounts(params)
        console.log(res)
        return res
    }
}
export default new MovemetsController()