import React from 'react';

import { 
    View, 
    Text, 
    Image, 
    StyleSheet, 
    TouchableOpacity,
    Platform,
    DeviceEventEmitter
} from "react-native";

import { t, extra } from "../../assets"
import { LargeButtonEmpty } from './largeButtonEmpty';
import { OrderAndPay } from '../model/OrderAndPay';
import { vas, orderIcon } from "../utils/Utility"
import Moment from 'moment';
import { Avatar } from './avatar';
import homeController from '../controller/home.controller';
import { OrderDetails } from './orderDetail';
import { CBottomSheet } from './bottomSheet';
import { CAlert } from './alert';
import ImageService from '../services/ImageService';
import { environment } from '../environment/envinments';


export class PendingOrderCard extends React.Component<{ W, M, S: boolean, item: OrderAndPay, refresh: any, _toggleBottomNavigationView: any }, {visible: boolean, modalType: string}> {
    
    refundModal
    modalEvent = "pendingHome";

    constructor(props){
        super(props)
        this.state = {visible: false, modalType: "REFUND"}
    }

    infoStandardCard = {
        pTitle: "Ok",
        page: "Home",
        navigation: "",
        onClick: this.acknowledgeOrder.bind(this),
        value: ""
    }

    openRefundModal(){
        this.setState({modalType: "REFUND"})
        CAlert.showModal({data: {actionFunc: this.refund.bind(this), props: this.props}, type:this.state.modalType}, this.modalEvent)
        Platform.OS == "web" && this.props._toggleBottomNavigationView()
    }

    async refund(){
        this.props.item.refund().then(
            () => {
                this.props.refresh()
                CAlert.dismissModal(this.modalEvent)
                Platform.OS != "web" && this.props._toggleBottomNavigationView()
                this.setState({modalType: "SUCCESS_REFUND"})
                CAlert.showModal({data: {props: this.props}, type:this.state.modalType}, this.modalEvent)
                this.setState({modalType: "REFUND"})
            },
            () => {
                Platform.OS == "web" && this.props._toggleBottomNavigationView()
                CAlert.dismissModal(this.modalEvent)
                this.setState({modalType: "ERROR_REFUND"})
                CAlert.showModal({data: {actionFunc: this.refund.bind(this), props: this.props}, type:this.state.modalType}, this.modalEvent)
                this.setState({modalType: "REFUND"})
            }
        )
        
    }

    renderContentBS({ W, M, S }){
        return(          
            <OrderDetails ack={true} refresh={this.props.refresh} refund={this.openRefundModal.bind(this)} event={"pendingHome"} dismiss={this.props._toggleBottomNavigationView.bind(this)} {...{ W, M, S, item: this.props.item }}></OrderDetails>
        )
    }

    async acknowledgeOrder(){
        await homeController.acknowledgeOrder(this.props.item.businessId, this.props.item.orderId)
        this.props.refresh(true)
    }
 
    render(){

        const { W, M, S } = this.props

        return(

            <View style={[ t.flex, t.flexWrap, t.flexRow,  t.wFull, t.bgWhite, t.rounded, t.mB1, t.p2, 
            extra.elevationShadow(1), e.elevation0, M && [{width: "48%", marginHorizontal: "1%"}, t.mB2], W && [{width: "48%", marginHorizontal: "1%"}, t.mB2] ]}>

                <View style={[ t.w2_12, t.h6, t.flex, t.justifyCenter ]}>
                    <Avatar imageLink={this.props.item.avatar ? this.props.item.avatar : ""}/>
                </View>
                <View style={[ t.w7_12, t.h6, t.flex, t.justifyCenter ]}>
                    <Text style={[ t.fontBd, t.textFirst, t.text5 ]} >{this.props.item.user.contactAlias}</Text>
                    <Text style={[ t.fontRg, t.textSecond ]}>{Moment(this.props.item.timestamp).isSame(Moment(), 'day') ? "OGGI "+Moment(this.props.item.timestamp).format('HH:mm') : Moment(this.props.item.timestamp).format('DD/MM/YYYY HH:mm')}</Text>
                </View>
                <View style={[ t.w3_12, t.h6, t.flex, t.itemsEnd, t.justifyCenter ]}>
                    <Text style={[ t.fontBd, t.textFirst, t.text4  ]}>{this.props.item.amount.toFixed(2).replace(".", ",")}€</Text>
                </View>

                <View style={[ t.wFull, t.borderB1, t.borderDisGrey, {marginTop: 10}, t.mB1 ]}></View>

                <View style={[ t.w2_12, t.h5, t.flex, t.justifyCenter ]}>
                    <Image source={orderIcon[this.props.item.vasType]} style={[ t.w4, t.h4, t.mL1 ]}/>
                </View>
                <View style={[ t.w8_12, t.h5, t.flex, t.justifyCenter ]}>
                    <Text style={[ t.fontBd, t.textSecond, t.text5 ]} >{vas[this.props.item.vasType]}</Text>
                </View>
                <TouchableOpacity style={[ t.w2_12, t.h5, t.itemsEnd, t.justifyCenter ]} onPress={() => this.props._toggleBottomNavigationView(this.renderContentBS.bind(this, {W, M, S}))}>
                    <Image source={require('../../assets/icons/infoDotsCard.png')} style={[ t.w4, t.h4 ]}/>
                </TouchableOpacity>

                <LargeButtonEmpty style={[ t.wFull, t.mT2, t.h5 ]} { ...this.infoStandardCard }></LargeButtonEmpty>
            </View>
        )
    }
}

const e = StyleSheet.create({
    
    // Fix z-index bottom sheet android 
    elevation0: { elevation: 0 },
    elevation50: { elevation: 50 },

});