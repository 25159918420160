import React from "react";
import { 
    View, 
    Text, 
    Image, 
    StyleSheet, 
    TouchableOpacity,
    TouchableHighlight, 
    Platform
} from "react-native";

import { t, extra } from "../../assets"
import Moment from 'moment';
import { CAlert } from './alert';

export class PayDetails extends React.Component<{ W, M, S: boolean, item: any, refresh: any, refund: any, dismiss: any }> {
    
    constructor(props){
        super(props)
    }
    
    render(){
        const { W, M, S, item} = this.props
        return(
            <View style={[ t.flexWrap, t.flexRow,  t.wFull, t.bgWhite, t.roundedT, {padding: 8}, t.pB5, extra.elevationShadow(1), e.elevation0, 
            item.direction == "OUTGOING" && [{borderTopWidth: 5}, t.borderRed], Platform.OS == "ios" && W && [t.w1_2, {marginHorizontal:"25%"}] ]}>
    
                <View style={[ t.w10_12, t.h6, t.p2 ]}>
                    <Text style={[ t.fontBd, t.textFirst, t.text5 ]}>Dettaglio movimento</Text>
                </View>
                <TouchableOpacity style={[ t.w2_12, t.h6, t.justifyCenter, t.itemsEnd, t.pX2 ]} onPress={() => this.props.dismiss()}>
                    <Image source={require('../../assets/icons/iconCross.png')} style={{width: 14, height: 14}}/>
                </TouchableOpacity>

                <View style={[ t.flexWrap, t.flexRow, t.wFull, t.p2 ]}>

                    {
                    /*<View style={[ t.w2_12, t.h6, t.justifyCenter ]}>
                        <Image source={require('../../assets/img/profileSmall.png')} style={[ t.w5, t.h5, t.roundedFull ]}/>
                    </View>*/
                    }
                    <View style={[ t.w9_12, t.h6, t.justifyCenter ]}>
                        <Text style={[ t.fontBd, t.textFirst, item.direction == "OUTGOING" && t.textRed, t.text5 ]}>{item.objectAlias ? item.objectAlias : item.message}</Text>
                        <Text style={[ t.fontRg, t.textSecond ]}>{Moment(item.timestamp).format('DD/MM/YYYY HH:mm')}</Text>
                    </View>
                    <View style={[ t.w3_12, t.h6, t.itemsEnd, t.justifyCenter ]}>
                        <Text style={[ t.fontBd, t.textFirst, t.text4  ]}>{(item.amount+item.fee/100).toFixed(2).replace(".", ",")}€</Text>
                    </View>
                    <View style={[ t.wFull, t.borderB1, t.borderDisGrey, t.mY2 ]}></View>

                    <View style={[ t.wFull, t.h5, t.flexWrap, t.contentStart, t.justifyCenter ]}>

                        <Image source={require('../../assets/icons/faiPagare.png')} style={[ t.w4, t.h4, t.mR3 ]}/>
                        {
                            item.direction == "OUTGOING" && <View style={[ t.justifyCenter, t.itemsCenter, t.mR2, t.textCenter, t.bgRed, t.w10, t.h4, {borderRadius: 6}]}>
                                <Text style={[ t.fontBd, t.textWhite, t.text5 ]}>Rimborso</Text>
                            </View>
                        }
                        {
                            item.direction != "OUTGOING" && <Text style={[ t.fontBd, t.textSecond, item.direction == "OUTGOING" && t.textRed, t.text5]}>Pagamento</Text>
                        }

                    </View> 
         
                    {
                        item.refundable && item.direction != "OUTGOING" && <TouchableHighlight underlayColor="#eff4f6" onPress={() => {this.props.refund()}}
                        style={[ t.roundedFull, t.border1, t.borderMainBlue, t.mT5, t.wFull, {padding: 10}]}>
                            <Text style={[ t.textCenter, t.textMainBlue, t.text5, t.fontBd ]}>Rimborsa</Text>
                        </TouchableHighlight>
                    }     

                </View>

                {   Platform.OS != "web" && <CAlert event={"historicPay"}></CAlert> }
            </View>
        )
    }
}

const e = StyleSheet.create({
    
    // Fix z-index bottom sheet android 
    elevation0: { elevation: 0 },
    elevation50: { elevation: 50 },

});
