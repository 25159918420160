import { AsyncStorage } from 'react-native';
import OrderService from '../services/OrderService';
import { OrderAndPay } from '../model/OrderAndPay';
import PaymentService from '../services/PaymentService';
import { PaymentsRequests } from '../model/PaymentsRequests';
import { asyncForEach } from '../services/Utils';
import moment from 'moment';
import { CAlert } from '../components/alert';
import { handleError, toCents } from '../utils/Utility';
import ImageService from '../services/ImageService';
import { environment } from '../environment/envinments';



class HomeController{

    async getCashInfo(){
        return await AsyncStorage.getItem("wp_merchantDeviceData")
    }

    async getOrders(businessId, page, el, values){
        var orders: OrderAndPay[] = []
        var carts = {
            orders: orders,
            page: {}
        }
        if(!JSON.parse(values!).deviceInfo.standalone) return carts
        return OrderService.getPendingOrders(businessId, page, el).then(
            async (res) => {
                var data = res && res._embedded ? res._embedded.orders : [];
                carts.page = res && res.page ? res.page : {};
                await asyncForEach(data, async el =>
                    {
                    if(el.status != "ORDER_REFUNDED"){
                        var order = new OrderAndPay(el, el.customer, businessId)
                        order.avatar = await ImageService.getAvatar(order.user._links ? order.user._links.avatar.href : "")
                        carts.orders.push(order);
                    }
                });
                return carts
            }
        )
    }

    async getPayments() {
        var pr : PaymentsRequests[] = [];
        return PaymentService.getPendingRequestedPayments().then(
            async (data) => {
                const listPayments = data && data._embedded ? data._embedded.requestPayments : [];
                await asyncForEach(listPayments,
                    async (payment) => {
                            var pay = new PaymentsRequests(payment)
                            pay.avatar = await ImageService.getAvatar(environment.api.baseUrl+"/api"+pay.userAvatar.split("/api")[1])
                            pr.push(pay)
                        }
                    );
                return pr;
            },
            () => {
                return pr;
            }
        )
    }

    async getDataOnlyOrders(page, el, logout, props, event){
        return this.getCashInfo().then(
            async (values) => {
                const businessId = JSON.parse(values!).id;
                return this.getOrders(businessId, page, el, values).then(
                    (orders) => {
                        let sortedList = orders.orders.sort((a,b) => +moment(b.timestamp) - +moment(a.timestamp))
                        return {data: sortedList, pagination: orders.page }
                    },
                    (error) => {
                        handleError(error, logout, props, event)
                        return {data: [], pagination: {} }
                    }


                )

        })
    }

    async getData(page, el, logout, props, event){
        return this.getCashInfo().then(
            async (values) => {
                const businessId = JSON.parse(values!).id;
                return Promise.all([
                    this.getOrders(businessId, page, el, values),
                    this.getPayments()
                ]).then(
                    ([orders, payments]) => {
                        let sortedList = [...orders.orders, ...payments].sort((a,b) => +moment(b.timestamp) - +moment(a.timestamp))
                        return {data: sortedList, pagination: orders.page }
                    },
                    (error) => {
                        handleError(error, logout, props, event)
                        return {data: [], pagination: {} }
                    }
                )
        })
    }

    async generatePayment(id, amount, logout, props, ev) {
        console.log("On generate Payment")
        return PaymentService.generateNewPurchaseWithId(id, toCents(amount)).then(
           (res) => {return res},
           (error) => {
                handleError(error, logout, props, ev);
           }
       )
    }

    async checkPurchase(purchaseId, logout, props, ev){
        return PaymentService.checkPurchase(purchaseId).then(
            (data) => {
                return data.eventType
            },
            (error) => {
                handleError(error, logout, props, ev);
            }
        )
    }

    acknowledgeOrder(businessId, orderId){
        return OrderService.confirmOrder(businessId, orderId)
    }

    acknowledgePayment(id){
        return PaymentService.hideRequestPayement(id)
    }

    refusePayment(id){
        return PaymentService.deleteRequestedPayment(id)
    }
}

export default new HomeController()
