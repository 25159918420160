import React from 'react';

import {
  Text,
  View,
  SafeAreaView,
  ScrollView,
  StyleSheet,
  YellowBox,
  Platform,
  Animated,
  NativeScrollEvent,
  ActivityIndicator,
  DeviceEventEmitter,
} from 'react-native';

import { t, extra } from "../../assets"
import { mq, isCloseToBottom, diffCard, handleError } from '../utils/Utility';
import { HomePayBD } from '../components/HomePayBD';
import { TopBar } from '../components/topBar';
import homeController from '../controller/home.controller';
import { PendingOrderCard } from '../components/pendingOrderCard';
import { PendingPaymentCard } from '../components/pendingPaymentCard';
import { OrderAndPay } from '../model/OrderAndPay';
import { NavigationEvents } from 'react-navigation';
import moment from 'moment';
import { NewOrderPayModal } from '../components/newOrderPayModal';
import { CAlert } from '../components/alert';
import { CBottomSheet } from '../components/bottomSheet';
import loginController from '../controller/login.controller';


// Disable specific touch warnings
YellowBox.ignoreWarnings(['`-[RCTRootView cancelTouches]`'])   


export class Home extends React.Component<{}, { WW, W, M, S: boolean, list: any, listEmpty: boolean, pagination: any, scrollY: any, isLoading: boolean, newElements: any, visible: boolean, renderContent: any }> {

    page
    el
    orderReloadListener
    currentList
    firstSubscribtion
    newElementsModal
    isLoad
    interval;

    constructor(navigation: any){
        super(navigation)
        this.state = { WW: false, W: false, M: false, S: false, list: [], listEmpty: true, pagination: {}, scrollY: new Animated.Value(0), isLoading: false, newElements: [], visible: false, renderContent: () => {} };
        this.el = Platform.OS == 'web' ? 14 : 5
        this.currentList = []
        this.firstSubscribtion = true
        this.newElementsModal = []
        this.isLoad = false
        DeviceEventEmitter.addListener("force-logout", () => {
            loginController.logout(this.props)
        })
    }

    static navigationOptions = {
        headerStyle: {
         visibility: "hidden",
         height: 0 
        },
    };

    componentDidMount(){
        this.page = 0;
        this.orderReloadListener = DeviceEventEmitter.addListener("orders:reload", () => {if(!this.isLoad) this.getOrderPayList(false)})
        if(Platform.OS == "web"){
            this.interval = setInterval(() => {
                this.getOrderPayList()
            }, 10000);
        }
    }

    componentWillUnmount(){
        this.orderReloadListener.remove()
        Platform.OS == "web" && clearInterval(this.interval)
    } 
    
    _toggleBottomNavigationView = (renderContentBS, eventType, acknowledgePayment) => {
        
        if(eventType == undefined) this.setState({ visible: !this.state.visible });

        if(eventType == "BILL_REQUEST"){
            this.setState({ visible: !this.state.visible });
        }else if(eventType == "PAYMENT_SUCCESS") {
            acknowledgePayment()
        }  

        if(renderContentBS && typeof renderContentBS == "function"){
            this.setState({ renderContent: renderContentBS })
        }
    };


    async getOrderPayList(ack?){
        this.page = 0;
        this.isLoad = true
        //this.setState({newElements: []})
        homeController.getData(this.page, this.el, this.logout.bind(this, this.props), this.props, "pendingHome").then(
            (res) => {
                if(!this.firstSubscribtion && !ack){
                    const currentNewElements = this.state.newElements;
                    const newElements = diffCard(res.data as [], this.currentList);
                    this.setState({newElements: currentNewElements.concat(newElements)})
                 }else{
                     this.firstSubscribtion = false
                 }
                 this.currentList = res.data;
                 this.setState({list: res.data, pagination: res.pagination, listEmpty: this.currentList.length == 0})
                 this.isLoad = false
            },
        )
       
    }

    async loadMore(){
        if(this.page + 1 < this.state.pagination.totalPages){
            this.setState({isLoading:true})
            this.page = this.page + 1
            var currentList = this.state.list
            var res = await homeController.getDataOnlyOrders(this.page, this.el, this.logout.bind(this, this.props), this.props, "pendingHome")
            var newList = [...currentList, ...res.data].sort((a,b) => +moment(b.timestamp) - +moment(a.timestamp))  
            this.setState({list: newList, pagination: res.pagination, isLoading: false})
        }
    }

    async logout(props){
        loginController.logout(props)
    } 

    renderNewElementModals({ W, M, S }){
        return (
            this.state.newElements.map((el, i) => {        
                    return <NewOrderPayModal key={i} refresh={this.getOrderPayList.bind(this)} onRef={ref => {this.newElementsModal.push(ref)}} {...{ W, M, S, item: el }}></NewOrderPayModal>
            })
        )     
    }
 
    render(){
        const { WW, W, M, S } = this.state;

        return (
            <SafeAreaView style={[ e.heightCustom, e.bgCustomLight ]} onLayout={ mq(this) }>
                <View style={[ e.heightCustom, t.bgLightBlue, WW && [e.fixedContainer] ]}>

                    <NavigationEvents
                        onDidFocus ={() => {
                            this.page = 0
                            this.getOrderPayList()
                        }}
                    />

                    <ScrollView contentContainerStyle={[t.flex, t.flexWrap, t.flexRow, t.pB13, M && { marginHorizontal: "1%" }, W && {marginHorizontal: "3%"}, WW && {marginHorizontal: "3%"} ]}
                    scrollEventThrottle={16}
                    bounces={false}
                    onMomentumScrollEnd={({nativeEvent}) => {
                        if (isCloseToBottom(nativeEvent) && !this.state.isLoading) { 
                            this.loadMore()
                        }
                    }} onScroll={Animated.event(
                        [{ nativeEvent: { contentOffset: { y: this.state.scrollY } } }],
                        {
                            listener: (event : any) => {
                                if (isCloseToBottom(event.nativeEvent) && !this.state.isLoading && Platform.OS == "web") {
                                    this.loadMore()
                                }
                            }
                        }
                    )}>

                        <TopBar></TopBar>

                        {
                            this.state.list.map((el, i) => {
                                if(el instanceof OrderAndPay){
                                    return <PendingOrderCard key={i} refresh={this.getOrderPayList.bind(this)} _toggleBottomNavigationView={this._toggleBottomNavigationView.bind(this)} {...{ W, M, S, item: el }}/>
                                } else {
                                    return <PendingPaymentCard key={i} refresh={this.getOrderPayList.bind(this)} _toggleBottomNavigationView={this._toggleBottomNavigationView.bind(this)} {...{ WW, W, M, S, item: el }}/> 
                                }
                            })
                        }
                        {
                            this.state.isLoading && <View style={[t.wFull, t.justifyCenter, t.itemCenter]}>
                                <ActivityIndicator size="large" color="#6b7075"></ActivityIndicator>
                            </View>
                        }
                    </ScrollView>
                    
                    {
                        this.renderNewElementModals({W, M, S})
                    }
                    { Platform.OS == "web" && <CAlert event={"pendingHome"}></CAlert>}
                    <CBottomSheet { ...{visible: this.state.visible, _toggleBottomNavigationView: this._toggleBottomNavigationView, renderContent: this.state.renderContent} }></CBottomSheet>
                    { (Platform.OS !== "web") && this.state.listEmpty &&  <HomePayBD style={[ e.elevation50 ]} startUp = {true} {...this.props}></HomePayBD> }
                    { (Platform.OS !== "web") && !this.state.listEmpty &&  <HomePayBD style={[ e.elevation50 ]} startUp = {false} {...this.props}></HomePayBD> }

                </View>
            </SafeAreaView> 
        )
    }
}


const e = StyleSheet.create({


    // Custom background external area
    bgCustomLight: {
        backgroundColor: (Platform.OS === 'web') ? "#c9d1d7" : "#e8eef2",
    },

    // Fix height 100% on mobile + web
    heightCustom: {
        height: (Platform.OS === 'web') ? "100vh" : "100%",
    },

    // Custom fixed container
    fixedContainer: { 
        height: (Platform.OS === 'web') ? "90vh" : "100%",
        width: (Platform.OS === 'web') ? "1400px" : "100%",
        margin: (Platform.OS === 'web') ? "auto" : 0,
        marginTop: (Platform.OS === 'web') ? "4.5vh" : 0,
    },
    
    // Fix z-index bottom sheet android 
    elevation0: { elevation: 0 },
    elevation50: { elevation: 50 }

});