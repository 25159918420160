import React from "react";
import {
    View,
    Text,
    Image,
    StyleSheet,
    TouchableOpacity,
    TouchableHighlight,
    Platform,
    TextInput,
    Clipboard,
    Share
} from "react-native";

import { t, extra } from "../../assets"
import Moment from 'moment';
import { CAlert } from './alert';
import { NumericKeyboard } from "./numericKeyboard";
import { toCents } from '../utils/Utility';


interface IState {
    PRMode: boolean
    insertedAmount: any
    errorPR: boolean
    refundedTotal: any
}

export class LinkToPayDetail extends React.Component<{ W, M, S: boolean, item: any, refresh: any, refund: any, dismiss: any, partiallyRefund: any }, IState> {

    modalEvent = "historicLinkDetail";
    value = ""
    baseValue: string = "000";

    constructor(props){
        super(props)
        this.state = {PRMode: false, insertedAmount: "", errorPR: false, refundedTotal: 0}
    }

    componentDidUpdate(prevProp){
        if(prevProp.item.refundedAmount != this.props.item.refundedAmount){
            this.setState({refundedTotal: this.props.item.refundedAmount ? this.props.item.refundedAmount : 0})
        }
    }

    writeToClipboard = async () => {
        await Clipboard.setString(this.props.item.linkToPayUrl);
    };

    renderStatusTag(item){
        var style
        var label
        var partialRefunded = false

        switch (item.status) {
            case "EXPIRED":
                style = [t.bgDarkGrey]
                label = "SCADUTO"
                break;
            case "PAYMENT":
                style = [{backgroundColor: "#EFAA07"}]
                label = "PENDING"
                break;
            case "REFUND":
                style = [t.bgRed]
                label = "RIMBORSATO"
            break;
            case "PAYMENT_SUCCESS":
                if(this.state.refundedTotal && this.state.refundedTotal > 0 && this.state.refundedTotal < item.amount ){
                    style = [t.border2, t.borderRed]
                    label = "RIMBORSATO PARZIALMENTE"
                    partialRefunded = true
                }else{
                    style = [t.bgGreen]
                    label = "PAGATO"
                }
            break;
        }

        return (
            <View style={[t.p1, t.rounded, style]}>
                <Text style={[t.fontBold, {fontSize: 11}, !partialRefunded ? t.textWhite : t.textRed]}>{label}</Text>
            </View>
        )
    }

    confirmPayTotal() {
        const item = this.props.item
        var refundedAmount = 0
        if(this.state.refundedTotal){
            refundedAmount = this.state.refundedTotal
        }
        if(toCents(this.state.insertedAmount) <= (Number(item.amount) - refundedAmount)){
            this.props.partiallyRefund(this.state.insertedAmount, this.reset.bind(this))
            var newRefundedTotal =  toCents(this.state.insertedAmount) + refundedAmount
            this.setState({refundedTotal: newRefundedTotal})

        }else{
            this.setState({errorPR: true})
        }
    }

    reset(){
        this.setState({PRMode: false, insertedAmount: "", errorPR: false});
        this.value = ""
        this.baseValue = "000";
    }

    onKeyPress(number){
        if(number == "Backspace"){
            if(this.baseValue != "000"){
                this.baseValue = this.value.length <= 3 && this.value.length > 0  ? this.baseValue + "0": "";
                this.value = this.value.slice(0, -1);
            }
        }else{
            if((number == "0" || number == "00") && this.baseValue == "000") { return }
            if(this.value.length < 9){
                this.baseValue = this.value.length <= 2 ? this.baseValue.slice(0, -number.length): "";
                this.value += number;
            }
        }
        var total = this.baseValue + this.value;
        this.setState({insertedAmount: (Number(total)/100).toFixed(2)})
    }

    renderContentPartRefund(){
        const {W, M, S, item} = this.props
        return (
            <View style={[t.itemsCenter, W && t.hFull, t.wFull, t.p2, t.bgWhite, t.roundedT]}>
                <View style={[t.flexRow, t.wFull, t.itemsCenter]}>
                    <Text style={[t.w11_12, t.textCenter, Platform.OS == "web" && t.mL5, t.text5, t.textSecond, t.fontBd]}>Rimborsa parzialmente questo ordine</Text>
                    <TouchableOpacity style={[ t.w1_12, t.h6, t.justifyCenter, t.itemsEnd ]} onPress={() =>
                    {
                        this.reset()
                        this.props.dismiss()
                    }}>
                        <Image source={require('../../assets/icons/iconCross.png')} style={[{width: 14, height: 14}, t.mR1]}/>
                    </TouchableOpacity>
                </View>
                <View style={[t.wFull, t.itemsCenter]}>
                    <Text style={[t.text5, t.fontBd, t.textSecond]}>SALDO RIMANENTE </Text>
                    <Text style={[t.text5, t.fontBd, t.textSecond]}>€{item ? (Number(item.amount)/100 - Number(this.state.refundedTotal)/100).toFixed(2) : ""}</Text>
                </View>

                <View style={[t.h1, t.borderT1, t.w10_12, t.borderDisGrey, t.mT1]}></View>

                <View style={[t.mY2, t.itemsCenter]}>

                    <Text style={[t.text6, t.textDarkGrey, t.fontRg]}>Inserisci l'importo da rimborsare*</Text>

                    <View style={[t.flexRow, t.w8_12, t.border1, t.borderDarkGrey, t.itemsCenter, t.mT1]}>
                        <View style={[t.w5_12, t.itemsEnd]}>
                            <Text style={[t.text4, t.fontBd, t.textSecond]}>€ </Text>
                        </View>
                        <View style={[t.w7_12]}>
                            <TextInput
                                style={[t.text4, t.fontBd, t.textMainBlue]}
                                onChange={({nativeEvent}) => {
                                    if(nativeEvent.text.length > this.state.insertedAmount.length){
                                        this.onKeyPress(nativeEvent.text.charAt(nativeEvent.text.length-1))
                                    }else{
                                        this.onKeyPress("Backspace")
                                    }
                                }}
                                keyboardType={"numeric"}
                                placeholder={"0.00"}
                                placeholderTextColor={"#236A95"}
                                value={this.state.insertedAmount}
                            >
                            </TextInput>
                        </View>
                    </View>
                    <View style={[t.wFull, t.itemsCenter, t.mT1]}>
                        { this.state.errorPR && <Text style={[t.text5, t.fontRg, t.textRed]}>L'importo inserito è superiore al totale rimborsabile</Text>}
                    </View>
                </View>

                <View style={[t.h1, t.borderT1, t.w10_12, t.borderDisGrey, t.mT1]}></View>

                <View style={[t.wFull, t.itemsCenter]}>
                    <TouchableHighlight
                        disabled={!this.state.insertedAmount || this.state.insertedAmount == "0.00" }
                        onPress={() => {
                            this.confirmPayTotal();}}
                        style={[ t.roundedFull, t.bgWhite, t.border1, t.borderRed, t.justifyCenter, t.mT2, W && [{padding: 10, width: "48%"}], !W && t.h6]}>
                            <Text style={[ t.textCenter, t.textRed, t.text5, t.fontBd, t.pX2 ]}>Conferma rimborso parziale</Text>
                    </TouchableHighlight>
                </View>

            </View>
        )
    }

    renderDetail(){
        var web = Platform.OS == "web"
        const {W, M, S, item} = this.props
        var gmt = Moment(item.expireDate).utcOffset() > 0 ?  "+"+Moment(item.expireDate).utcOffset()/60 : Moment(item.expireDate).utcOffset()/60
        return(
            <View style={[ t.flexWrap, t.flexRow,  t.wFull, t.bgWhite, t.roundedT, {padding: 8}, t.pB5, extra.elevationShadow(1), e.elevation0,
                Platform.OS == "ios" && W && [t.w1_2, {marginHorizontal:"25%"}] ]}>

                <View style={[ t.w10_12, t.h6, t.p2 ]}>
                    <Text style={[ t.fontBd, t.textFirst, t.text5 ]}>Dettaglio movimento</Text>
                </View>
                <TouchableOpacity style={[ t.w2_12, t.h6, t.justifyCenter, t.itemsEnd, t.pX2 ]} onPress={() => {this.props.dismiss()}}>
                    <Image source={require('../../assets/icons/iconCross.png')} style={{width: 14, height: 14}}/>
                </TouchableOpacity>

                <View style={[ W && [t.flexWrap, t.flexRow], t.wFull, t.p2 ]}>

                    <View style={[ W && t.w1_2, t.h6]}>
                        <Text style={[ t.fontBd, t.textFirst, t.text5 ]}>RIFERIMENTO PAGAMENTO</Text>
                            <Text style={[ t.fontBd, t.textSecond, t.text6 ]}>{item.productCodeId}</Text>
                    </View>
                    <View style={[ W && t.w1_2]}>
                        <Text style={[ t.fontBd, t.textFirst, t.text5 ]}>DESCRIZIONE</Text>
                        <Text style={[ t.fontBd, t.textSecond, t.text6  ]}>{item.description}</Text>
                    </View>

                    <View style={[ W && t.w1_2, t.h6, W && t.mT2, S && t.mT1 ]}>
                        <Text style={[ t.fontBd, t.textFirst, t.text5 ]}>IMPORTO</Text>
                        <Text style={[ t.fontBd, t.textSecond, t.text6 ]}>€{(item.amount/100).toFixed(2).replace(".", ",")}</Text>
                    </View>
                    <View style={[ W && t.w1_2, t.h6, W && t.mT2 ]}>
                        <Text style={[ t.fontBd, t.textFirst, t.text5 ]}>VALIDITA' LINK</Text>
                        <Text style={[ t.fontBd, t.textSecond, t.text6 ]}>fino alle ore {Moment(item.expireDate).format('HH:mm')} di {Moment(item.expireDate).format('dddd DD/MM/YYYY')} (GMT {gmt})</Text>
                    </View>

                    <View style={[t.flexRow, t.itemsCenter, t.mT2, t.wFull]}>
                        <View style={[t.w10_12]}>
                            <Text style={[t.fontBd, t.textOrange, t.text6]}>{item.linkToPayUrl}</Text>
                        </View>
                        <View style={[t.flexRow, t.w2_12]}>
                        <TouchableOpacity
                            onPress={() => {this.writeToClipboard()}}
                            style={[web && [t.itemsEnd, t.wFull], !web && t.w1_2]}
                        >
                            <Image style={[t.w3, t.h3]} source={require('../../assets/icons/copy.png')}></Image>
                        </TouchableOpacity>
                        {
                            !web && <TouchableOpacity
                                        onPress={async () => {
                                            await Share.share({message: item.linkToPayUrl})
                                        }}
                                        style={[t.w1_2]}
                                    >
                                <Image style={[t.w3, t.h3]} source={require('../../assets/icons/share.png')}></Image>
                            </TouchableOpacity>
                        }

                        </View>
                    </View>

                    <View style={[ t.wFull, t.borderB1, t.borderDisGrey, t.mY2 ]}></View>

                    <View style={[ t.wFull, t.h5, t.flexWrap, t.contentStart, t.justifyCenter ]}>

                        <View style={[t.w4, t.h4, t.mR3, t.itemsCenter]}>
                            <Image  source={require('../../assets/icons/ltp-icon.png')} style={[ {width: 24}, t.h3 ]}/>
                        </View>

                        {
                            this.renderStatusTag(item)
                        }

                        <Text style={[ t.fontBd, t.textSecond, t.text5, t.mL3]}>LinkToPay</Text>


                    </View>

                    <View style={[ t.wFull, t.borderB1, t.borderDisGrey, t.mT2 ]}></View>

                    {
                        item.status == "PAYMENT_SUCCESS" && this.state.refundedTotal > 0 && this.state.refundedTotal < item.amount &&
                        <View style={[t.mT2, t.wFull]}>
                            <View style={[t.flexRow, t.mT1]}>
                                <Text style={[t.w10_12, t.fontBd, t.textFirst, t.text6]}>IMPORTO RICEVUTO</Text>
                                <View style={[t.w2_12, t.itemsEnd]}>
                                    <Text style={[t.fontRg, t.textFirst, t.text6]}>{(item.amount/100).toFixed(2).replace(".", ",")}</Text>
                                </View>
                            </View>
                            <View style={[t.flexRow, t.mT1]}>
                                <Text style={[t.w10_12, t.fontBd, t.textFirst, t.text6]}>RIMBORSATO PARZIALMENTE</Text>
                                <View style={[t.w2_12, t.itemsEnd]}>
                                    <Text style={[t.fontRg, t.textFirst, t.text6]}>{(this.state.refundedTotal/100).toFixed(2).replace(".", ",")}</Text>
                                </View>
                            </View>
                            {
                                item.refundable && <View style={[t.flexRow, t.mT2]}>
                                    <Text style={[t.w10_12, t.fontBd, t.textFirst, t.text5]}>SALDO RIMANENTE</Text>
                                    <View style={[t.w2_12, t.itemsEnd]}>
                                    <Text style={[t.fontBd, t.textFirst, t.text5]}>{((item.amount - this.state.refundedTotal)/100).toFixed(2).replace(".", ",")}</Text>
                                    </View>
                                </View>
                            }
                        </View>
                    }

                    {
                        item.status == "PAYMENT_SUCCESS" && this.state.refundedTotal < item.amount && item.refundable &&
                        <View style={[ W && [t.flexRow, t.flexWrap], t.wFull]}>
                            <TouchableHighlight  onPress={() => {this.props.refund()}}
                            style={[ t.roundedFull, t.bgRed, t.justifyCenter, t.mT2, W && [{padding: 10, width: "48%"}], !W && t.h6]}>
                                <Text style={[ t.textCenter, t.textWhite, t.text5, t.fontBd ]}>Rimborsa</Text>
                            </TouchableHighlight>


                            <TouchableHighlight onPress={() => {
                                this.setState({PRMode: true})
                            }}
                            style={[ t.roundedFull, t.border2, t.borderRed, t.mT2, t.justifyCenter, W && [t.mL2, {padding: 10, width: "48%"}], !W && t.h6]}>
                                <Text style={[ t.textCenter, t.textRed, t.text5, t.fontBd ]}>Rimborsa parzialmente</Text>
                            </TouchableHighlight>
                        </View>
                    }


                </View>
            </View>
        )
    }

    render(){
        return(
                <View style={[t.wFull]}>
                {
                    this.state.PRMode ? this.renderContentPartRefund() : this.renderDetail()
                }
                {   Platform.OS != "web" && <CAlert event={"historicLinkDetail"}></CAlert> }
                </View>
        )
    }
}

const e = StyleSheet.create({

    // Fix z-index bottom sheet android
    elevation0: { elevation: 0 },
    elevation50: { elevation: 50 },

});
