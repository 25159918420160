import React, { Component } from 'react'
import { 
    View, 
    Text, 
    ActivityIndicator, 
    TouchableHighlight,
    SafeAreaView,
    Image, 
    StyleSheet, 
    Platform,
    Dimensions,
    TouchableOpacity,
    ScrollView
} from 'react-native';

import homePayBDController from '../controller/homePayBD.controller';
import { CAlert } from './alert';
import { TopBar } from '../components/topBar'
import { NumericKeyboard } from './numericKeyboard';
import { t } from "../../assets"
import { mq } from '../utils/Utility';
import homeController from '../controller/home.controller';
import loginController from '../controller/login.controller';
import { LinkToPayInfo } from './LinkToPayInfo';
import { LinkToPayResult } from './LinkToPayResult';

export class CBottomDrawer extends React.Component<{}, { WW, W, M, S: boolean, inTransition: boolean, qrcode: any, payId: any, amount: any, modalType: any, linkToPayMode: number, info: any}> {
    
    interval: any
    modal:any 
    modalEvent = "homePayBD"
    total = ""

    constructor(props){
        super(props)
        this.state = { WW: false, W: false, M: false, S: false, info: undefined, inTransition: false, qrcode: "", payId: -1, amount: "", modalType: "DEFAULT", linkToPayMode: 0 };
    }

    confirmPayTotal(total){
        this.generatePayment(total)
    }

    componentWillUnmount(){
        clearInterval(this.interval)
    }
    
    async generatePayment(total){
        var result = await homePayBDController.generatePayment(total, this.logout.bind(this, this.props), this.props, this.modalEvent)
        if(result && result.id){
            this.setState({qrcode: result.qrCode, payId: result.id, inTransition: true, amount: total})  
        
        this.interval = setInterval(() => {
                homePayBDController.checkPurchase(result.id, this.logout.bind(this, this.props), this.props, this.modalEvent).then(
                    (res) => {
                        if(res.eventType != "LET_PAY" && res.eventType != "PAYMENT_STARTED"){
                            this.setState({inTransition: false, modalType: res.eventType})
                            clearInterval(this.interval)
                            CAlert.showModal({data: {props: this.props, content: res}, type:this.state.modalType}, this.modalEvent)
                        }
                    },
                    (error) => console.log(error)
                )
            }, 2000);  
        }
    }

    async logout(props){
        loginController.logout(props)
    } 

    async refusePayment(){
        homeController.refusePayment(this.state.payId).then(
            () => { 
                this.setState({inTransition: false})
                clearInterval(this.interval) 
            },
            (error) => { 
            }
        )  
    }

    renderLinkToPayInfo(W, M, S){
        return(  
            <LinkToPayInfo style={[]} {...{W, M, S, total: this.total, changeMode: this.changeMode.bind(this)}}></LinkToPayInfo>
        )
    }

    changeMode(back = false, info?){
        if(info){
            this.setState({info: info})
        }
        if(back){
            this.total = ""
            this.setState({linkToPayMode: this.state.linkToPayMode - 1}) 
        }else{
            if(this.state.linkToPayMode < 2){
                this.setState({linkToPayMode: this.state.linkToPayMode + 1})
            }else{
                this.total = ""
                this.setState({linkToPayMode: 0})
            }
        }
    }

    renderLinkToPayResult(W, M, S){
        return(
           <LinkToPayResult {...{W, M, S, changeMode: this.changeMode.bind(this), data: this.state.info}}></LinkToPayResult>
        )
    }

    renderMainView( W, M, S){
        return (
            <View style={[t.h11_12, t.pB6]}>
                {
                    this.renderKeypadBS( W, M, S)
                } 
                <View style={[t.mT3, t.itemsCenter]}>                   
                    <TouchableOpacity onPress={() => {this.changeMode()}}>
                        <Text style={[t.text5, t.fontBold, t.textSecond, t.textCenter, t.underline]}>Oppure crea un link di pagamento</Text> 
                    </TouchableOpacity>   
                </View>
            </View>
        )
    }

    onTotalChange(amount){
        this.total = amount
    }

    renderKeypadBS( W, M, S ){ 

        return (
            <View style={[ t.hFull, t.bgWhite, t.roundedLg, t.border2, t.borderDisGrey, t.pB2, M && [t.border0], W && [t.border0] ]}>
                <NumericKeyboard {...{confirmTotal: this.confirmPayTotal.bind(this), onTotalChange: this.onTotalChange.bind(this), idPayment: undefined, showConfirmBtn: true}}></NumericKeyboard>
            </View>
        )           
    }

    renderPayQrBS( W, M, S ){

        return (
            <View style={[ t.hFull, t.border2, t.borderDisGrey ]}>
                <View style = {[ t.itemsCenter ]}> 
                    <Text style={[t.text2, t.fontBold, t.textMainBlue, t.mY2, W && [t.text1_2, {marginVertical: "2%"}] ]}>{this.state.amount} €</Text>

                    <View style={[t.mT3, t.flexRow, t.mY2]}>
                        <ActivityIndicator size="small" color="#6b7075" />
                        <Text style={[t.text5, t.fontRegular, t.textSecond, t.mL3, W && t.text3 ]}>Transazione in corso</Text>
                    </View>

                    <Image style={{width: 220, height: 220}} source={{uri: `data:image/png;base64,${this.state.qrcode}`}} />
                    <Text style={[t.text5, t.fontRegular, t.textSecond, t.textCenter, t.mT4, W && t.text4 ]}>Puoi mostrare questo QR code {"\n"}per il pagamento</Text>
                    <TouchableHighlight underlayColor="#fafbfc" style={[ t.border1, e.customBorderColor, t.roundedFull, t.mT4, {width: 200, padding: 6}, W && {marginTop: "6%"} ]} onPress={() => this.refusePayment()}>
                        <Text style={[ t.textCenter, t.textSecond, t.text6, t.fontRegular, W && t.text5 ]}>Annulla</Text>
                    </TouchableHighlight>
                </View>
            </View>
        )
    }
    
    render() {

        const { WW, W, M, S } = this.state;

        var heightKeysWeb = (Dimensions.get('window').height) - 170    //170 = topBar + bottom menu
        var heightKeysLLWeb = (Dimensions.get('window').height);
        var heightKeysWebCont = (Dimensions.get('window').height * 0.89) - 170;   //0.9 = height container(90vh), slightly reduced
        var marginWidthWeb = (Dimensions.get('window').width - heightKeysWeb) / 2 - 25;
        var marginWidthLLWeb = (Dimensions.get('window').width - heightKeysLLWeb) / 2 - 300;
        var marginWidthWebCont = (1400 - heightKeysWebCont) / 2 - 75;
        var marginWidthLLWebCont = (1400 - heightKeysWebCont) / 2 - 300;

        return(

            <SafeAreaView style={[ e.heightCustom, e.bgCustomLight ]} onLayout={ mq(this) }>
                
                    <View style={[ e.heightCustom, t.bgLightBlue, WW && [e.fixedContainer] ]}>
                        
                        <ScrollView contentContainerStyle={[ t.pB13, M && { marginHorizontal: "1.05%" }, W && { marginHorizontal: "3.05%" } ]}>
                            <View style={[ M && { paddingHorizontal: "1%" }, W && { paddingHorizontal: "3%" } ]}>

                                <CAlert event={this.modalEvent}></CAlert>
                                <TopBar></TopBar>
                                <View style={[{ 
                                        marginHorizontal: this.state.linkToPayMode == 0 ? marginWidthWeb : marginWidthLLWeb, 
                                        height: this.state.linkToPayMode == 0 ? heightKeysWeb : heightKeysLLWeb }, 
                                        WW && { marginHorizontal: this.state.linkToPayMode == 0 ? marginWidthWebCont : marginWidthLLWebCont, 
                                        height: heightKeysWebCont 
                                    }]}>
                                    {this.state.inTransition ? this.renderPayQrBS(W, M, S) : this.state.linkToPayMode == 0 ? this.renderMainView(W, M, S) : this.state.linkToPayMode == 1 ? this.renderLinkToPayInfo(W, M, S) : this.renderLinkToPayResult(W, M,S)}
                                </View>
                            </View> 
                        </ScrollView>
                        
                    </View>
                
            </SafeAreaView> 
        )  
    }
}

const e = StyleSheet.create({


    // Custom background external area
    bgCustomLight: {
        backgroundColor: (Platform.OS === 'web') ? "#c9d1d7" : "#e8eef2",
    },

    // Fix height 100% on mobile + web
    heightCustom: {
        height: (Platform.OS === 'web') ? "100vh" : "100%",
    },

    // Custom fixed container
    fixedContainer: { 
        height: (Platform.OS === 'web') ? "90vh" : "100%",
        width: (Platform.OS === 'web') ? "1400px" : "100%",
        margin: (Platform.OS === 'web') ? "auto" : "0",
        marginTop: (Platform.OS === 'web') ? "4.5vh" : "0",
    },

    // Custom border color to match inside text
    customBorderColor: { borderColor: "#859aa7" },

    
    
    dialogSwiper: {
        width: 70,
        height: 5,
        borderRadius: 2.5,
        borderColor: "#a4a4a4",
        marginTop: 10,
        marginBottom: 5,
    }

});