import React, { Component } from 'react'
import { View, Text, TouchableOpacity, Clipboard } from 'react-native'
import { t } from "../../assets"
import { QrCode } from '../components/qrcode';


export class LinkToPayResult extends React.Component<{W, M, S, changeMode: any, data:any}> {

    constructor(props){
        super(props)
    }

    writeToClipboard = async () => {
        await Clipboard.setString(this.props.data.linkTopayUrl);
      };

    render() {
        var {W, M, S, data} = this.props
        return (
            <View style={[t.wFull, t.h10_12]}>
                <View style={[t.h11_12, t.bgWhite, t.p4, t.itemsCenter, t.justifyCenter, t.roundedLg]}>
                    <View style={[t.itemsCenter, t.justifyCenter]}>
                        <Text style={[t.text3, t.fontBold, t.textDarkGrey]}>Il tuo Link to Pay è stato generato correttamente:</Text>
                        <Text style={[t.text3, t.fontBold, t.textOrange, t.mB5]}>{data.linkTopayUrl}</Text>
                        <QrCode value={data.linkTopayUrl? data.linkTopayUrl : "NA"} size={130}/>
                    </View>


                    <View style={[t.flexRow, t.w8_12, t.flexWrap]}>
                        <View style={[t.flexCol, t.itemsCenter, t.w1_2, t.mT4]}>
                            <View style={[t.itemsCenter]}>
                                <Text style={[s.label]}>
                                    RIFERIMENTO PAGAMENTO
                                </Text>
                                <Text style={[s.text]}>
                                    {data.info.productCodeId}
                                </Text>
                            </View>
                            <View style={[ t.itemsCenter, t.mT2]}>
                                <Text style={[s.label]}>
                                    IMPORTO
                                </Text>
                                <Text style={[s.text]}>
                                    €{(Number(data.info.amount)/100).toFixed(2)}
                                </Text>
                            </View>
                        </View>
                        <View style={[t.flexCol, t.itemsCenter, t.flexWrap, t.w1_2, t.mT4]}>
                            <View style={[t.itemsCenter]}>
                                <Text style={[s.label]}>
                                    DESCRIZIONE
                                </Text>
                                <Text style={[s.text, t.flexWrap, t.textCenter, t.w20]}>
                                    {data.info.description}
                                </Text>
                            </View>
                            <View style={[t.itemsCenter, t.mT2]}>
                                <Text style={[s.label]}>
                                    VALIDITA' LINK
                                </Text>
                                <Text style={[s.text]}>
                                    {data.info.validTo/60} ore
                                </Text>
                            </View>
                        </View>
                    </View>
                    <View style={[t.itemsCenter, t.justifyCenter, t.mT4, t.wFull]}>
                        <View style={[  t.bgOrange, t.mT3, t.roundedFull, t.w4_12, {padding: 8}]}>
                            <TouchableOpacity onPress={() => {this.writeToClipboard()}}>
                                <Text style={[ t.textCenter, t.textWhite, t.text4, t.fontBold ]}>Copia link</Text>
                            </TouchableOpacity>
                        </View>
                    </View>
                </View>
                <View style={[t.wFull, t.itemsCenter, t.justifyCenter, t.mT3]}>
                    <View style={[ t.bgMainBlue, t.mT3, t.roundedFull, t.w4_12, {padding: 8}]}>
                        <TouchableOpacity onPress={() => {this.props.changeMode()}}>
                            <Text style={[ t.textCenter, t.textWhite, t.text4, t.fontBold ]}>Gestisci un nuovo pagamento</Text>
                        </TouchableOpacity>
                    </View>
                </View>
            </View>
        )
    }
}

const s = {
    label: [ t.fontBold, t.textDarkGrey, t.text5],
    text: [t.text5, t.textSecond, t.fontRegular ]
}
