import React from 'react';

import {
  Text,
  View,
  SafeAreaView,
  StyleSheet,
  Platform,
  ScrollView,
  TouchableHighlight,
  Animated,
  ActivityIndicator
} from 'react-native';

import { t } from "../../assets"
import { mq, isCloseToBottom } from '../utils/Utility';
import { TopBar } from '../components/topBar';
import { HistoricOrder } from './HistoricOrders';
import { HistoricPayment } from './HistoricPayment';
import { HistoricLinkToPay } from './HistoricLinkToPay';
import { CBottomSheet } from '../components/bottomSheet';
import movementsController from '../controller/movements.controller';

export class Movements extends React.Component<{}, { WW, W, M, S: boolean, orderToggle: number, scrollY: any, isLoading: boolean, visible: boolean, renderContent: any, standalone: boolean}> {
    
    histOrder: any;
    histPay: any;
    histLink: any;
    RBSheet
    
    constructor(navigation: any){
        super(navigation)
        this.state = { WW: false, W: false, M: false, S: false, orderToggle: 0, scrollY: 0, isLoading: false, visible: false, renderContent: () => {}, standalone: false };
    }

    static navigationOptions = {
        headerStyle: {
         visibility: "hidden",
         height: 0 
        },
    };

    async componentDidMount(){
        let userCtx = JSON.parse((await movementsController.getCashInfo())!)
        this.setState({standalone: userCtx.deviceInfo.standalone})
    }

    toogleOrder(event){
        this.setState({orderToggle: event })
    }

    _toggleBottomNavigationView = (renderContentBS, open) => {
        this.setState({visible: !this.state.visible})
        if(renderContentBS && typeof renderContentBS == "function"){
            this.setState({ renderContent: renderContentBS })
        }
    };

    async loadMore(){
        this.setState({isLoading: true})
        switch(this.state.orderToggle){
            case 0:
                await this.histPay.loadMore()
                break;
            case 1:
                await this.histOrder.loadMore()
                break;
            case 2:
                await this.histLink.loadMore()
                break;        
        } 
        this.setState({isLoading: false})
    }

    renderContent(){
        switch(this.state.orderToggle){
            case 0:
                return(
                    <HistoricPayment onRef={ref => {this.histPay = ref}} _toggleBottomNavigationView={this._toggleBottomNavigationView.bind(this)}/>  
                )
            case 1:
                return(
                    <HistoricOrder onRef={ref => {this.histOrder = ref}} _toggleBottomNavigationView={this._toggleBottomNavigationView.bind(this)}/>
                )
            case 2:
                return(
                    <HistoricLinkToPay onRef={ref => {this.histLink = ref}} _toggleBottomNavigationView={this._toggleBottomNavigationView.bind(this)}/>
                )
                break;  
        }
    }

    render(){

        const { WW, W, M, S } = this.state;

        return (

            <SafeAreaView style={[ e.heightCustom, e.bgCustomLight ]} onLayout={ mq(this) }>
                <View style={[ e.heightCustom, t.bgLightBlue, WW && [e.fixedContainer] ]}>

                    <ScrollView contentContainerStyle={[ t.flexWrap, t.flexRow, t.pB13, M && { marginHorizontal: "1.05%" }, W && { marginHorizontal: "3.05%" } ]}
                    scrollEventThrottle={16}
                    bounces={false}
                    onMomentumScrollEnd={({nativeEvent}) => {
                        if (isCloseToBottom(nativeEvent) && !this.state.isLoading) {
                            this.loadMore()
                        }
                    }} onScroll={Animated.event(
                        [{ nativeEvent: { contentOffset: { y: this.state.scrollY } } }],
                        {
                        listener:(event : any) => {
                            if (isCloseToBottom(event.nativeEvent) && !this.state.isLoading && Platform.OS == "web") {
                                this.loadMore()
                            }
                        }
                        }
                    )}>

                        <TopBar></TopBar>

                        <View style={[t.wFull, t.mB2, t.flexWrap, t.flexRow, M && t.mB3, W && t.mB3 ]}>

                            <TouchableHighlight style={[ t.justifyCenter, t.itemsCenter, t.bgSecond, t.w11, t.h4, t.mL1, this.state.orderToggle == 0 && t.bgThird,
                            M && {marginLeft: "1%"}, W && {marginLeft: "1%"}, {borderRadius: 6} ]} onPress={() => this.toogleOrder(0)}>
                                <Text style={[ t.fontBd, t.textWhite, t.text6, this.state.orderToggle != 0 && t.textDisGrey ]}>Movimenti</Text> 
                            </TouchableHighlight> 

                            {
                                this.state.standalone && <TouchableHighlight style={[ t.justifyCenter, t.itemsCenter, t.bgSecond, t.h4, {borderRadius: 6, width: 105, marginLeft: 9}, this.state.orderToggle == 1 && t.bgThird ]} onPress={() => this.toogleOrder(1)}>
                                    <Text style={[ t.fontBd, t.textWhite, t.text6, this.state.orderToggle != 1 && t.textDisGrey]}>Storico ordini</Text> 
                                </TouchableHighlight> 
                            }

                            <TouchableHighlight style={[ t.justifyCenter, t.itemsCenter, t.bgSecond, t.h4, {borderRadius: 6, width: 105, marginLeft: 9}, this.state.orderToggle == 2 && t.bgThird ]} onPress={() => this.toogleOrder(2)}>
                                <Text style={[ t.fontBd, t.textWhite, t.text6, this.state.orderToggle != 2 && t.textDisGrey]}>Link to Pay</Text> 
                            </TouchableHighlight> 
                            
                        </View>

                        <View style={[ t.clearFix, t.mB2, t.wFull]}>
                            {
                                this.renderContent()  
                            }
                        </View>

                        {
                            this.state.isLoading && <View style={[t.wFull, t.justifyCenter, t.itemCenter, t.pR1 ]}>
                                <ActivityIndicator size="large" color="#6b7075"></ActivityIndicator>
                            </View>
                        } 
                    </ScrollView>
                    <CBottomSheet { ...{visible: this.state.visible, _toggleBottomNavigationView: this._toggleBottomNavigationView, renderContent: this.state.renderContent} }></CBottomSheet>   
                
                </View>
            </SafeAreaView>
        )
    }
}

const e = StyleSheet.create({

    // Custom background external area
    bgCustomLight: {
        backgroundColor: (Platform.OS === 'web') ? "#c9d1d7" : "#e8eef2",
    },

    // Fix height 100% on mobile + web
    heightCustom: {
        height: (Platform.OS === 'web') ? "100vh" : "100%",
    },

    // Custom fixed container
    fixedContainer: { 
        height: (Platform.OS === 'web') ? "90vh" : "100%",
        width: (Platform.OS === 'web') ? "1400px" : "100%",
        margin: (Platform.OS === 'web') ? "auto" : 0,
        marginTop: (Platform.OS === 'web') ? "4.5vh" : 0,
    }

});