import ServiceBase from "./ServiceBase";
import { environment } from "../environment/envinments";


class VassesService extends ServiceBase {

    constructor() {
        super()
    }

    async getCollections(businessId: number, shopId: number) {
        return await this.GET(`${environment.api.ecommerceUrl}/businesses/${businessId}/${shopId}/menus`)
    }

    async getVasses(merchantId: any, merchantShopId: any){
        return await this.GET(`${environment.api.ecommerceUrl}/businesses/${merchantId}/${merchantShopId}/vasses`);
    }

    async setVasEnabledList(businessId: number, shopId: number, vasTypeId: number, listId: number){
        return await this.PATCH( `${environment.api.ecommerceUrl}/businesses/${businessId}/${shopId}/vas-types/${vasTypeId}`,
        JSON.stringify([
            {"op": "replace", "path": "/menuId", "value": listId},
            {"op": "replace", "path": "/enabled", "value": true}
        ]));
    }

    async setAvailability(businessId: number, productId: number, avail: any){
        const request = JSON.stringify([{"op":"replace","path":"/published","value":avail}])
        return await this.PATCH(`${environment.api.ecommerceUrl}/businesses/${businessId}/products/${productId}`, request);
    }
}

export default new VassesService()