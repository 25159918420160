import React from "react";
import {
    View,
    TouchableOpacity,
    Text,
    TextInput,
    Platform
} from "react-native";

import { t } from "../../assets"
import { toCents } from '../utils/Utility';


export class NumericKeyboard extends React.Component<{confirmTotal?: any, onTotalChange?: any, idPayment?: any, showConfirmBtn: boolean, defTotal?: any}, {total: any}> {

    baseValue: string = "000";
    value: string = "";
    total: string = "";

    constructor(props){
        super(props)
        this.state = {total: "0.00"}
    }

    componentDidMount(){
        if(this.props.defTotal && this.props.defTotal != ""){
            this.setState({total: this.props.defTotal})
            this.total = this.props.defTotal
            this.value = toCents(this.props.defTotal).toString()
            this.baseValue = this.value.length <= 2 ? this.baseValue.slice(0, -this.value.length): "";
        }
    }

    setNumber(number: string) {
        if((number == "0" || number == "00") && this.baseValue == "000") { return }
        if(this.value.length < 9){
        this.baseValue = this.value.length <= 2 ? this.baseValue.slice(0, -number.length): "";
        this.value += number;
        this.setTotal();
        }
    }

    cancelNumber() {
        if(this.baseValue != "000"){
        this.baseValue = this.value.length <= 3 && this.value.length > 0  ? this.baseValue + "0": "";
        this.value = this.value.slice(0, -1);
        this.setTotal();
        }
    }

    setTotal() {
        this.total = this.baseValue + this.value;
        if(this.props.onTotalChange){
            this.props.onTotalChange((Number(this.total)/100).toFixed(2))
        }
        this.setState({total: (Number(this.total)/100).toFixed(2)});
    }

    numberKey(nKey){
        return (
            <TouchableOpacity style={[ t.w4_12, t.h1_4, t.itemsCenter, t.justifyCenter ]} onPress={() => {this.setNumber(nKey)}}>
                <Text style={[ t.text2, t.textFirst ]}>{nKey}</Text>
            </TouchableOpacity>
        )
    }


    render(){
        var web = Platform.OS == "web"
        return (

            <View style={[ t.hFull, t.itemsCenter, t.justifyEvenly, t.wFull, t.p3, !web && [t.border1, t.borderDarkGrey, t.p1] ]}>

                <View style={[t.flexRow, t.borderB1, t.mY1, t.wFull, {borderColor: "#AAAAAA"}, t.itemsCenter, web ?  {paddingLeft: "44%"} : t.justifyCenter]}>
                    <Text style={[ web ? t.text1 : t.text3, t.textFirst]}>€</Text>
                    <TextInput value={this.state.total} editable={false} style={[web ? t.text1 : t.text3, t.textMainBlue, t.mL1 ]}></TextInput>
                </View>

                <View style={[ t.flexRow, t.flexWrap, t.h10_12, t.wFull, t.mB1 ]}>

                    {this.numberKey(1)}
                    {this.numberKey(2)}
                    {this.numberKey(3)}

                    {this.numberKey(4)}
                    {this.numberKey(5)}
                    {this.numberKey(6)}

                    {this.numberKey(7)}
                    {this.numberKey(8)}
                    {this.numberKey(9)}

                    {this.numberKey("00")}
                    {this.numberKey(0)}

                    <TouchableOpacity style={[ t.w4_12, t.h1_4, t.itemsCenter, t.justifyCenter ]} onPress={() => {this.cancelNumber()}}>
                        <Text style={[ t.text1, t.textFirst ]}>{"<"}</Text>
                    </TouchableOpacity>

                </View>

                {
                    this.props.showConfirmBtn && <View style={[ this.state.total != "0.00" ? t.bgMainBlue : t.bgThird, t.mB2, t.roundedFull, t.w6_12, {padding: 8} ]}>
                        <TouchableOpacity disabled={this.state.total == "0.00"} onPress={() => {this.props.idPayment ? this.props.confirmTotal(this.props.idPayment, this.state.total) : this.props.confirmTotal(this.state.total)}}>
                            <Text style={[ t.textCenter, t.textWhite, t.text4, t.fontBd ]}>Conferma</Text>
                        </TouchableOpacity>
                    </View>
                }
            </View>
        )
    }
}
