import ServiceBase from "./ServiceBase";
import { environment } from "../environment/envinments";

class PropertiesService extends ServiceBase {

    constructor() {
        super()
    }

    async getProperties(){
      const authHeaders = {
        Authorization: "Basic d2ViYXBwOndlYmFwcF9zZWNyZXQ=",
        "Content-Type": "application/x-www-form-urlencoded",
    }

      console.log("PropertiesService -> GetProperties");

      return await this.GET(`/api/v2/properties`, authHeaders);
    }
}

export default new PropertiesService()
