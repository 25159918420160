import  AuthService from '../services/AuthService'
import MerchantService from '../services/MerchantService';
import { AsyncStorage, Platform, DeviceEventEmitter } from 'react-native';
import PaymentService from '../services/PaymentService';
import { environment } from '../environment/envinments';
import { StackActions, NavigationActions } from 'react-navigation';

class LoginController{

    token: string 

    constructor(){
        this.token = ""
    }

    async executeLogin(props: any) {
        let data = {
            code: props.value,
        };

        return AuthService.login(data).then(
            (async () => {
                return this.getUserContext().then(
                    (res) => {
                        console.log("success "+res)
                        const resetAction = StackActions.reset({
                            index: 0,
                            actions: [NavigationActions.navigate({ routeName: props.page })],
                            });
                            props.navigation.dispatch(resetAction); 
                    },
                    (error) => {return error}
                )}),
            ((error) =>{
                    return {message: error.toString()}
                }
            )
        )
    }

    async logout(props){
        let merchantData = (await AsyncStorage.getItem("wp_merchantDeviceData"))! 
        await this.unsubscribeDevice(JSON.parse(merchantData))
        await AsyncStorage.clear()
        AsyncStorage.setItem("wp_deviceNotificationToken", this.token)
        const resetAction = StackActions.reset({
            index: 0,
            actions: [NavigationActions.navigate({ routeName: "Login" })],
        });
         
        props.navigation.dispatch(resetAction);         
     } 

    async getUserContext(){
        var merchantData: any
        let merchantDeviceData = await MerchantService.getMerchantDeviceInfo()
        if(merchantDeviceData && merchantDeviceData._embedded){ 
            const businessId = await AsyncStorage.getItem("wp_businessId");
            if (businessId) {
                merchantData = merchantDeviceData._embedded.businesses.find(item => item.id == businessId);
            }
            
            if (!merchantData) {
                merchantData = merchantDeviceData._embedded.businesses.find(item => item.status == "ENABLED");
            }
           
            merchantData.deviceInfo = await MerchantService.getDeviceInfo()
            merchantData.shop = await MerchantService.getMerchantShopInfo(merchantData.id, merchantData.deviceInfo.shopId)
            AsyncStorage.setItem("wp_merchantDeviceData", JSON.stringify(merchantData)) 
            let response = await PaymentService.generatePaymentCode(merchantData.shop.id)
            AsyncStorage.setItem("wp_paymentCode", response.error ? "" : `${environment.api.domain}/?${response.qrCode}`)
            if(Platform.OS !== "web")
                await this.subscribeDevice(merchantData)
        }
    }

    async subscribeDevice(merchantData){
        this.token = (await AsyncStorage.getItem("wp_deviceNotificationToken"))!!
        if(this.token){
            await MerchantService.subscribeDevice(this.token, merchantData.owner.phoneNumber, environment.iosPushSandbox)
        }
    }

    async unsubscribeDevice(merchantData){
        if(this.token){
            await MerchantService.unsubscribeDevice(this.token, merchantData.owner.phoneNumber)
        }
    }

    async isLogged(){
        let token = await AsyncStorage.getItem("wp_access_token")
        return token != null && token != "undefined" 
    }
}

export default new LoginController()