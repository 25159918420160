import TransactionService from "../services/TransactionService";
import { AsyncStorage } from "react-native";

export class LinkToPay {
    
    public id: any
    public businessId: number
    public businessName: string
    public businessWalletId: number
    public businessAvatarImgPath: string
    public createdDate: string
    public expireDate: string
    public eventType: string
    public status: string
    public amount: number 
    public currency: string 
    public shopId: number
    public shopName: string 
    public merchantDeviceId: number 
    public merchantDeviceName: string
    public paymentMode: string 
    public description: string
    public productCodeId: string
    public refundedAmount: number
    public userAlias: string
    public userFullName: string
    public usetAvatarImgPath: string
    public transactionId: number
    public refundable: boolean
    public linkToPayUrl: string
    public paymentMethod: string

    readonly $name;
  
    constructor(paymentObj){
        this.id = paymentObj.id
        this.businessId = paymentObj.businessId
        this.businessName = paymentObj.businessName
        this.businessWalletId = paymentObj.businessWalletId
        this.businessAvatarImgPath = paymentObj.businessAvatarImgPath
        this.userAlias = paymentObj.userAlias
        this.userFullName = paymentObj.userFullName
        this.usetAvatarImgPath = paymentObj.useusetAvatarImgPath
        this.createdDate = paymentObj.createdDate
        this.expireDate = paymentObj.expireDate
        this.eventType = paymentObj.eventType
        this.status = paymentObj.status
        this.amount = paymentObj.amount
        this.currency = paymentObj.currency
        this.shopId = paymentObj.shopId
        this.shopName = paymentObj.shopName 
        this.merchantDeviceId = paymentObj.merchantDeviceId
        this.merchantDeviceName = paymentObj.merchantDeviceName
        this.paymentMode = paymentObj.paymentMode
        this.description = paymentObj.description
        this.productCodeId = paymentObj.productCodeId
        this.refundedAmount = paymentObj.refundedAmount ? paymentObj.refundedAmount : 0
        this.transactionId = paymentObj.transactionId
        this.refundable = paymentObj.refundable
        this.linkToPayUrl = paymentObj.linkToPayUrl
        this.paymentMethod = paymentObj.paymentMethod
    }

    async refund(partiallyAmount, total){
        var req: any = undefined
    
        req = {
            transactionId: this.transactionId,
            paymentType: "B2P",
            currentAmount: this.amount - this.refundedAmount,
            amount: partiallyAmount ? Number(total) : this.amount - this.refundedAmount,
            currency: "EUR"
        }  
        
        return TransactionService.businessRefund(req)   
    }
}    