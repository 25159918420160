import React, { Component } from 'react'
import { View, StyleSheet, Text, TouchableHighlight, DeviceEventEmitter } from 'react-native'
import { t } from "../../assets"
import Loader from 'react-loader-spinner'
import {SkyLightStateless} from 'react-skylight';
import { getAlertType } from '../utils/Utility';

export class CAlert extends React.Component<{event: any}, {visible: boolean, data: any, content:any, type: any, loading: boolean}> {
	
		showModalListener
		dismissModalListener
		types
		
		constructor(props){
		super(props)
		this.state = {visible: false, data: {}, type: {}, content: {}, loading: false}
		this.showModalListener = DeviceEventEmitter.addListener("show:modal:"+this.props.event, (param) => {
			console.log("showModalListener")
			this.setState({data: param.data, content:param.data.content, type: param.type})
			this.types = getAlertType(this.state)
			this.show()
		})
		this.dismissModalListener = DeviceEventEmitter.addListener("dismiss:modal:"+this.props.event, () => {this.setState({loading: false}), this.dismiss()})
		this.types = getAlertType(this.state)
		}

		componentDidMount() {
		}

		componentWillUnmount() {
		this.dismissModalListener.remove() 
		this.showModalListener.remove()
		}

		static showModal = function(props, event) {
		console.log("insideShowModal")
		DeviceEventEmitter.emit("show:modal:"+event, props)
		}
	
		static dismissModal = function(event) {
		DeviceEventEmitter.emit("dismiss:modal:"+event)
		}

	show(){
		this.setState({visible: true})
	}

	dismissAndRefresh() {
		this.dismiss()
		DeviceEventEmitter.emit("orders:reload")
	}

	dismiss(){
		this.setState({visible: false})
	}

	closePopup(){
		alert('Overlay clicked!');
	}

		renderContent(){
		var type = this.types.find(x => x.type == this.state.type)!
		var titleColor = e.textDarkGrey
		switch(this.state.type){
			case "PAYMENT_SUCCESS":
				titleColor = t.textGreen
				break
			case "PAYMENT_FAILS":
				titleColor = t.textRed
				break
			case "EXPIRED": 
				titleColor = t.textRed
				break   
		}
		return(          
			<View style={[ t.p2, t.rounded, t.itemsCenter, t.wFull ]}>

				<Text style={[t.text3, titleColor,  t.fontBold]}>{type ? type.title: ""}</Text>
				<Text style={[t.text5, e.textDarkGrey, t.mT2]}>{type ? type.message: "" }</Text>

				{
					(this.state.type == "LOGOUT" || this.state.type == "REFUND" || this.state.type == "ERROR-500") && <TouchableHighlight 
					style={[ t.bgWhite, t.p2, t.roundedFull, t.border1, t.borderMainBlue, t.mT7, t.w6_12 ]} onPress={() => {window.location.reload(), this.dismiss()}}>
						<Text style={[ t.textCenter, t.textMainBlue, t.text4, t.fontBd ]}>{this.state.type != "ERROR-500" ? "Annulla" : "Aggiorna pagina"}</Text>
					</TouchableHighlight>
				}

				{ !this.state.loading && <TouchableHighlight style={[ t.bgMainBlue, t.p2, t.mT2, t.roundedFull, t.w6_12 ]} onPress={() => {this.state.data.actionFunc && this.state.type != "ERROR_REFUND" ? (this.setState({loading: true}), this.state.data.actionFunc(this.state.data.props)) : this.state.type != "PAY_REFRESH" ? this.dismiss() : this.dismissAndRefresh()}}>
					<Text style={[ t.textCenter, t.textWhite, t.text4, t.fontBd ]}>{type ? type.button: ""}</Text>
				</TouchableHighlight>
				}

				{this.state.loading && <View style={[ t.bgMainBlue, t.p2, t.mT2, t.roundedFull, t.w6_12, t.itemsCenter, t.justifyCenter ]}>
					<Loader type="Oval" color="#ffffff" height={25} width={25}/>
				</View>
				}
				
			
			</View>
		)
	}

		render(){
			return(

				<SkyLightStateless 
				onOverlayClicked={() => {this.dismiss()}} 
				onCloseClicked={() => {this.dismiss()}} 
				isVisible={this.state.visible} 
				transitionDuration={20} 
				closeButtonStyle={{ fontSize: 0 }}
				dialogStyles={{ height: "100vh", width: "100%", top: 0, left: 0, margin: 0, padding: 0, backgroundColor: "#FFFFFF00"}}>

					<View style={[ t.bgWhite, t.h20, t.rounded, t.itemsCenter, t.justifyCenter, 
					{width: 600, top: "50%", left: "50%", transform: [{ translate: "-50%, -50%" }]}]}>

						{this.renderContent()}  

					</View>
					
				</SkyLightStateless>
			) 
		}
} 

const e =  StyleSheet.create({

	textDarkGrey: {color: "#6b7075"},

})



// Delete this when style is fixed!!!!

const styles = {
	overlayStyles: {
		position: 'fixed',
		top: 0,
		left: 0,
		width: '100%',
		height: '100%',
		zIndex: 99,
		backgroundColor: 'rgba(0,0,0,0.3)'
	},
	dialogStyles: {
		width: '50%',
		height: '400px',
		position: 'fixed',
		top: '50%',
		left: '50%',
		marginTop: '-200px',
		marginLeft: '-25%',
		backgroundColor: '#fff',
		borderRadius: '2px',
		zIndex: 100,
		padding: '15px',
		boxShadow: '0 0 4px rgba(0,0,0,.14),0 4px 8px rgba(0,0,0,.28)'
	},
	title: {
		marginTop: '0'
	},
	closeButtonStyle: {
		cursor: 'pointer',
		position: 'absolute',
		fontSize: '4em',
		right: '100px',
		top: '0',
		backgroundColor: "red",
	}
};