import React, { Component } from 'react'
import { TouchableHighlight, Text } from 'react-native'
import { t } from "../../assets"

interface IButtonProps {
    pTitle: string;
    page?: string;
    onClick: any;
    navigation: any;
    value:any;
    style: any;      //For styling components from the exterior (and also "this.props.style") 
}

export class LargeButtonFilled extends React.Component<IButtonProps> {
    render() {
        return (

            // Fix button color on click!

            <TouchableHighlight underlayColor="#ffbc1c" onPress={() => {this.props.onClick(this.props)}}
            style={[ t.flex, t.justifyCenter, t.rounded, this.props.style]}>
                <Text style={[ t.text5, t.fontBd, t.textWhite, t.textCenter]}>{this.props.pTitle}</Text>
            </TouchableHighlight>
        );
    }
}