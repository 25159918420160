import PaymentService from "../services/PaymentService";
import { CAlert } from "../components/alert";
import { handleError, toCents } from "../utils/Utility";

class HomePayDBController{

    async generatePayment(amount, logout, props, ev) {
        console.log("On generate Payment")
       return PaymentService.generateNewPurchase(toCents(amount)).then(
           (res) => {return res},
           (error) => {handleError(error, logout, props, ev)}
       )
    }

    async checkPurchase(purchaseId, logout, props, ev){
        return PaymentService.checkPurchase(purchaseId).then(
            (data) => {
                return data
            },
            (error) => {
                handleError(error, logout, props, ev)
            }
        )
    }
}

export default new HomePayDBController()
