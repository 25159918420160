import { StyleSheet } from "react-native";
import t from "./styles/styles";
var appIcons = {
  tabBar: {
    cash: {
      true: require("./icons/iconHomeActive.png"),
      false: require("./icons/iconHome.png")
    },
    movements: {
      true: require("./icons/iconMovementsActive.png"),
      false: require("./icons/iconMovements.png")
    },
    setting: {
      true: require("./icons/iconSettingsActive.png"),
      false: require("./icons/iconSettings.png")
    },
    pay: {
      true: require("./icons/faiPagareActive.png"),
      false: require("./icons/faiPagare.png")
    }
  }
};
var extra = {
  elevationShadow: function elevationShadow(elevation, bg) {
    return {
      elevation: elevation,
      shadowColor: col.first,
      shadowOffset: {
        width: 0,
        height: 0.5 * elevation
      },
      shadowOpacity: 0.3,
      shadowRadius: 0.8 * elevation
    };
  },
  aspectRatio: function aspectRatio(r) {
    return StyleSheet.create({
      aspectRatio: r
    });
  }
};
var col = {
  orange: "#f76122",
  lightOrange: "#ffede7",
  first: "#4a5155",
  second: "#707f89",
  third: "#cbd1d5",
  mainBlue: "#236a95",
  midBlue: "#4380a4",
  lightBlue: "#f1f5f8",
  white: "#ffffff",
  lightWarmGrey: "#faf9f8",
  lightDisGray: "#efefef",
  darkGray: "#9b9b9b",
  black: "#000000",
  green: "#7ed321",
  red: "#d0021b",
  clear: "#ffffff00"
};
export { appIcons, extra, col, t };