import ServiceBase from "./ServiceBase";
import { AsyncStorage } from 'react-native'
import { environment } from "../environment/envinments";

class AuthService extends ServiceBase {

    constructor() {
        super()
    }

    authHeaders = {
        Authorization: "Basic d2ViYXBwOndlYmFwcF9zZWNyZXQ=",
        "Content-Type": "application/x-www-form-urlencoded",
    }

    async login(data: any) {
        
        var details = {
            code: data.code, 
            client_id: "webapp",
            grant_type: "authorization_code"
        }
    
        var formBody = [] as any;
        formBody.push(encodeURIComponent('code') + "=" + encodeURIComponent(details.code));
        formBody.push(encodeURIComponent('client_id') + "=" + encodeURIComponent(details.client_id));
        formBody.push(encodeURIComponent('grant_type') + "=" + encodeURIComponent(details.grant_type));

        let body = formBody.join("&");

        return this.POST(`${environment.api.deviceUrl}/token`, body, this.authHeaders).then(
            async (response) => {
                if(response && response.access_token && response.refresh_token){
                    await AsyncStorage.setItem("wp_access_token", response.access_token)
                    await AsyncStorage.setItem("wp_refresh_token", response.refresh_token)
                    await AsyncStorage.setItem("wp_businessId", ""+response.businessId)
                }
            },
            (error) => {
                return new Promise((resolve, reject) => {
                    reject(error)
                })
            }
        );   
    }

    async refresh() {
        
        var details = {
            refresh_token: (await AsyncStorage.getItem("wp_refresh_token"))!,
            client_id: 'webapp',
            grant_type: "refresh_token"
        }

        var formBody = [] as any;
        formBody.push(encodeURIComponent('refresh_token') + "=" + encodeURIComponent(details.refresh_token));
        formBody.push(encodeURIComponent('client_id') + "=" + encodeURIComponent(details.client_id));
        formBody.push(encodeURIComponent('grant_type') + "=" + encodeURIComponent(details.grant_type));

        let body = formBody.join("&");

        return this.POST(`${environment.api.deviceUrl}/token`, body, this.authHeaders).then(
            async (response) => {
                if(response && response.access_token && response.refresh_token){
                    await AsyncStorage.setItem("wp_access_token", response.access_token)
                    await AsyncStorage.setItem("wp_refresh_token", response.refresh_token)
                }
            },
            async (error) => {
                if(error == 500){
                    await AsyncStorage.setItem("wp_access_token", "") 
                }
                return new Promise((resolve, reject) => {
                    reject(error)
                })
            }
        )

    }
}

export default new AuthService()
