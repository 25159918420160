import React from 'react';

import { 
    View, 
    Text, 
    Image, 
    StyleSheet, 
    TouchableOpacity,
    Platform,
    DeviceEventEmitter,
    ScrollView,
    TouchableHighlight
} from "react-native";

import { t, extra } from "../../assets"
import { LargeButtonEmpty } from './largeButtonEmpty';
import listController from '../controller/list.controller';
import { vasByName, vasByIcon } from '../utils/Utility';
import { ProductCard } from './productCard';

export class MenuCard extends React.Component<{ W, M, S: boolean, item: any, vasses: any, refresh: any, _toggleBottomNavigationView: any, style?: any },  {products: any}> {

    constructor(props){
        super(props)
        this.state = {products: []}
    }

    componentDidMount(){
        this.setState({products: this.props.item.products})
    }

    async enableVas(vasTypeId){
        await listController.enableVas(vasTypeId, this.props.item.id)
        this.props.refresh()
    }

    renderProductsContent({W, M, S}){
        return (
            <ScrollView style={[t.bgWhite, t.roundedT, t.wFull, {height: 400}, Platform.OS == "ios" && W && [t.w1_2, {marginHorizontal:"25%"}]]}>
                <View style={[ t.flexWrap, t.flexRow, t.p2, t.pB2, e.elevation0 ]}>

                    <View style={[ t.w10_12, t.h6, t.p2, t.mB3 ]}>
                        <Text style={[ t.fontBd, t.textFirst, t.text4 ]}>{this.props.item.name}</Text>
                        <Text style={[ t.fontRg, t.textSecond, t.mT1 ]}>{this.props.item.description}</Text>
                    </View>
                    <TouchableOpacity style={[ t.w2_12, t.h6, t.justifyCenter, t.itemsEnd, t.pX2, t.mB1 ]} onPress={() => this.props._toggleBottomNavigationView(true)}>
                        <Image source={require('../../assets/icons/iconCross.png')} style={{width: 14, height: 14}}/>
                    </TouchableOpacity>

                    <View style={[ t.flexNoWrap, t.overflowScroll, t.wFull, t.mT2 ]}>
                        { 
                            this.state.products.map((el) => {
                                return <ProductCard item={el}></ProductCard>
                            }) 
                        }
                    </View>

                </View>
            </ScrollView>
        )
    }
    
    render(){
        const { W, M, S, item, vasses } = this.props
        const vasNumber = vasses.length
        var width = {width: (100/vasNumber)+"%"}
    
        
        return(

            <View style={[{zIndex: 100}, t.flex, t.flexWrap, t.flexRow, t.wFull, t.bgWhite, t.rounded, t.pB1, {marginBottom: 11}, 
            e.elevation0, extra.elevationShadow(2), W && [{width: "48%", marginHorizontal: "1%"}, t.mB2] ]}>

                <View style={[ t.flexWrap, t.flexRow, t.wFull, t.p2, t.justifyContent ]}>

                    <View style={[ t.w8_12, t.h6, t.justifyCenter, t.pL1 ]}>
                        <Text style={[ t.fontBd, t.textFirst, t.text5 ]}>{item.name}</Text>
                        <Text style={[ t.fontRg, t.textSecond ]}>{item.description}</Text>
                    </View>
                    
                    <TouchableHighlight underlayColor="#fafbfc" onPress={() => this.props._toggleBottomNavigationView(true, this.renderProductsContent.bind(this, {W,M,S}))}
                    style={[ t.flex, t.justifyCenter, t.border1, e.customBorderColor, t.rounded, t.w4_12, t.h4, {marginTop: 7}]}>
                        <Text style={[ t.text5, t.fontRg, t.textSecond, t.textCenter]}>Prodotti</Text>
                    </TouchableHighlight>

                </View>

                <View style={[ t.wFull, t.mT0 ]}>
                    
                    <ScrollView horizontal={true} contentContainerStyle={[M && t.wFull, W && t.wFull]}>
                        <View style={[ t.flexRow, t.pX2, t.pB2, t.justifyAround, t.wFull ]}>

                        {
                            vasses.map((el) => {       

                                return <View style={[ t.itemsCenter, {width: 80}, M && width, W && width ]}>
                                    <TouchableOpacity style={[ t.itemsCenter ]} onPress={() => this.enableVas(el.vasTypeId)}>

                                        <View style={[ t.h9, t.w9, t.justifyCenter, t.itemsCenter ]}>
                                            {
                                                el.configuration.menuId == item.id &&
                                                <Image source={require('../../assets/icons/tick.png')} style={[ t.z20, t.w3, t.h3, t.absolute, t.top0, t.right0 ]}/>
                                            }
                                            <View style={[t.bgLightBlue, t.roundedFull, t.h7, t.w7, t.justifyCenter, t.itemsCenter, t.z0 ]}>
                                                <Image source={ vasByIcon[el.name]} style={{width: 35, height: 35}}/>
                                            </View>
                                        </View>

                                        <Text style={[ t.w9, t.justifyCenter, t.textCenter, t.fontRg, t.textSecond]}>{vasByName[el.name]}</Text>
                                
                                    </TouchableOpacity>
                                </View>    
                            })
                        }
                        </View>

                    </ScrollView>
                </View>

            </View>
        )
    }
}

const e = StyleSheet.create({

    // Custom border radius
    customBorderColor: { borderColor: "#859aa7" },
    
    // Fix z-index bottom sheet android 
    elevation0: { elevation: 0 },
    elevation50: { elevation: 50 },

});