import React from 'react';
import { StyleSheet, View, Image, Linking } from 'react-native';
import { Platform } from 'react-native'
import { StackActions, NavigationActions } from 'react-navigation';
import DeviceInfo from "react-native-device-info";
import loginController from '../controller/login.controller';
import { handleError } from '../utils/Utility';
import { CAlert } from '../components/alert';
import PropertiesService from "../services/PropertiesService";


export class Splash extends React.Component<{navigation: any}> {

    static navigationOptions = {
        headerStyle: {
         visibility: "hidden",
         height: 0
        },
    };

    performAutoLogin = async() => {
        if(await loginController.isLogged()){
            loginController.getUserContext().then(
                () => { this.goNextPage("Home")},
                (error) => {
                    console.log(error)
                    handleError(error, this.logout.bind(this, this.props), this.props, "splash")
                }
            )

        }else{
            this.goNextPage("Login")
        }
    }

    updateVersion() {
      let marketUrl;

      if (Platform.OS === "ios") {
          marketUrl =
              "https://itunes.apple.com/us/app/tinaba/id1130356175l=it&ls=1&mt=8";
      } else {
          marketUrl =
              "https://play.google.com/store/apps/details?id=it.tinaba.incassi";
      }

      Linking.openURL(marketUrl);
  }

    async logout(props){
        loginController.logout(props)
    }

    async componentDidMount() {
      const isTooOldVersion =  await this.isTooOldVersion();
      if (!isTooOldVersion){
        await this.performAutoLogin();
      } else {
        CAlert.showModal({data: {actionFunc: this.updateVersion, props: this.props}, type:"APP-BLOCKED"}, "splash")
      }
    }

    async isTooOldVersion() {
      return new Promise(async (resolve, reject) => {
        const properties = await PropertiesService.getProperties();
        console.log("---------------------------------------");
        console.log("---------------------------------------");
        console.log("---------------------------------------");
        console.log("----------PROPERTIES----------");
        console.log(properties);
        console.log("---------------------------------------");
        console.log("---------------------------------------");
        console.log("---------------------------------------");

        let currentVersion = DeviceInfo.getVersion();
        let minVersion =
            Platform.select({
                ios: properties["pos-ios-version"],
                android: properties["pos-android-version"],
            }) || 0;


            //MOCK DATA
            //currentVersion = "3.0.0";
            //minVersion = "4.0.0";

        console.log("currentVersion: "+ currentVersion + ", minVersion: "+ minVersion);


        if (!currentVersion || !minVersion) {
            return resolve(false);
        }

        resolve(this.compareVersion(minVersion, currentVersion) >= 1)
    })

    }

    compareVersion(a = "", b = "") {
        //1 se a+nuova di b, 0 se uguali, -1 se a+vecchia di b
        let i, diff;
        const regExStrip0 = /(\.0+)+$/;
        const segmentsA = a.replace(regExStrip0, "").split(".");
        const segmentsB = b.replace(regExStrip0, "").split(".");
        const l = Math.min(segmentsA.length, segmentsB.length);

        for (i = 0; i < l; i++) {
            diff = parseInt(segmentsA[i], 10) - parseInt(segmentsB[i], 10);
            if (diff) {
                return diff;
            }
        }
        return segmentsA.length - segmentsB.length;
    }

    goNextPage(page){
        const resetAction = StackActions.reset({
        index: 0,
        actions: [NavigationActions.navigate({ routeName: page })],
        });
        this.props.navigation.dispatch(resetAction);
    }

    render() {
        return (
        <View style={style.container}>
            {
                Platform.OS === 'web' ?
                <Image source={require('../../assets/icons/splash-landscape.png')} style={style.backgroundImageWeb}/>
                :
                <Image source={require('../../assets/icons/splash.png')} style={style.backgroundImage}/>
            }
             <CAlert event={"splash"}></CAlert>
        </View>
        );
    }
}

const style = StyleSheet.create({
    container: {
        justifyContent: 'center',
        alignItems: 'center',
        flex: 1,
    },

    backgroundImage:{
        alignSelf: 'center',
        flex: 1,
        height: 1024,
        width: 720
    },
    backgroundImageWeb:{
        alignSelf: 'center',
        flex: 1,
        height: window.innerHeight,
        width: window.innerWidth
    }
})
