import TransactionService from "../services/TransactionService";
import ImageService from "../services/ImageService";

export class OrderAndPay{
  public businessId
  public status: string
  public transactionId;
  public timestamp: string
  public user: any
  public refunded: boolean
  public orderId: string;      
  public code: string;
  public amount: number;
  public vasType: string;
  public deliveryAddress;
  public tableNumber;
  public address
  public slot
  public items;
  public deliveryCost;
  public subtotal;
  public refundable;
  public avatar;
  public companyNameOD;

  readonly $name = "Order"

  public cart:any

  constructor(orderObj: any, user: any, businessId: any) {

    this.user = user
    this.businessId = businessId
    this.orderId = orderObj.id;     
    this.code = orderObj.code;
    this.vasType = orderObj.vasType ? orderObj.vasType.replace(" ", "") : "";
    this.status = orderObj.status;
    this.subtotal = orderObj.orderTotals.subtotal.value;
    this.amount = orderObj.orderTotals.total.value;
    this.deliveryCost = orderObj.orderTotals.shippingPrice.value
    this.tableNumber = orderObj.tableNumber;
    this.slot = orderObj.slot;
    this.items = orderObj.products;
    this.deliveryAddress = orderObj.shippingAddress;
    this.timestamp = orderObj.createdDate;
    this.refunded = orderObj.status == "ORDER_REFUNDED" ? true : false;
    this.refundable = orderObj.refundable
    this.companyNameOD = orderObj.vasOfficeDeliveryCompanyName

    if(this.vasType == "officedelivery"){
        this.deliveryAddress.givenName = user.contactAlias
        this.deliveryAddress.familyName = ""
    }
    }

    refund(){
        return TransactionService.refundTransaction(this.businessId, this.orderId)
    }
}