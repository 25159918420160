export class PaymentsRequests {
    
    public contactAlias: string
    public contactName: string
    public timestamp: string
    public idRequestedPayment: string
    public idUser: string;
    public idUserWalletConsumer: string
    public idUserWalletMerchant: string
    public eventType: string
    public table: string;
    public amount: number;
    public userAvatar: string;
    public businessAvatar: string;
    public avatar;

    readonly $name;
  
    constructor(paymentObj){
     this.$name = paymentObj.tableNumber ? "LazyCheckout" : "PaymentsRequests";
     this.contactAlias = paymentObj.userAlias;
     this.contactName = paymentObj.userFullName;
     this.timestamp = paymentObj.createdDate;
     this.idRequestedPayment = paymentObj.id;
     this.idUser = paymentObj.userId
     this.idUserWalletConsumer = paymentObj.userWalletId
     this.idUserWalletMerchant = paymentObj.businessWalletId;
     this.eventType = paymentObj.eventType;
     this.table = paymentObj.tableNumber;
     this.amount = paymentObj.amount;
     this.userAvatar = paymentObj.userAvatarImgPath
     this.businessAvatar = paymentObj.businessAvatarImgPath
    }
}    