import ServiceBase from "./ServiceBase";
import { byteArrayToUrl, readAllChunks } from './Utils'
import { environment } from "../environment/envinments";
import { Platform } from 'react-native'

class MerchantService extends ServiceBase {

    constructor() {
        super()
    }

    async getMerchantDeviceInfo(){
        return await this.GET(`${environment.api.userUrl}/users/me/businesses`); 
    }

    async getMerchantShopInfo(businessId: number, shopId: number){
        return await this.GET(`${environment.api.userUrl}/businesses/${businessId}/shops/${shopId}`)
    }

    async getDeviceInfo(){
        return await this.GET(`${environment.api.deviceUrl}/devices/me`);
    }

    async updateMerchantDevice(id: number, status: any) {
        const url = `${environment.api.deviceUrl}/devices/me`;
          let request = [
            {"op": "replace", "path": "/status", "value": status}
          ]
          return await this.PATCH(url, request)
        }
    
    async subscribeDevice(token: any, phone: any, sandbox: boolean){
        return await this.POST(`${environment.api.notificationUrl}/device-endpoints/pair`, JSON.stringify({
          "deviceToken" : token,
          "deviceType" : Platform.OS === "ios" ? "IOS": "ANDROID",
          "phoneNumber" : phone,
          sandbox
        }));
      }
    
    async unsubscribeDevice(token: any, phone: any){
        return await this.POST(`${environment.api.notificationUrl}/device-endpoints/unpair`, JSON.stringify({
          "deviceToken" : token,
          "deviceType" : Platform.OS === "ios" ? "IOS": "ANDROID",
          "phoneNumber" : phone
        }));
    }

    /*async getAvatarImage(shopId: number, cb: any) {
        var response = await this.GET(`${environment.api.userUrl}/businesses/shops/${shopId}/img/sa?cb=${cb}`, {})
        if(Platform.OS == "web"){
          return byteArrayToUrl(await readAllChunks(response.body))
        }else{
          return ""
        }
        
      }*/

}

export default new MerchantService()